import { Flex, Image, useMediaQuery, Text } from "@chakra-ui/react";
import { AuthContext } from "core/features/auth/AuthContext";
import useGetUserInfo from "core/features/auth/hooks/useGetUserInfo";
import useLogout from "core/features/auth/hooks/useLogout";
import colors from "core/resources/theme/colors";
import { useContext, useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { IoTicketSharp } from "react-icons/io5";
import { MdMenu, MdOutlineLogin } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Button from "ui/components/Button/Button";
import MenuNavegation from "./components/MenuNavegation";
interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function HeaderNavegation({ isOpen, setIsOpen }: Props) {
  const [isLargerThan1024] = useMediaQuery("(min-width: 1025px)");
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  const auth = useContext(AuthContext);

  const { isSuccess: isLoadedUser, data: user } = useGetUserInfo();

  const handleLogout = () => {
    auth.signout();
    navigate("/");
  };

  useEffect(() => {
    setIsOpen(false);
  }, [navigate, setIsOpen]);

  const logout = useLogout();

  return (
    <Flex flex="1" justify={"end"} gap={4} h="max-content" w="100%" zIndex={10}>
      <Flex
        w={isOpen ? "100%" : "max-content"}
        flexDir={isLargerThan1024 ? "row" : "column"}
        bg={isLargerThan1024 && !isOpen ? "none" : `${colors.blue}`}
        rounded={10}
      >
        {isLargerThan1024 ? null : (
          <Flex justify="end" onClick={() => setIsOpen(!isOpen)}>
            <MdMenu color="white" size={35} />
          </Flex>
        )}

        {isOpen || isLargerThan1024 ? (
          <Flex flexDir="column" gap={4}>
            <Flex
              flex={1}
              justify="end"
              gap={4}
              align={"center"}
              flexDir={isLargerThan1024 ? "row" : "column"}
              m={isLargerThan1024 ? "" : "auto"}
              w={isLargerThan1024 ? "100%" : "100%"}
            >
              {!isLargerThan1024 && (
                <Image
                  src={'https://tactonuvem.blob.core.windows.net/imagens/900b34b0-9d5d-4e52-b6ed-80d9c42d8825/banners/logoacquapark-01.png'}
                  maxWidth={["100px", "100px", "100px", "200px", "240px"]}
                  width="100%"
                  cursor="pointer"
                  onClick={() => navigate("/")}
                />
              )}
              {!isLoadedUser ? (
                <Button
                  title="ENTRAR"
                  bg={colors.green}
                  prevIcon={<MdOutlineLogin size={20} />}
                  onClick={() => {
                    navigate("/auth/login");
                  }}
                />
              ) : null}

              <Button
                title="INGRESSO"
                bg={colors.red}
                icon={<IoTicketSharp />}
                onClick={() => navigate("/ingresso")}
              />
            </Flex>
            {user && !isLargerThan1024 && (
              <Flex flexDir="column" align="center" gap={2} px={20}>
                <Text
                  color={colors.white}
                  fontWeight="medium">
                  Olá , {user.name}
                </Text>
              </Flex>
            )}
            <Flex
              gap={6}
              fontWeight="medium"
              color="white"
              align={"center"}
              flexDir={isLargerThan1024 ? "row" : "column"}
              p={isLargerThan1024 ? "0" : " 20px"}
              mt={!!user ? "" : "15px"}
              rounded={10}
              position="relative"
            >
              <Flex
                _hover={{ color: colors.blue300 }}
                cursor="pointer"
                onClick={() => navigate("/parque-aquatico")}
              >
                PARQUE AQUÁTICO
              </Flex>
              <Flex
                _hover={{ color: colors.blue300 }}
                cursor="pointer"
                onClick={() => navigate("/duvidas")}
              >
                DÚVIDAS FREQUENTES
              </Flex>
              <Flex
                _hover={{ color: colors.blue300 }}
                cursor="pointer"
                onClick={() => navigate("/contato")}
              >
                CONTATO
              </Flex>
              <Flex
                _hover={{ color: colors.blue300 }}
                cursor="pointer"
                onClick={() => navigate("/como-chegar")}
              >
                COMO CHEGAR
              </Flex>
              <Flex
                _hover={{ color: colors.blue300 }}

                cursor="pointer"
                onClick={() => navigate("/minhas-compras")}
              >
                MINHAS COMPRAS
              </Flex>
              {user && isLargerThan1024 ? (
                <Flex
                  cursor="pointer"
                  onClick={() => setOpenMenu(!openMenu)}
                  zIndex={100}
                >
                  <Flex align="center" gap={2}>
                    <Flex
                      w="40px"
                      h="40px"
                      bg="white"
                      rounded="full"
                      align="center"
                      justify="center"
                      color={colors.blue500}
                    >
                      {user?.name.charAt(0)}
                    </Flex>
                    <Flex>
                      <AiFillCaretDown size={15} />
                    </Flex>
                  </Flex>
                  {openMenu ? (
                    <Flex
                      right={["20px", "40px", "40px", "-60px"]}
                      position="absolute"
                    >
                      <MenuNavegation user={user} handleLogout={handleLogout} />
                    </Flex>
                  ) : null}
                </Flex>
              ) : null}
              {user && !isLargerThan1024 ? (
                <>
                  <Flex
                    _hover={{ color: colors.blue300 }}

                    cursor="pointer"
                    onClick={() => navigate("/perfil")}
                  >
                    MEUS DADOS
                  </Flex>
                  <Flex
                    _hover={{ color: colors.blue300 }}

                    cursor="pointer"
                    onClick={() => logout()}
                  >
                    SAIR
                  </Flex>
                </>

              ) : null}
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
}
