import { Tag } from "@chakra-ui/react";

interface TagPagamentoProps {
  icon?: React.ReactNode;
  title: string;
  colorScheme: string;
}

export default function TagPagamento({
  colorScheme,
  title,
  icon,
}: TagPagamentoProps) {
  return (
    <Tag
      rounded={15}
      px={4}
      py={1}
      gap={2.5}
      border={`solid 2px ${colorScheme}`}
      size="xs"
      fontSize="14px"
      fontWeight="medium"
      bg="white"
      color={colorScheme}
    >
      {icon}
      {title}
    </Tag>
  );
}
