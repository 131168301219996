import { api } from "infra/axios";
import { useQuery } from "react-query";
import { Informativo } from "../types";

export function useInformativosList() {
  const url = `/v1/site-informativo/ultimos`;

  return useQuery(url, async () => {
    const { data } = await api.get<Informativo[]>(url);

    return data;
  });
}
