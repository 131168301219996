import { AxiosError } from "axios";
import { useTokenStore } from "core/features/auth/hooks/useTokenStore";
import { api } from "infra/axios";
import { showError, showSuccess } from "infra/packages/helpers/alerta";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useIngressosStore } from "ui/pages/Admin/Ingresso/hooks/useIngressosStore";

interface Address {
  cep: string;
  nome: string;
  complemento?: string;
  cepId: number;
  cidadeId: number;
  nomeBairro: string;
  nomeEstado: string;
  nomeCidade: string;
  logradouro: string;
  bairroId: number;
  pais: string;
  numero: string;
}

interface ValidationErrors {
  field: string;
  errors: string[];
}

export interface ValidationDetails {
  statusCode: number;
  message: string;
  details: ValidationErrors[];
}

function useComprarIngressoCC() {
  const url = `v1/ingressos/comprar`;
  const token = useTokenStore((state) => state?.token?.access_token);
  const diaUtil = useIngressosStore((state) => state?.diaUtilSelecionado);
  const navigate = useNavigate();

  const { eventoId, setorId } = useParams<{
    eventoId: string;
    setorId: string;
  }>();

  return useMutation(
    async ({
      pessoaCarteiraId,
      qtdeParcelas,
      dadosEndereco,
    }: {
      pessoaCarteiraId: number | string;
      qtdeParcelas: number;
      dadosEndereco?: Address;
    }) => {
      const ingressos =
        useIngressosStore
          .getState()
          .ingressos?.filter((ingresso) => ingresso.quantity > 0) ?? [];

      const valorTotal = Object.entries(ingressos).reduce((acc, curr) => {
        const [, value] = curr;
        const taxa = value.item?.valorTaxaWeb || 0;
        return (
          acc +
          value.quantity *
            (value.item.valorBase + taxa + value.item.valorServico)
        );
      }, 0);

      const subTotal = Object.entries(ingressos).reduce((acc, curr) => {
        const [, value] = curr;
        return acc + value.quantity * value.item.valorBase;
      }, 0);

      const totalServico = Object.entries(ingressos).reduce((acc, curr) => {
        const [, value] = curr;

        return acc + value.item.valorServico;
      }, 0);

      const { data } = await api.post(
        url,
        {
          modalidadeCaixaApiId: 99,
          pessoaCarteiraId,
          qtdeParcelas: +qtdeParcelas,
          endereco: dadosEndereco,
          ingressoEventoId: eventoId,
          dataEscolhaId: diaUtil?.id,
          valorTotal,
          valorIngresso: subTotal,
          valorTaxa: 0,
          valorServico: totalServico,
          ingressosVendaItem: ingressos?.map((ingresso) => ({
            qtde: ingresso.quantity,
            valorUnitario: ingresso.item.valorBase + ingresso.item.valorTaxaWeb,
            ingressoSetorId: setorId,
            ingressoSetorOpcaoId: ingresso.item.id,
            valorIngresso:
              (ingresso.item.valorBase + ingresso.item.valorTaxaWeb) *
              ingresso.quantity,
            valorServico: ingresso.item.valorServico,
            valorTaxa: ingresso.item.valorTaxaWeb,
            valorTotal:
              (ingresso.item.valorBase +
                ingresso.item.valorTaxaWeb +
                ingresso.item.valorServico) *
              ingresso.quantity,
            utilizadorNome: "",
            utilizadorDocumento: "",
          })),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    },
    {
      onError: (error: AxiosError<ValidationDetails>) => {
        error.response?.data.details.forEach((detail) => {
          detail.errors.forEach((error) => {
            showError(error);
          });
        });
      },
      onSuccess() {
        showSuccess();
        navigate("/minhas-compras");
      },
    }
  );
}

export default useComprarIngressoCC;
