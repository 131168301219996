import { Center, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { formatMoney } from "core/helpers/number";
import colors from "core/resources/theme/colors";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import Button from "ui/components/Button/Button";
import Container from "ui/components/Layout/Container";
import BarStep from "../components/BarStep";
import StepCoponent from "../components/Pagamento/components/FormaPagamento/components/Pix/StepComponent";
import useCurrentStep from "../hooks/useCurrentStep";
import { useIngressosStore } from "../hooks/useIngressosStore";
import useStepTitle from "../hooks/useStepTitle";
import CountDownTimer from "./CountDownTime";

function Pix() {
  const activeStep = useCurrentStep();
  const [titleStep, titleNextStep] = useStepTitle(activeStep, activeStep + 1);
  const data = useIngressosStore((state) => state.pixData!);

  const dataVisita = useIngressosStore((state) =>
    moment(state.dataSelecionada).format("DD MMMM YYYY")
  );

  const total = useIngressosStore((state) =>
    state.ingressos
      ?.filter((ingresso) => ingresso.quantity > 0)
      .reduce((acc, curr) => {
        return (
          acc +
          curr.quantity *
          (curr.item.valorBase +
            curr.item.valorTaxaWeb +
            curr.item.valorServico)
        );
      }, 0)
  );

  const onCopy = () => {
    navigator.clipboard.writeText(data.pix.qrCodeData);
  };

  return (
    <Container>
      <Flex flexDir="column">
        <Flex
          pt={["130px", "130px", "130px", "330px"]}
          mb="80px"
          flexDir={"column"}
          gap={8}
        >
          <BarStep
            step={activeStep}
            titleNextStep={titleNextStep!}
            nextStep={activeStep + 1}
            titleStep={titleStep!}
          />

          <Stack spacing={[8, 8, 16]}>
            <Stack align="center" justify="center" spacing={2}>
              <Image
                src="/assets/pix.png"
                w="100%"
                maxW="100px"
                h="auto"
                objectFit="cover"
              />
              <Text fontSize="20px">Compra aguardando pagamento via Pix</Text>
              <Text fontSize="16px" color={colors.gray600}>
                Escaneie o QR Code ou copie o código
              </Text>
            </Stack>

            <Center>
              <Stack
                bg="white"
                shadow="md"
                px={6}
                py={4}
                w={["90%", "90%", "90%", "50%"]}
                rounded="md"
                spacing={6}
              >
                <Flex align="center" justify="space-between">
                  <Stack spacing={1}>
                    <Text fontSize="15px" color={colors.gray600}>
                      Data da visita
                    </Text>

                    <Text
                      fontSize="20px"
                      fontWeight="medium"
                      color={colors.blue500}
                    >
                      {dataVisita}
                    </Text>
                  </Stack>

                  <Stack spacing={1}>
                    <Text fontSize="15px" color={colors.gray600}>
                      Valor da compra
                    </Text>

                    <Text
                      fontSize="20px"
                      fontWeight="medium"
                      color={colors.blue500}
                    >
                      {formatMoney(total)}
                    </Text>
                  </Stack>
                </Flex>

                <Center>
                  <QRCodeSVG size={200} value={data?.pix?.qrCodeData} />
                  {/* <Image
                    src={data?.pix?.qrCode}
                    w="100%"
                    maxW="300px"
                    h="auto"
                    objectFit="contain"
                  /> */}
                </Center>

                <Center>
                  <Button
                    bg={colors.red}
                    title="COPIAR CÓDIGO"
                    onClick={onCopy}
                  />
                </Center>
              </Stack>
            </Center>

            <Center>
              <CountDownTimer />
            </Center>

            <Center>
              <Flex flexDir="column" gap={4} w={["90%", "90%", "90%", "50%"]}>
                <Text
                  fontSize="20px"
                  mb={2}
                  color={colors.blue500}
                  align="center"
                  fontWeight="medium"
                >
                  Pagar com Pix é seguro, fácil e rápido!
                </Text>
                <StepCoponent
                  step="1"
                  textStep=" Abra o aplicativo do seu banco no celular."
                />
                <StepCoponent
                  step="2"
                  textStep=" Clique em Pix e escolha a opção Pagar por QR Code."
                />
                <StepCoponent
                  step="3"
                  textStep="Aponte a câmera do celular para o QR Code que será exibido após a
            finalização da compra."
                />
                <StepCoponent
                  step="4"
                  textStep="Pronto, agora é só confirmar os dados e finalizar o pagamento com
          o Pix."
                />
              </Flex>
            </Center>
          </Stack>
        </Flex>
      </Flex>
    </Container>
  );
}

export default Pix;
