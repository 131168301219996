import { Flex, Text } from "@chakra-ui/react";
import { formatMoney } from "core/helpers/number";
import colors from "core/resources/theme/colors";
import moment from "moment";
import { BsCalendarCheck } from "react-icons/bs";
import { MdShoppingCart } from "react-icons/md";
import { useIngressosStore } from "ui/pages/Admin/Ingresso/hooks/useIngressosStore";

export default function InformacaoPagamentoBar() {
  const dataVisita = useIngressosStore((state) =>
    moment(state.dataSelecionada).format("DD/MM/YYYY")
  );

  const total = useIngressosStore(({ ingressos }) => {
    return Object.entries(ingressos || []).reduce((acc, curr) => {
      const [, value] = curr;
      const taxa = value.item.valorTaxaWeb ? value.item.valorTaxaWeb : 0;
      const servico = value.item.valorServico ? value.item.valorServico : 0;

      return acc + value.quantity * (value.item.valorBase + taxa + servico);
    }, 0);
  });

  return (
    <Flex
      w="100%"
      justify="space-between"
      align={["start", "center"]}
      rounded={4}
      px={4}
      flexDir={["column", "column", "row"]}
    >
      <Flex flexDir="column" gap={2}>
        <Text>Data da vista</Text>
        <Flex>
          <Flex gap={4}>
            <BsCalendarCheck size={30} color={colors.blue} />
            <Text fontSize="24px" color={colors.blue}>
              {dataVisita}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDir="column" gap={2}>
        <Text>Total</Text>
        <Flex>
          <Flex gap={4}>
            <MdShoppingCart size={30} color={colors.blue} />
            <Text fontSize="24px" fontWeight="bold" color={colors.blue}>
              {formatMoney(total)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
