import { Route, Routes } from "react-router-dom";
import Login from "ui/pages/Auth/Login";
import Reset from "ui/pages/Auth/Reset/index";
import Admin from "./Admin";
import CalendarioFuncionamento from "./Admin/CalendarioFuncionamento/index";
import ComoChegar from "./Admin/ComoChegar";
import Contato from "./Admin/Contato/index";
import Duvidas from "./Admin/Duvidas/index";
import EditarPerfilTabs from "./Admin/EditarPerfil/EditarPerfilTabs";
import Home from "./Admin/Home/index";
import Informativos from "./Admin/Informativos";
import InformativoDetalhado from "./Admin/Informativos/InformativoDetalhado";
import ComprarIngressos from "./Admin/Ingresso/ComprarIngressos";
import Pix from "./Admin/Ingresso/Confirmacao/Pix";
import DataPasseio from "./Admin/Ingresso/DataPasseio";
import IdentificacaoPage from "./Admin/Ingresso/Identificacao";
import ListagemIngressos from "./Admin/Ingresso/ListagemIngressos";
import PagamentoPage from "./Admin/Ingresso/Pagamento";
import MinhasComprasPage from "./Admin/MinhasCompras/index";
import MinhasCompras from "./Admin/MinhasCompras/Listagem/components";
import DetalheCompra from "./Admin/MinhasCompras/Listagem/components/DetalheCompra";
import ParqueAquatico from "./Admin/ParqueAquatico";
import Sobre from "./Admin/Sobre";
import Auth from "./Auth/index";
import Register from "./Auth/Register";
import Aniversariante from "./Admin/Aniversariante";

export default function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Admin />}>
        <Route path="/" element={<Home />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/parque-aquatico" element={<ParqueAquatico />} />
        <Route path="/informativos" element={<Informativos />} />
        <Route
          path="/informativos/:infoId"
          element={<InformativoDetalhado />}
        />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/duvidas" element={<Duvidas />} />
        <Route path="/como-chegar" element={<ComoChegar />} />
        <Route path="/ingresso">
          <Route path="" element={<ListagemIngressos />} />
          <Route path=":eventoId/setor" element={<DataPasseio />} />
          <Route
            path=":eventoId/setor/:setorId"
            element={<ComprarIngressos />}
          />
          <Route
            path=":eventoId/setor/:setorId/identificacao"
            element={<IdentificacaoPage />}
          />
          <Route
            path=":eventoId/setor/:setorId/pagamento"
            element={<PagamentoPage />}
          />
          <Route
            path=":eventoId/setor/:setorId/confirmacao/pix"
            element={<Pix />}
          />
        </Route>
        <Route path="/contato" element={<Contato />} />
        <Route path="/aniversariante" element={<Aniversariante />} />
        <Route
          path="/calendario-funcionamento"
          element={<CalendarioFuncionamento />}
        />
 <Route  path="perfil" element={<EditarPerfilTabs />} />
        <Route  path="perfil/:tab" element={<EditarPerfilTabs />} />
        <Route path="" element={<MinhasComprasPage />}>
          <Route path="minhas-compras" element={<MinhasCompras />} />
          <Route path="minhas-compras/:id" element={<DetalheCompra />} />
        </Route>
      </Route>

      <Route path="auth" element={<Auth />}>
        <Route path="login" element={<Login />} />
        <Route path="cadastro" element={<Register />} />
        <Route path="recuperar-senha" element={<Reset />} />
      </Route>
    </Routes>
  );
}
