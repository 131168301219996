import { Box, BoxProps, Flex } from "@chakra-ui/layout";
import colors from "core/resources/theme/colors";
import { useState } from "react";

interface TabsProps {
  children: JSX.Element[];
  day?: string;
  borderTopRightRadiusDate?: boolean;
  containerProps?: BoxProps;
}

function Tab({
  children,
  containerProps,
  day,
  borderTopRightRadiusDate = true,
}: TabsProps) {
  const [selected, setSelected] = useState(0);

  const handleChange = (index: number) => {
    setSelected(index);
  };

  return (
    <div>
      <Box display="flex">
        {children.map((elem: any, index: number) => {
          let actived =
            index === selected
              ? {
                  border: `1px solid ${colors.blue}`,
                  background: colors.blue,
                  color: "#FFFFFF",
                  fontWeight: "bold",
                }
              : {
                  color: colors.blue,
                  background: colors.lightBlue,
                  fontWeight: "bold",
                };
          return (
            <Box
              key={index}
              onClick={() => handleChange(index)}
              display={"flex"}
              w="full"
              maxW="300px"
              justifyContent={"center"}
              p={"15px 0"}
              borderRadius={"16px 16px 0 0"}
              style={{ border: "1px solid #EAEAEA", ...actived }}
              fontSize={14.96}
              cursor={"pointer"}
            >
              {elem.props.title}
            </Box>
          );
        })}
      </Box>

      <Flex
        align="center"
        color="white"
        fontSize="18px"
        fontWeight="medium"
        w="100%"
        h={day ? "50px" : "8px"}
        bg={colors.blue}
        borderTopRightRadius={borderTopRightRadiusDate ? 10 : 0}
        borderBottom={`solid ${day ? "10px" : "2px"} ${colors.blue500}`}
        pl={4}
      >
        {day ? day : null}
      </Flex>

      <Box
        background={"#FAFAFA"}
        border={"#EAEAEA 1px solid"}
        p={"25px 24px"}
        {...containerProps}
      >
        {children[selected]}
      </Box>
    </div>
  );
}

export default Tab;
