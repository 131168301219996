import { Box, Flex, Textarea } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import Field from "ui/components/Input/Field";
import InputMask from "ui/components/Input/InputMask/index";
import DefaultButton from "ui/components/Button/DefaultButton";
import colors from "core/resources/theme/colors";
import { BiMailSend } from "react-icons/bi";
import { useMutation } from "react-query";
import { enviarContato } from "core/features/contato/hooks/useEnviarContato";
import moment from "moment";
import { showSuccess } from "infra/packages/helpers/alerta";
import { useNavigate } from "react-router-dom";

export default function FormContato() {
  const { control, handleSubmit, reset } = useForm<any>({});
  const navigate = useNavigate();

  const { mutate } = useMutation(enviarContato, {
    onSuccess() {
      showSuccess("Contato enviado com sucesso");
      reset({});
      navigate("/");
    },
  });
  const onSubmit = (form: any) => {
    mutate({
      ...form,
      empresaId: 1,
      dataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        flexDir="column"
        w={["100%", "100%", "100%", "600px", "1000px"]}
        gap={10}
      >
        <Box>
          <Field
            control={control}
            name="nome"
            inputProps={{ placeholder: "Nome*" }}
            rules={{
              required: {
                message: "Informe seu nome",
                value: true,
              },
            }}
          />
        </Box>
        <Flex
          flexDir={["column", "row"]}
          justifyContent="space-between"
          gap={4}
        >
          <Box w="100%">
            <Field
              control={control}
              name="telefone"
              rules={{
                required: {
                  message: "Telefone*",
                  value: true,
                },
              }}
              render={({ field }) => (
                <InputMask
                  {...field}
                  placeholder="(99) 99999-9999"
                  mask="(99) 99999-9999"
                />
              )}
            />
          </Box>{" "}
          <Box w="100%">
            <Field
              control={control}
              name="email"
              inputProps={{ placeholder: "Email*" }}
              rules={{
                required: {
                  message: "Informe seu E-mail",
                  value: true,
                },
              }}
            />
          </Box>
        </Flex>
        <Box>
          <Field
            name="mensagem"
            rules={{
              required: "Informe sua mensagem",
            }}
            control={control}
            render={({ field, isInvalid }) => (
              <Textarea
                isInvalid={isInvalid}
                className="chakra-input"
                rows={8}
                placeholder="Mensagem"
                {...field}
              />
            )}
          />
        </Box>
        <Flex w="100%" justify="center">
          <DefaultButton
            type="submit"
            title="ENVIAR"
            bg={colors.red}
            icon={<BiMailSend size={20} />}
          />
        </Flex>
      </Flex>
    </form>
  );
}
