import { Flex } from "@chakra-ui/react";
import { DiaUtil } from "core/features/ingresso/typings";
import colors from "core/resources/theme/colors";
import moment from "moment";
import { useEffect, useState } from "react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import CardDay from "./components/CardDay";
import { buildCalendar } from "./utils";

interface CalendarProps {
  daysOpen: DiaUtil[];
  widthCalendar?: string[];
  heightCalendar?: string[];
  widthCalendarDay?: string[];
  heightCalendarDay?: string[];
  gapSemanas?: string[];
  pendingXCalendar?: number[];
  fontSizeStatusDay?: string[];
  mlSemanas?: string[];
  justifySemanas?: boolean;
  fieldOnChange?: (...event: any[]) => void;
  onChangeDiaUtil?: (diaUtil?: DiaUtil) => void;
}

moment.updateLocale("pt", {
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
});

export default function Calendar({
  daysOpen,
  justifySemanas = true,
  widthCalendar = ["555px"],
  heightCalendar = ["530px"],
  widthCalendarDay = ["70px"],
  heightCalendarDay = ["70px"],
  gapSemanas = ["48px"],
  pendingXCalendar = [2],
  mlSemanas = ["35px"],
  fontSizeStatusDay,
  fieldOnChange,
  onChangeDiaUtil,
}: CalendarProps) {
  const [calendar, setCalendar] = useState<moment.Moment[][]>([]);
  const [value, setValue] = useState(moment().locale("pt"));

  const [selectDay, setSelectDay] = useState(moment(""));

  useEffect(() => {
    setCalendar(buildCalendar(value));
  }, [value]);

  function currMonthName() {
    return value.format("MMMM - YYYY");
  }

  function prevMonth() {
    return value.clone().subtract(1, "month");
  }

  function nextMonth() {
    return value.clone().add(1, "month");
  }

  function thisMonth() {
    return value.isSame(new Date(), "month");
  }

  return (
    <Flex flexDir="column">
      <Flex
        justify="center"
        bg={colors.blue}
        h="100px"
        w={widthCalendar}
        roundedTop={10}
        borderTop={`solid 4px ${colors.blue500}`}
        align="center"
        gap={4}
      >
        <Flex flexDir="column" align="center">
          <Flex w="15px" h="15px" rounded="full" bg="white" mb="10px" />
          <Flex align="center">
            <Flex
              onClick={() => !thisMonth() && setValue(prevMonth())}
              _hover={{ opacity: 0.8 }}
            >
              {!thisMonth() ? (
                <MdArrowBackIos size={20} color="white" cursor="pointer" />
              ) : null}
            </Flex>
            <Flex color="white" fontSize="24px" w="220px" justify="center">
              {currMonthName()}
            </Flex>
            <Flex
              onClick={() => setValue(nextMonth())}
              cursor="pointer"
              _hover={{ opacity: 0.8 }}
            >
              <MdArrowForwardIos size={20} color="white" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        w={widthCalendar}
        h={heightCalendar}
        border={`solid 1px ${colors.gray100}`}
        wrap="wrap"
        py={2}
        px={pendingXCalendar}
        roundedBottom={10}
        bg="white"
      >
        <Flex
          w="100%"
          justify={justifySemanas ? "space-between" : "none"}
          gap={!justifySemanas ? gapSemanas : 0}
          ml={mlSemanas}
          h="40px"
          align="center"
          fontWeight="medium"
        >
          {["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SÁB"].map((d, index) => (
            <Flex key={index} color={colors.blue}>
              {d}
            </Flex>
          ))}
        </Flex>
        {calendar.map((week, index) => (
          <Flex key={index} align="center" m="auto" w="100%">
            {week.map((day) => (
              <CardDay
                key={day.format()}
                widthCalendarDay={widthCalendarDay}
                heightCalendarDay={heightCalendarDay}
                day={day}
                daysOpen={daysOpen}
                setValue={setValue}
                setSelectDay={setSelectDay}
                selectDay={selectDay}
                fontSizeStatusDay={fontSizeStatusDay}
                isCurrentMonth={day.isSame(value, "month")}
                fieldOnChange={fieldOnChange}
                onChangeDiaUtil={onChangeDiaUtil}
              />
            ))}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
