import { Flex, Text, Box, VStack } from "@chakra-ui/layout";
import { formatMoney } from "core/helpers/number";
import colors from "core/resources/theme/colors";
import ContentItem from "./ContentItem";
import moment from "moment";
import React from "react";
import styled from "@emotion/styled";
import { QRCodeSVG } from "qrcode.react";
import { Image } from "@chakra-ui/react";

interface ImpressaoIngressoProps {
  data: any;
}
const ImpressaoIngresso = React.forwardRef(
  ({ data }: ImpressaoIngressoProps, ref) => {
    return (
      <StyledBox
        display={"none"}
        ref={ref as any}
        flexDir="column"
        p={8}
        rounded={10}
      >
        <Flex align={"center"} mb={16} gap={"2rem"}>
          <Image
            src={'https://tactonuvem.blob.core.windows.net/imagens/900b34b0-9d5d-4e52-b6ed-80d9c42d8825/banners/logoacquapark-01.png'}
            maxWidth={["100px", "100px", "100px", "200px", "240px"]}
            width="100%"
          />
          <Text
            color={colors.blue}
            fontFamily={"heading"}
            fontSize="32px"
            fontWeight={"bold"}
          >
            ÁCQUA PARK PANTANAL
          </Text>
        </Flex>
        <Flex justifyContent={"space-between"}>
          <Text fontFamily={"heading"} fontSize="32px" fontWeight={"bold"}>
            # {data.id}
          </Text>
          {data?.dataPrevistaUtilizacao && (
            <ContentItem
              titulo="DATA PREVISTA UTILIZAÇÃO:"
              descricao={moment(data?.dataPrevistaUtilizacao).format(
                "DD/MM/YYYY"
              )}
            />
          )}
          <ContentItem titulo="STATUS" descricao={data?.statusNome} />
          {data?.statusNome && (
            <ContentItem titulo="SETOR:" descricao={data?.setorNome} />
          )}

          <ContentItem
            titulo="VALOR TOTAL"
            descricao={formatMoney(data?.valorTotal)}
          />
        </Flex>
        <Flex justifyContent={"space-between"}>
          {data?.utilizadorNome && (
            <ContentItem
              titulo="NOME DO UTILIZADOR:"
              descricao={data?.utilizadorNome}
            />
          )}
          {data?.utilizadorDocumento && (
            <ContentItem
              titulo="DOCUMENTO UTILIZADOR:"
              descricao={data?.utilizadorDocumento}
            />
          )}
          {data?.utilizadorEmail && (
            <ContentItem
              titulo="EMAIL DO UTILIZADOR:"
              descricao={data?.utilizadorEmail}
            />
          )}
        </Flex>
        <Flex mt={16} justify={"space-between"}
          color="black"
        >

          <VStack>
            <Text fontSize="32px" alignSelf={"flex-start"}>
              {data?.eventoNome}
            </Text>
            <Text fontSize="24px" alignSelf={"flex-start"}>
              {data?.opcaoNome?.toUpperCase()}
            </Text>
          </VStack>
          {data?.chave && (
            <Box>
              <ContentItem
                titulo=""
                descricao={<QRCodeSVG size={200} value={data?.chave} />}
              />
            </Box>
          )}
        </Flex>
        <Box mt={16} fontSize="18px">
          <Text fontSize="18px">*Obrigatório a apresentação de documento oficial com foto na portaria do PARK</Text>

          <Text fontSize="18px">*Crianças menores de idade devem estar acompanhadas dos pais ou responsáveis, munido de documento de identidade ou certidão de nascimento.
          </Text>


        </Box>
        <Flex fontSize="1rem" justify={"space-between"} mt={16}>
          <Text>TACTO SISTEMAS</Text>
          <Text>{moment().format("DD/MM/YYYY HH:mm")}</Text>
        </Flex>
      </StyledBox>
    );
  }
);
export default ImpressaoIngresso;

const StyledBox = styled(Flex)`
  @media print {
    display: block;
  }
`;
