import { Box, Flex } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { useForm } from "react-hook-form";
import { BiChevronLeft } from "react-icons/bi";
import DefaultButton from "ui/components/Button/DefaultButton";
import OutlineButton from "ui/components/Button/OutlineButton";
import { Tela } from "ui/pages/Admin/Ingresso/components/Identificacao";

import useSignup from "core/features/auth/hooks/useSignup";
import { useRegisterStore } from "core/features/register/register.store";
import { FormularioCadastroUsuario } from "core/features/register/typings";
import { useNavigate } from "react-router-dom";
import DadosAcesso from "./DadosAcesso";
import DadosPessoais from "./DadosPessoais";
// import Endereco from "./Endereco";

interface RegisterProps {
  defaultData?: Partial<any>;
  setTela?: (v: any) => void;
  prevStep?: () => void;
  goToIfSucceed?: string;
}

export function RegisterForm({ setTela, goToIfSucceed = "/" }: RegisterProps) {
  const { selectedForm } = useRegisterStore();
  const { control, getValues, trigger } = useForm<FormularioCadastroUsuario>({
    defaultValues: { email: selectedForm?.email },
  });

  const navigate = useNavigate();
  const { mutate: signup, } = useSignup(() => {
    navigate(goToIfSucceed);
  });

  const onSubmit = async (form: any) => {
    const codigoPais = form.codigoPais.replace(/\D/g, ""); // Remove non-digit characters
  
    signup({
      ...form,
      nome: form.nome + " " + form.sobrenome,
      telefone: `${codigoPais}${form.celular?.replace(/\D/g, "")}`,
      celular: `${codigoPais}${form.celular?.replace(/\D/g, "")}`,
      cpf: form.cpf.replace(/\D/g, ""),
    });
  };
  

  return (
    <Box w="100%">
      <form>
        <DadosAcesso control={control} getValues={getValues} />
        <DadosPessoais control={control} />
        {/* <Endereco control={control} setValue={setValue} form={watch()} /> */}

        <Flex justify="space-between" mt="100px" align="center">
          <OutlineButton
            title="VOLTAR"
            color={colors.blue}
            prevIcon={<BiChevronLeft size={20} />}
            onClick={() => setTela!(Tela.Identificacao)}
          />
          <DefaultButton
            maxH={6}
            onClick={async () => {
              const valued = await trigger();
              if (valued) {
                onSubmit(getValues());
              }
            }}
            bg={colors.red}
            title={"CADASTRAR"}
          />
        </Flex>
      </form>
    </Box>
  );
}

export default function Register(props: RegisterProps) {
  return (
    <Flex
      py={["150px", "150px", "150px", "350px"]}
      px={["50px", "50px", "30px", "200px"]}
      flexDir={"column"}
    >
      <RegisterForm {...props} />
    </Flex>
  );
}
