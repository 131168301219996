import { Flex, Text } from "@chakra-ui/react";
import Button from "ui/components/Button/Button";
import colors from "core/resources/theme/colors";
import cardsPay from "assets/images/svgs/CardsPay.svg";

interface AdicionarCartaoProps {
  setCadastrarCard: (v: boolean) => void;
}

export default function AdicionarCartao({
  setCadastrarCard,
}: AdicionarCartaoProps) {
  return (
    <Flex align="center" flexDir="column" justify="center" w="100%" gap={10}>
      <Flex flexDir="column" gap={4} align="center">
        <Flex
          bgImage={cardsPay}
          bgRepeat="no-repeat"
          bgSize="cover"
          w="120px"
          height="110px"
        />
        <Text fontWeight="medium" fontSize="24px">
          Nenhum cartão cadastrado :(
        </Text>
        <Text w="250px" textAlign="center">
          Adicione seu cartão! É seguro, prático e você não perde tempo para
          fazer seus pagamentos.
        </Text>
      </Flex>

      <Button
        bg={colors.red}
        title="ADICIONAR CARTÃO"
        onClick={() => setCadastrarCard(true)}
      />
    </Flex>
  );
}
