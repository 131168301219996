import { AspectRatio, Flex, Img, Text } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import CollapsibleCard from "./components/CollapsibleCard";
import mascote from "assets/images/svgs/mascote-vermelho.svg";
import Button from "ui/components/Button/Button";
import { BsChatDots } from "react-icons/bs";
import localizacao from "assets/images/localizacao.png";
import { useNavigate } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";
import OutlineButton from "ui/components/Button/OutlineButton";

export default function Duvidas() {
  const navigate = useNavigate();

  return (
    <Flex
      py={["150px", "150px", "150px", "350px"]}
      px={["50px", "50px", "50px", "200px"]}
      gap="80px"
      flexDir={["column"]}
    >
      <Flex
        gap={10}
        flexDir={["column", "column", "column", "column", "row"]}
        alignItems="center"
      >
        <Flex flexDir="column" gap="6" w={["100%", "100%", "100%", "100%"]}>
          <Text
            fontWeight="medium"
            fontSize="24px"
            color={colors.purple500}
            flexWrap="wrap"
          >
            DÚVIDAS FREQUENTES
          </Text>
          <CollapsibleCard
            titleCard="Aniversariantes do mês pagam ingresso?"
            children={
              <Flex>
                Aos sábados, os aniversariantes do mês não pagam ingresso 🥳.
                Basta apresentar o documento pessoal na portaria do parque para
                retirada da pulseira cortesia. Nosso presente pra você é muita
                diversão!!🎉
              </Flex>
            }
          />
          <CollapsibleCard
            titleCard="Como funciona o passaporte anual?"
            children={
              <Flex>
                O passaporte te dará livre acesso ao parque nos dias de
                funcionamento, sem a necessidade de enfrentar filas e por um
                preço muito mais acessível. 👨‍👩‍👧 Para saber mais, acesse o menu e
                clique em orçamento, passaporte anual e fale com a nossa equipe
                comercial. 😁
              </Flex>
            }
          />
          <CollapsibleCard
            titleCard="Comprei pelo site, como retirar o ingresso? 🎟️"
            children={
              <Flex>
                Basta apresentar o seu documento pessoal na portaria para
                retirar a pulseira na data marcada. 🗓️
              </Flex>
            }
          />
          <CollapsibleCard
            titleCard="Posso pernoitar no parque? 🌒🌛"
            children={
              <Flex mb={4}>
                <Text>
                  Não é possível. No momento o parque está abrindo apenas para
                  day use. ☀️🌞 Compre o seu ingresso antecipado pelo site:
                  {""}
                  <b
                    style={{
                      color: "#8e95f2",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/ingresso")}
                  >
                    {" "}
                    Clique Aqui
                  </b>
                </Text>
              </Flex>
            }
          />
          <CollapsibleCard
            titleCard="Posso remarcar o meu ingresso?🎟️ 🗓️"
            children={
              <Flex>
                Ao adquirir seu ingresso pelo site o mesmo ficará “disponível”
                para escolha da data de utilização em; “minhas compras”. Caso
                não comparecer na data escolhida seu ingresso ficará disponível
                por 90 dias sem necessidade de remarcar.
              </Flex>
            }
          />
          <CollapsibleCard
            titleCard="Quais as formas de pagamento? 💵"
            children={
              <Flex>
                As compras de ingressos antecipados poderão ser realizadas por
                pix ou cartão de crédito em até 3x pelo site ou via WhatsApp.
                Compras de ingressos na portaria do parque podem ser realizadas
                em dinheiro, pix, cartão de débito ou crédito em até 3x. Nas
                demais compras dentro do parque aceitamos; dinheiro, pix,
                crédito a vista e débito.
              </Flex>
            }
          />
          <CollapsibleCard
            titleCard="Quais os dias e horários de funcionamento? 🗓️"
            children={
              <Flex>
                O parque abre aos sábados e domingos das 09:00 às 17:00
              </Flex>
            }
          />{" "}
          <CollapsibleCard
            titleCard="Qual a localização?📍"
            children={
              <a
                href="https://goo.gl/maps/2MWKGkyCoyxPXTnv5"
                target={"_blank"}
                rel="noreferrer"
              >
                <AspectRatio ratio={4 / 3}>
                  <Img src={localizacao} />
                </AspectRatio>
              </a>
            }
          />{" "}
          <CollapsibleCard
            titleCard="Qual o valor do ingresso? 🎫"
            children={
              <Flex>
                <p>
                  Comprando direto no site ou via WhatsApp do Ácqua Park
                  Pantanal você garante descontos antecipados nas compras entre
                  segunda a sexta-feira.
                </p>
              </Flex>
            }
          />
          <CollapsibleCard
            titleCard="Posso cancelar minha compra? 🎫"
            children={
              <Flex>
                <p>
                  Sim! No prazo de até 7 dias as compras pelo site podem ser
                  canceladas com direito ao reembolso. Passado este período não
                  é possível cancelar, mas é possível remarcar o ingresso para
                  outra data.
                </p>
              </Flex>
            }
          />
          <CollapsibleCard
            titleCard="Quem tem direito a meia entrada? 🎫"
            children={
              <Flex>
                <p>
                  Idosos, estudantes, jornalistas, jovens de baixa renda e
                  doadores de sangue pagam meia. Basta apresentar o documento
                  comprobatório correspondente na portaria do parque para obter
                  o desconto. Idosos (documento pessoal com foto ou
                  carteirinha). Estudantes (Documento pessoal com foto e
                  carteirinha de estudante ou atestado de matrícula vigente).
                  Doares de sangue e medula óssea (Documento pessoal com foto e
                  carteirinha de doador frequente comprovando a realização de no
                  mínimo 3 doações de sangue no período de 12 meses, uma doação
                  de plaquetas ou inscrição no banco de dados de doares
                  voluntários.). Jornalistas (Carteira funcional dentro do prazo
                  de validade). Jovem de baixa renda (Documento pessoal e
                  carteirinha de ID JOVEM dentro do prazo de validade).
                </p>
              </Flex>
            }
          />
          <CollapsibleCard
            titleCard="PCD e TEA pagam ingresso? 🎫"
            children={
              <Flex>
                <p>
                  Não! Pessoas com deficiência e Transtorno do Espectro Autista
                  não pagam ingresso. Basta apresentar a carteirinha na portaria do parque. Pessoas com restrições
                  alimentares podem entrar com a sua alimentação desde que
                  apresentem o laudo com a restrição indicada pelo médico. Caso
                  esteja expresso na carteirinha a necessidade de acompanhante,
                  este também terá direito à uma cortesia.{" "}
                </p>
              </Flex>
            }
          />
          <CollapsibleCard
            titleCard="Posso entrar com alimento e bebidas no parque?"
            children={
              <Flex>
                <p>
                  Não! Inclusive também não é permitido entrar com itens para
                  aniversário como bolos e doces. O parque disponibiliza
                  restaurantes, bares e doceria para a sua comodidade. Além
                  disso, estando com a pulseira é possível entrar e sair do
                  parque quantas vezes for necessário durante o horário de
                  funcionamento.
                </p>{" "}
              </Flex>
            }
          />
          <CollapsibleCard
            titleCard="O que não é permitido nas piscinas do Ácqua Park?"
            children={
              <Flex>
                <p>
                  Usar curativos, faixas, gaze, gesso, absorventes higiênicos,
                  algodão ou pomadas; Usar bronzeadores, sabonetes, copos e
                  utensílios de vidro Realizar brincadeiras que impliquem riscos
                  aos demais frequentadores do Parque; Usar shorts, bermudas,
                  calça jeans, vestidos ou qualquer roupa que possua zíper,
                  botões, ilhós e outros adereços que possam machucar a si ou a
                  terceiros e/ou danificar os equipamentos do parque; Não será
                  permitido qualquer tipo de comportamento discriminatório e/ou
                  indevido que possa ofender a moral e a dignidade dos usuários.
                </p>
              </Flex>
            }
          />
          <CollapsibleCard
            titleCard="Quais os trajes de banhos permitidos?"
            children={
              <Flex>
                <p>
                  O traje de banho masculino será preferencialmente sunga,
                  podendo ainda usar calção com forração interna, tipo tactel. O
                  traje feminino será preferencialmente biquini, maiô ou shorts
                  em malha.
                </p>
              </Flex>
            }
          />
        </Flex>

        <Flex
          bgImage={mascote}
          bgRepeat="no-repeat"
          bgSize="cover"
          w={["120px", "120px", "120px", "250px", "310px"]}
          height={["180px", "180px", "190px", "400px"]}
        />
      </Flex>

      <Flex flexDir="column" gap={4} align="center">
        <Text
          fontWeight="medium"
          fontSize="24px"
          color={colors.purple500}
          flexWrap="wrap"
        >
          AINDA TEM DÚVIDAS?
        </Text>
        <Button
          bg={colors.red}
          onClick={() => navigate("/contato")}
          title="ENTRE EM CONTATO"
          icon={<BsChatDots size={20} />}
        />
      </Flex>
      <OutlineButton
        w={`200px`}
        onClick={() => navigate("/")}
        title="VOLTAR"
        color={colors.blue}
        prevIcon={<BiChevronLeft size={20} />}
      />
    </Flex>
  );
}
