import { Flex, Text } from "@chakra-ui/react";
// import backgroundAtracoesCarousel from "assets/images/svgs/backgrounAtracoesCarousel.svg";
import colors from "core/resources/theme/colors";
import AtracaoCard from "./components/AtracaoCard";
import card1 from "assets/images/PiscinaOndas.jpeg.jpg";
import card2 from "assets/images/BaldãoMaluco.jpg";
import card3 from "assets/images/BarMolhado.jpg";
import card4 from "assets/images/Toboaguas.jpeg.jpg";
import card5 from "assets/images/bgHome.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useWindowDimensions from "core/helpers/windowDimensions";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

export default function AtracoesCarousel() {
  const { width } = useWindowDimensions();

  const getWidhtCarouse = () => {
    if (width > 1024) {
      return "1100px";
    } else if (width > 800) {
      return "600px";
    }
    return "340px";
  };

  const getSliderPercentage = () => {
    if (width > 1024) {
      return 33.333;
    } else if (width > 900) {
      return 50;
    }
    return 100;
  };

  return (
    <Flex flexDir={"column"} w="100%">
      <Text fontWeight="medium" fontSize="24px" color={colors.purple500}>
        PRINCIPAIS ATRAÇÕES:
      </Text>
      <Flex mt="50px">
        <Flex w="100%" >
          <Carousel
            centerMode
            infiniteLoop={true}
            centerSlidePercentage={getSliderPercentage()}
            width={getWidhtCarouse()}
            renderArrowPrev={(clickHandle, hasPrev) =>
              hasPrev && (
                <Flex
                  onClick={clickHandle}
                  rounded="full"
                  bg={colors.blue100}
                  w="40px"
                  h="40px"
                  justify="center"
                  align="center"
                  position="absolute"
                  zIndex={10}
                  top="200px"
                  left={0}
                >
                  <MdOutlineArrowBackIos color="white" />
                </Flex>
              )
            }
            renderArrowNext={(clickHandle, hasNext) =>
              hasNext && (
                <Flex
                  onClick={clickHandle}
                  rounded="full"
                  bg={colors.blue100}
                  w="40px"
                  h="40px"
                  justify="center"
                  align="center"
                  position="absolute"
                  top="200px"
                  right={0}
                >
                  <MdOutlineArrowForwardIos color="white" />
                </Flex>
              )
            }
            showStatus={false}
            renderIndicator={(_, visible) => (visible = false)}
          >
            <AtracaoCard
              bgImage={card4}
              titleCard="TOBOÁGUAS RADICAIS: FANTÁSTICO, FABULOSO E MAGNÍFICO"
              descriptionCard=
              {
                <>
                  <Text>
                    <b>•O Fantástico (cor vermelha)</b> é um toboágua radical de velocidade média com 8 curvas bem
                    fechadas. É o mais alto do parque com 13 metros de altura.
                  </Text>
                  <Text>
                    <b>•O Fabuloso (cor azul)</b> é um toboágua de alta velocidade, uma queda livre de 12 metros de
                    altura. Para quem curte muita adrenalina, este é o mais radical. Há quem diga que a alma
                    sai do corpo por um momento.
                  </Text>
                  <Text>
                    <b>•O Fantástico (cor vermelha)</b> é um toboágua radical de velocidade média com 8 curvas bem
                    fechadas. É o mais alto do parque com 13 metros de altura.
                  </Text>
                </>

              }


            />
            <AtracaoCard
              bgImage={card5}
              titleCard="ACQUALÂNDIA"
              descriptionCard="Esta atração é uma das preferidas da família, pois tem uma profundidade adequada para crianças menores e conta com muitos escorregadores infantis, a ÁCQUA RAMPA e o BALDE MALUCO, que também fazem a alegria de adultos que podem se divertir junto com seus filhos em um ambiente super colorido e divertido."
            />
            <AtracaoCard
              bgImage={card2}
              titleCard="BALDÃO MALUCO"
              descriptionCard="O BALDE MALUCO, também faz a alegria de adultos e crianças. A brincadeira é simples. Um grande 'balde' armazena a água e, quando cheio, cai dentro de uma piscina, formando uma cascata."
            />
            <AtracaoCard
              bgImage={card1}
              titleCard="PISCINA DE ONDAS"
              descriptionCard="
                Uma das maiores piscinas de ondas do Brasil! São 15 tipos de ondas que lembram as ondas do mar. Elas têm intensidades que vão das mais leves até as ondas do tipo tempestade e tsunami que são emocionantes! Os mais ousados costumam ficar no fundo para sentir a adrenalina das ondas mais agitadas. Já aqueles que preferem tranquilidade e relaxamento podem ficar no raso sentados e recebendo a ondas que quebram na borda"
            />
            <AtracaoCard
              bgImage={card3}
              titleCard="BAR MOLHADO"
              descriptionCard="É um riacho formado por 6 piscinas naturais com águas correntes e muito arborizado.
              Dentro dele tem mesas e cadeiras onde é possível sentar em família para se refrescar na
              sombra, enquanto toma uma bebida e desfruta daquela comida, lanche ou porção preferida.
            "
            />
          </Carousel>
        </Flex>
      </Flex>
    </Flex>
  );
}
