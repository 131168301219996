import { EventoSetorOpcao } from "core/features/eventos/types";
import { DiaUtil } from "core/features/ingresso/typings";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface PixData {
  id: number;
  pix: {
    qrCode: string;
    qrCodeData: string;
  };
}
export interface IngressoStore {
  dataSelecionada?: string;
  ingressos?: { id: number; quantity: number; item: EventoSetorOpcao }[];

  diaUtilSelecionado?: DiaUtil;
  setDiaUtilSelecionado: (diaUtil?: DiaUtil) => void;
  setDataSelecionada: (data: string) => void;
  setIngressos: (
    ingressos: { id: number; quantity: number; item: EventoSetorOpcao }[]
  ) => void;

  pixData?: PixData;
  setPixData: (pixData: PixData) => void;
}

export const useIngressosStore = create(
  persist<IngressoStore>(
    (set) => ({
      dataSelecionada: undefined,
      setDataSelecionada: (data) => {
        set({ dataSelecionada: data });
      },

      ingressos: undefined,
      setIngressos: (ingressos) => {
        set({ ingressos });
      },

      diaUtilSelecionado: undefined,
      setDiaUtilSelecionado: (diaUtil) => {
        set({ diaUtilSelecionado: diaUtil });
      },

      pixData: undefined,
      setPixData: (pixData) => {
        set({ pixData });
      },
    }),
    {
      name: "ingresso-store",
      getStorage: () => sessionStorage,
    }
  )
);
