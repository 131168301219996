import { Box, BoxProps } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { ReactNode } from "react";

interface TabsProps {
  children: ReactNode;
  day?: string;
  containerProps?: BoxProps;
  title?: string;
}

function IngressoContainer({
  title,
  children,
  containerProps,
  day,
}: TabsProps) {
  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"center"}
        p={"15px 0"}
        borderRadius={"16px 16px 0 0"}
        style={{
          border: `1px solid ${colors.blue}`,
          background: colors.blue,
          color: "#FFFFFF",
          fontWeight: "bold",
        }}
        fontSize={14.96}
      >
        <Text
          fontWeight="medium"
          fontSize={{ base: "16px", md: "24px" }}
          color={colors.white}
          flexWrap="wrap"
        >
          {title}
        </Text>
      </Box>

      <Box
        background={"#FAFAFA"}
        border={"#EAEAEA 1px solid"}
        p={"25px 24px"}
        {...containerProps}
      >
        {children}
      </Box>
    </div>
  );
}

export default IngressoContainer;
