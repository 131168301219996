import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import colors from "core/resources/theme/colors";

const theme = extendTheme({
  components: {
    Text: {
      baseStyle: {
        fontFamily: "Outfit",
        fontSize: [12, 12, 14, 14, 14],
        color: colors.gray600,
      },
    },

    Button: {
      baseStyle: {
        fontFamily: "Outfit",
        fontSize: [12, 12, 14, 14, 14],
        color: colors.gray600,
      },
    },
    Box: {
      baseStyle: {
        fontFamily: "Outfit",
        fontSize: [12, 12, 14, 14, 14],
        color: colors.gray600,
      },
    },
  },

  colors: {},
});

function ChakraUiProvider({ children }: any) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}

export default ChakraUiProvider;
