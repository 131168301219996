import { AxiosError } from "axios";
import { useTokenStore } from "core/features/auth/hooks/useTokenStore";
import { api } from "infra/axios";
import { showError, } from "infra/packages/helpers/alerta";
import { useMutation, useQueryClient } from "react-query";
import { ValidationDetails } from "./useComprarIngressoCC";

function useDeletarCartao(id: number) {
  const url = `/v1/cartoes/${id}`;
  const token = useTokenStore((state) => state?.token?.access_token);

  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const { data } = await api.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["v1/cartoes", token]);
      },
      onError: (error: AxiosError<ValidationDetails>) => {
        error.response?.data.details.forEach((detail) => {
          detail.errors.forEach((error) => {
            showError(error);
          });
        });
      },
    }
  );
}

export default useDeletarCartao;
