import { Flex } from "@chakra-ui/react";

export default function HeaderTableRow() {
  return (
    <Flex bg={"#F3F7FC"} w="100%" px={6} py={6} roundedTop={20}>
      <Flex flex={0.2} fontSize="16px" fontWeight="medium">
        #
      </Flex>
      <Flex flex={0.8} fontSize="16px" fontWeight="medium">
        Evento
      </Flex>
      <Flex flex={0.6} fontSize="16px" fontWeight="medium">
        Data da Compra
      </Flex>

      <Flex flex={0.4} fontSize="16px" fontWeight="medium">
        Total
      </Flex>
      <Flex flex={1} fontSize="16px" fontWeight="medium">
        Status
      </Flex>
    </Flex>
  );
}
