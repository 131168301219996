import { CARRINHO_STEP } from "core/features/carrinho/enums";

export const steps = {
  [CARRINHO_STEP.INGRESSO]: {
    large: "ESCOLHA O TIPO DO INGRESSO",
    small: "INGRESSO",
  },
  [CARRINHO_STEP.DATA]: {
    large: "ESCOLHA DATA DA VISITA",
    small: "DATA",
  },
  [CARRINHO_STEP.IDENTIFICACAO]: {
    large: "IDENTIFICAÇÃO",
    small: "IDENTIFICAÇÃO",
  },
  [CARRINHO_STEP.PAGAMENTO]: {
    large: "PAGAMENTO",
    small: "PAGAMENTO",
  },
  [CARRINHO_STEP.TIPO_INGRESSO]: {
    large: "ESCOLHA O TIPO DO INGRESSO",
    small: "INGRESSO",
  },
  [CARRINHO_STEP.CONFIRMACAO]: {
    large: "CONFIRMAÇÃO",
    small: "CONFIRMAÇÃO",
  },
} as const;
