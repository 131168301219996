import { Flex } from "@chakra-ui/react";

interface LegendaCalendarProps {
  bg: string;
  titleLegenda: string;
}

export default function LegendaCalendar({
  bg,
  titleLegenda,
}: LegendaCalendarProps) {
  return (
    <Flex gap={2} align="center" fontSize="16px">
      <Flex w="25px" h="25px" bg={bg} rounded={4} />
      {titleLegenda}
    </Flex>
  );
}
