import { Flex, Text } from "@chakra-ui/react";
import { IngressoDto } from "core/features/ingresso/typings";
import { formatMoney } from "core/helpers/number";
import colors from "core/resources/theme/colors";
import { Items } from "../CompraIngresso";

interface CardIngressoProps {
  titleCard: string;
  valorIngresso: number;
  valorTotalIngresso?: number;

  id: number;
  fieldOnChange?: (count: number) => void;
  shoppingCart?: Items[];
  form?: IngressoDto | undefined;

  quantity: number;
  addQuantity: () => void;
  removeQuantity: () => void;
}

export default function CardIngresso({
  titleCard,
  valorIngresso,

  quantity,
  addQuantity,
  removeQuantity,
}: CardIngressoProps) {
  return (
    <Flex
      w="100%"
      h="80px"
      boxShadow="md"
      p={3}
      rounded={4}
      justify="space-between"
    >
      <Flex align="center" gap={4}>
        <Flex flexDir="column" justify="space-between" h="100%">
          <Text fontSize="16px" fontWeight="black">
            {titleCard}
          </Text>
          <Flex gap={2}>
            <Text fontSize="16px" fontWeight="medium" color={colors.green}>
              {formatMoney(valorIngresso)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDir="column" justify="end">
        <Flex gap={2} align="center">
          <Flex
            justify="center"
            rounded="full"
            w="20px"
            h="20px"
            bg={colors.blue}
            color="white"
            cursor="pointer"
            onClick={() => {
              removeQuantity();
            }}
            userSelect="none"
          >
            -
          </Flex>
          <Text fontWeight="medium" fontSize="18px" w="30px" align="center">
            {quantity}
          </Text>
          <Flex
            justify="center"
            rounded="full"
            w="20px"
            h="20px"
            bg={colors.blue}
            color="white"
            cursor="pointer"
            onClick={() => {
              addQuantity();
            }}
            userSelect="none"
          >
            +
          </Flex>
        </Flex>
        <Flex fontSize="12px" justify={"center"}>
          {formatMoney(valorIngresso * quantity)}
        </Flex>
      </Flex>
    </Flex>
  );
}
