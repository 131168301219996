import { Flex } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { LoginForm } from "ui/pages/Auth/Login";
import { RegisterForm } from "ui/pages/Auth/Register";
import { ResetForm } from "ui/pages/Auth/Reset/index";
import ResumoCompra from "./../Pagamento/components/ResumoCompra/index";

export enum Tela {
  Identificacao = 1,
  EsqueciSenha = 2,
  Cadastro = 3,
}

export default function Identificacao() {
  const [tela, setTela] = useState(Tela.Identificacao);
  const { eventoId, setorId } = useParams<{
    eventoId: string;
    setorId: string;
  }>();

  return (
    <Flex flexDir="column">
      <ResumoCompra />

      {tela === Tela.Identificacao ? (
        <LoginForm
          setIsReset={setTela}
          prevStep={console.log}
          setTela={() => setTela(Tela.Cadastro)}
          goToIfSucceed={`/ingresso/${eventoId}/setor/${setorId}/pagamento`}
        />
      ) : null}

      {tela === Tela.EsqueciSenha ? (
        <Flex
          py={[8, 8, 16, 16, 20]}
          px={[8, 16, 24, 32, 32]}
          rounded={4}
          flexDir="column"
          gap={10}
          bg={colors.gray10}
        >
          <ResetForm setTela={setTela} />
        </Flex>
      ) : null}
      {tela === Tela.Cadastro ? (
        <Flex
          py={[8, 8, 16, 16, 20]}
          px={[8, 16, 24, 32, 32]}
          flexDir={"column"}
        >
          <RegisterForm
            setTela={setTela}
            goToIfSucceed={`/ingresso/${eventoId}/setor/${setorId}/pagamento`}
          />
        </Flex>
      ) : null}
    </Flex>
  );
}
