import { api } from "infra/axios";
import { useQuery } from "react-query";

export function useGetFotos(qtde: number) {
    const url = `/v1/site-fotos/ultimas`;

    return useQuery(url, async () => {
        const { data } = await api.get(url, {
            params: {
                qtde
            }
        });

        return data;
    });
}
