import { api } from "infra/axios";
import { useQuery } from "react-query";
import { Evento } from "../types";

export function useEventosAberto() {
  const url = `/v1/ingressos/eventos-abertos`;

  return useQuery<Evento[]>(url, async () => {
    const { data } = await api.get(url);

    return data;
  });
}
