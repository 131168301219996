import { Box, Flex, Text } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiChevronLeft } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "ui/components/Button/Button";
import OutlineButton from "ui/components/Button/OutlineButton";
import Field from "ui/components/Input/Field";
import { Tela } from "ui/pages/Admin/Ingresso/components/Identificacao";
import ModalRecuperarSenha from "./components/ModalRecuperarSenha";
import { useMutation } from "react-query";
import { useRecuperarSenha } from "core/features/auth/hooks/useRecuperarSenha";
import { showError } from "infra/packages/helpers/alerta";

interface ResetProps {
  setTela?: (v: any) => void;
}

export function ResetForm({ setTela }: ResetProps) {
  const [openModal, setOpenModal] = useState(false);
  const { control, watch } = useForm<any>({});
  const location = useLocation();
  const { mutate } = useMutation(useRecuperarSenha, {
    onSuccess() {
      setOpenModal(true);
    },
    onError() {
      showError("Email não cadastrado");
    },
  });

  const onSubmit = () => {
    mutate(watch());
  };
  const navigate = useNavigate();
  return (
    <>
      <Text
        fontWeight="medium"
        fontSize="24px"
        color={colors.purple500}
        flexWrap="wrap"
      >
        ESQUECI MINHA SENHA
      </Text>
      <Text>
        Informe seu e-mail cadastrado para enviarmos as instruções de
        redefinição de senha.
      </Text>
      <Flex flexDir="column" gap={6} w="100%">
        <Box>
          <Field
            textBorder="Informe seu e-mail"
            bgTextBorder={colors.gray10}
            colorTextBorder={colors.blue}
            control={control}
            name="email"
            inputProps={{
              placeholder: "email@",
              borderColor: colors.blue,
            }}
            rules={{
              required: {
                message: "Informe seu e-mail",
                value: true,
              },
            }}
          />
        </Box>

        <Flex justify="end" flex="1">
          <Button
            bg={colors.red}
            title="CONTINUAR"
            onClick={() => onSubmit()}
          />
        </Flex>
      </Flex>

      <Flex justify="start" mt="100px">
        <OutlineButton
          title="VOLTAR"
          color={colors.blue}
          prevIcon={<BiChevronLeft size={20} />}
          onClick={() =>
            location.pathname === "/auth/recuperar-senha"
              ? navigate("/auth/login")
              : setTela!(Tela.Identificacao)
          }
        />
      </Flex>

      <ModalRecuperarSenha
        visible={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}

export default function Reset(props: ResetProps) {
  return (
    <Flex
      rounded={4}
      flexDir="column"
      gap={10}
      py={["150px", "150px", "150px", "350px"]}
      px={["50px", "50px", "30px", "200px"]}
      bg={colors.gray10}
    >
      <ResetForm {...props} />
    </Flex>
  );
}
