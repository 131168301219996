import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { MdCheck } from "react-icons/md";
import OutlineButton from "ui/components/Button/OutlineButton";

interface ModalRecuperarSenhaProps {
  visible: boolean;
  onClose: () => void;
}

export default function ModalRecuperarSenha({
  onClose,
  visible,
}: ModalRecuperarSenhaProps) {
  return (
    <Modal isCentered isOpen={visible} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex flexDir="column" align="center">
            <Flex>
              <MdCheck size={100} color={colors.green} />
            </Flex>
            <Text mb={5} fontWeight="bold" fontSize="40px">
              Email enviado!
            </Text>
            <Text align="center">
              Caso o email esteja cadastrado, você receberá uma mensagem com as
              instruções para definir a nova senha. Caso não tenha recebido a
              mensagem, verifique se digitou o endereço de e-mail corretamente
              ou se caiu na caixa de spam.
            </Text>
            <Divider mt={10} mb={5} />
            <Flex
              flex={1}
              justifyContent="space-between"
              w="100%"
              align="center"
              mb={5}
            >
              <OutlineButton title="FECHAR" onClick={onClose} />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
