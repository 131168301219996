import { Flex, Text } from "@chakra-ui/react";
import StepTag from "./StepTag";

interface StepCoponentProps {
  textStep: string;
  step: string;
}

export default function StepCoponent({ step, textStep }: StepCoponentProps) {
  return (
    <Flex align="center" gap={2}>
      <StepTag step={step} />
      <Text fontSize="18px">{textStep}</Text>
    </Flex>
  );
}
