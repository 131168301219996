import { Center, Divider, Flex, Spinner, Stack, Text } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import OutlineButton from "ui/components/Button/OutlineButton";
import { useGetCartoes } from "core/features/eventos/hooks/useGetCartoes";
import { useState } from "react";
import CadastrarCartao from "../../Ingresso/components/Pagamento/components/FormaPagamento/components/CadastrarCartao";
import CartaoCheckbox from "../../Ingresso/components/Pagamento/components/FormaPagamento/components/PagarComCartao/CartaoCheckbox";

export default function FormasPagamento() {
  const [cadastrarCard, setCadastrarCard] = useState(false);
  const { data: cartoes, isLoading } = useGetCartoes();

  if (isLoading)
    return (
      <Center flexDir="column" h="160px" gap={12}>
        <Text fontSize={"lg"} color={colors.blue100}>
          Carregando Cartões ...
        </Text>
        <Spinner size="xl" color={colors.blue} />
      </Center>
    );

  if (cartoes?.length === 0 || cadastrarCard)
    return (
      <CadastrarCartao
        cadastrarCard={cadastrarCard}
        setCadastrarCard={setCadastrarCard}
      />
    );

  return (
    <Stack spacing={2} w="full">
      <Stack mb={2}>
        <Flex align="center" gap={4}>
          <Text fontWeight="medium" fontSize="20px">
            Seus cartões
          </Text>

          <OutlineButton
            title="ADICIONAR CARTÃO"
            h="32px"
            onClick={() => {
              setCadastrarCard(true);
            }}
          />
        </Flex>
        <Divider />
      </Stack>
      {cartoes?.map((cartao) => (
        <CartaoCheckbox cartao={cartao} key={cartao?.id} canDelete />
      ))}
    </Stack>
  );
}
