import { Center, Flex, Spinner } from "@chakra-ui/react";
import useHasEnderecoCadastrado from "core/features/auth/hooks/useHasEnderecoCadastrado";
import colors from "core/resources/theme/colors";
import Tabs from "ui/components/Tabs/index";
import PagarComCartao from "./components/PagarComCartao";
import Pix from "./components/Pix/index";

export default function FormaPagamento() {
  const { isLoading } = useHasEnderecoCadastrado();

  if (isLoading) {
    return (
      <Flex flexDir="column" mt={8}>
        <Center>
          <Spinner color={colors.blue500} size="xl" />
        </Center>
      </Flex>
    );
  }

  return (
    <Flex flexDir="column">
      <Tabs.Tab
        containerProps={{
          padding: 5,
          width: "100%",
          background: "white",
          border: "none",
        }}
        borderTopRightRadiusDate={false}
      >
        <Tabs.Panel title={"CARTÃO DE CRÉDITO"}>
          <Flex>
            <PagarComCartao />
          </Flex>
        </Tabs.Panel>
        <Tabs.Panel title={"PIX"}>
          <Flex>
            <Pix />
          </Flex>
        </Tabs.Panel>
      </Tabs.Tab>
    </Flex>
  );
}
