import { Box, Flex, Link, useMediaQuery } from "@chakra-ui/react";
import { useEventoDestaque } from "core/features/eventos/hooks/useEventoDestaque";
import { useGetImagensSite } from "core/features/hooks/useGetImagensSite";
import { useInformativosList } from "core/features/informativos/hooks/useInformativosList";
import colors from "core/resources/theme/colors";
import { IoTicketSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Button from "ui/components/Button/Button";
import Ticket from "ui/components/Ticket/index";
import DescricaoHome from "./components/DescricaoHome/index";

export default function Home() {
  const { data: infos, isLoading: loadingInfos } = useInformativosList();
  const { data: eventoDestaque, isLoading: loadingEvento } =
    useEventoDestaque();
  const { data: imagens, isLoading: isLoadingGif } = useGetImagensSite();
  const ultimoInfomartivo = infos?.[0];
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();

  return (
    <Flex flexDir={"column"}>
      <Flex position={"relative"} w="100%" height="1020px">
        <Box
          as="video"
          autoPlay
          playsInline
          preload="auto"
          muted
          loop
          sx={{
            "@media screen and (max-width: 800px)": { display: "none" },
          }}
          style={{
            objectFit: "cover",
            position: "absolute",
            width: "100%",
            height: "1020px",
          }}
          src={
            "https://tactonuvem.blob.core.windows.net/imagens/900b34b0-9d5d-4e52-b6ed-80d9c42d8825/banners/videofundo3-pc.mp4"
          }
        ></Box>
        <Box
          as="video"
          autoPlay
          preload="auto"
          playsInline
          muted
          loop
          sx={{
            "@media screen and (min-width: 800px)": { display: "none" },
          }}
          style={{
            objectFit: "cover",
            position: "absolute",
            width: "100%",
            height: "1020px",
          }}
          src={
            "https://tactonuvem.blob.core.windows.net/imagens/900b34b0-9d5d-4e52-b6ed-80d9c42d8825/banners/fundocelular.mp4"
          }
        ></Box>
        <Flex
          zIndex={99}
          m="auto"
          mb={["0", "80px"]}
          gap={6}
          flexDir={isLargerThan800 ? "row" : "column"}
          pt={isLargerThan800 ? "100px" : "100px"}
          align="center"
        >
          <Box order={isLargerThan800 ? 0 : 1}>
            <Ticket
              loading={isLoadingGif}
              onClickImage={() => navigate("/parque-aquatico")}
              hTicket={["274px"]}
              wTicket={["198px"]}
              hImage={["190px"]}
              wImage={["180px"]}
              fontSizeTipo={["14px", "12px", "14px", "14px"]}
              bgImage={imagens?.bannerVideo02}
              navegacaoTicket={
                <Link
                  textDecoration="underline"
                  color={colors.purple500}
                  onClick={() => navigate("/parque-aquatico")}
                >
                  QUERO CONHECER
                </Link>
              }
              tipoTicket="O ÁCQUA PARK"
            />
          </Box>
          <Box order={isLargerThan800 ? 1 : 0}>
            <Ticket
              loading={loadingEvento}
              hTicket={["300px", "300px", "344px", "350px"]}
              wTicket={["198px", "198px", "245px", "250px"]}
              wImage={["180px", "180px", "220px", "220px"]}
              bgImage={eventoDestaque?.imagemUrl}
              colorTipo={colors.blue300}
              navegacaoTicket={
                <Button
                  onClick={() =>
                    navigate(`/ingresso`)
                  }
                  bg={colors.red}
                  title={"COMPRE AGORA"}
                  icon={<IoTicketSharp />}
                />
              }
              tipoTicket={eventoDestaque?.nome}
            />
          </Box>
          <Box order={2}>
            <Ticket
              loading={loadingInfos}
              onClickImage={() => {
                navigate(`/informativos`);
              }}
              hTicket={["290px"]}
              wTicket={["200px"]}
              wImage={["180px"]}
              bgImage={ultimoInfomartivo?.imagemUrlCompleta}
              navegacaoTicket={
                <Link
                  textDecoration="underline"
                  onClick={() => navigate("/informativos")}
                  color={colors.purple500}
                >
                  SAIBA MAIS
                </Link>
              }
              tipoTicket={"INFORMATIVOS"}
            />
          </Box>
        </Flex>
      </Flex>
      <Flex>
        <DescricaoHome isLargerThan800={isLargerThan800} />
      </Flex>
    </Flex>
  );
}
