import { useEffect } from "react";
import Swal from "sweetalert2";

export function showSuccess(
  message = "Operação realizada com sucesso",
  title = "Sucesso"
) {
  return Swal.fire(title, message, "success");
}

export function showError(
  message = "Houve um problema ao realizar esta operação",
  title = "Erro"
) {
  return Swal.fire(title, message, "error");
}

export function showErrorAxios(error: any, fallbackMessage?: string) {
  let message: any = undefined;

  if (!error) {
    return message ?? fallbackMessage;
  }

  if (
    Number(error.response?.status) >= 400 &&
    Number(error.response?.status) <= 499
  ) {
    if (typeof error.response?.data?.message === "string") {
      message = error.response.data.message;
    } else if (
      Array.isArray(error.response?.data?.message) &&
      error.response.data.message.length
    ) {
      message = error.response.data.message[0];
    }
  }

  setTimeout(() => {
    showError(message ?? fallbackMessage);
  }, 150);

  return;
}

export function showConfirm(
  message = "Deseja realmente realizar esta operação?",
  config: any
) {
  const instance = Swal.fire({
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    title: "Aviso",
    icon: "info",
    text: message,
    ...config,
  });

  return instance;
}

export function showConfirm2(config: {
  message?: string;
  onOk?: () => void;
  onCancel?: () => void;
}) {
  config.message = config.message || "Deseja realmente realizar esta operação?";

  const instance = Swal.fire({
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    title: "Aviso",
    icon: "info",
    text: config.message ?? "Deseja realmente realizar esta operação?",
    ...config,
  });

  instance.then(({ isConfirmed }) => {
    if (isConfirmed && config.onOk) {
      setTimeout(() => {
        config.onOk!();
      }, 250);
    } else if (!isConfirmed && config.onCancel) {
      config.onCancel();
    }
  });

  return instance;
}

export function showLoading() {
  Swal.showLoading();
}

export function hideLoading() {
  Swal.close();
}

export function useLoading(bool: boolean) {
  useEffect(() => {
    if (bool) {
      showLoading();
    } else {
      hideLoading();
    }

    return () => hideLoading();
  }, [bool]);
}
