import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Token } from "../types/auth";

export interface TokenStore {
  token?: Token;
  setToken: (token?: Token) => void;
}

export const useTokenStore = create(
  persist<TokenStore>(
    (set) => ({
      token: undefined,
      setToken: (token) => set({ token }),
    }),
    {
      name: "acqua-park@token",
      getStorage: () => localStorage,
    }
  )
);
