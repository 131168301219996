import { FaHeart, FaMapMarkerAlt } from "react-icons/fa";
import { HiPhone } from "react-icons/hi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { BiCalendar } from "react-icons/bi";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface InformationsFooterProps {
  isLargerThan800: boolean;
}

export default function InformationsFooter({
  isLargerThan800,
}: InformationsFooterProps) {
  const navigate = useNavigate();
  return (
    <Flex
      w="full"
      justify="space-between"
      gap={10}
      flexDir={isLargerThan800 ? "row" : "column"}
    >
      <Flex flexDir="column" color="white">
        <Flex gap={2} align="center" mb={4}>
          <Text fontWeight="medium" fontSize="18px">
            Sobre nós
          </Text>
          <FaHeart color="white" />
        </Flex>
        <Flex
          flexDir="column"
          gap={2}
          cursor={"pointer"}
          onClick={() => navigate("/parque-aquatico")}
        >
          <Text fontSize="18px">Parque aquático</Text>
        </Flex>
      </Flex>

      <Flex flexDir="column" color="white">
        <Flex gap={2} align="center" mb={4}>
          <Text fontWeight="medium" fontSize="18px">
            Ajuda
          </Text>
          <RiQuestionnaireFill color="white" />
        </Flex>
        <Flex cursor="pointer" onClick={() => navigate("/duvidas")}>
          <Text fontSize="18px">Dúvidas frequentes</Text>
        </Flex>
      </Flex>

      <Flex flexDir="column" color="white">
        <Flex gap={2} align="center" mb={4}>
          <Text fontWeight="medium" fontSize="18px">
            Venha se divertir
          </Text>
          <BiCalendar color="white" />
        </Flex>
        <Flex flexDir="column" gap={2} fontSize="14px" maxW="300px">
          <Text>
            Sábado, domingo : 9h às 17h *Consultar calendário de abertura.
          </Text>
        </Flex>
      </Flex>
      <Flex flexDir="column" color="white">
        <Flex gap={2} align="center" mb={4}>
          <Text fontWeight="medium" fontSize="18px">
            Como chegar
          </Text>
          <FaMapMarkerAlt color="white" />
        </Flex>
        <Flex flexDir="column" gap={4} fontSize="14px" maxW="300px">
          <Text>
            Rodovia Vicente Bezerra Neto, km 05 (Estrada do Manso)Cuiabá, Mato
            Grosso - Brasil.78005-000
          </Text>
          <Button
            w="150px"
            bg="none"
            border="solid 1px white"
            _hover={{ background: "none" }}
            onClick={() => navigate("/como-chegar")}
          >
            Ver no mapa
          </Button>
        </Flex>
      </Flex>
      <Flex flexDir="column" color="white">
        <Flex gap={2} align="center" mb={4}>
          <Text fontWeight="medium" fontSize="18px">
            Atendimento
          </Text>
          <HiPhone color="white" />
        </Flex>
        <Flex flexDir="column" gap={2} fontSize="14px">
          <a
            href={`https://wa.me/5565999999102`}
            target="_blank"
            rel="noreferrer"
          >
            <Text>(65) 9 9999-9102</Text>
          </a>
        </Flex>
      </Flex>
    </Flex>
  );
}
