import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import { Flex, Text, Box } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { Control } from "react-hook-form";
import Field from "ui/components/Input/Field";
import InputMask from "ui/components/Input/InputMask/index";
import SelectCodigoPais from "ui/components/Input/SelectCodigoPais";
import SelectSexo from "ui/components/Input/SelectSexo";

interface DadosPessoaisProps {
  control: Control<any, any>;
}

export default function DadosPessoais({ control }: DadosPessoaisProps) {
  return (
    <Flex flexDir="column" gap={4} mt={8}>
      <Text
        fontWeight="medium"
        fontSize="24px"
        color={colors.purple500}
        flexWrap="wrap"
      >
        Dados pessoais
      </Text>
      <Flex gap={6} flexDir="column">
        <Flex gap={6} flexDir={["column", "column", "column", "row", "row"]}>
          <Box w="100%">
            <Field
              control={control}
              name="nome"
              inputProps={{ placeholder: "Nome*" }}
              rules={{
                required: {
                  message: "Informe seu nome",
                  value: true,
                },

              }}
            />
          </Box>
          <Box w="100%">
            <Field
              control={control}
              name="sobrenome"
              inputProps={{ placeholder: "Sobrenome*" }}
              rules={{
                required: {
                  message: "Informe seu Sobrenome",
                  value: true,
                },

              }}
            />
          </Box>
          <Box w="100%">
            <Field
              control={control}
              name="cpf"
              inputProps={{ placeholder: "CPF*" }}
              rules={{
                required: "Campo obrigatório",
                validate: (v) => isValidCPF(v) || "CPF inválido",
              }}
              render={({ field, isInvalid }) => {
                return (
                  <InputMask
                    {...field}
                    isInvalid={isInvalid}
                    mask="999.999.999-99"
                    placeholder="CPF*"
                  />
                );
              }}
            />
          </Box>
        </Flex>
        <Flex gap={6} flexDir={["column", "column", "column", "row", "row"]}>
          {" "}
          <Box w="100%">
            <Field
              control={control}
              name="dataNascimento"
              rules={{
                required: {
                  message: "Informe a sua data de nascimento",

                  value: true,
                },
              }}
              render={({ field, isInvalid }) => {
                return (
                  <InputMask
                    mask="99/99/9999"
                    placeholder="Data de nascimento*"
                    value={field.value}
                    isInvalid={isInvalid}
                    onChange={field.onChange}
                  />
                );
              }}
            />
          </Box>
        
          <Box w="100%">
            <Field
              textBorder="Sexo"
              control={control}
              name="sexo"
              inputProps={{ placeholder: "sexo*" }}
              rules={{
                required: {
                  message: "Informe seu sexo",
                  value: true,
                },
              }}
              render={({ field }) => <SelectSexo {...field} />}
            />
          </Box>
          <Box w="100%" >
            <Field
              control={control}
              name="codigoPais"
              inputProps={{ placeholder: "Código do país *" }}
              rules={{
                required: {
                  message: "Informe o codigo do país",
                  value: true,
                },
              }}
              render={({ field }) => <SelectCodigoPais {...field} />}
            />
          </Box>
          <Box w="100%">
            <Field
              control={control}
              name="celular"
              rules={{
                validate: (value) => value.replace(/\D/g, "").length === 11 || "Informe um celular válido com 11 dígitos",
                required: "Informe seu celular",
              }}
              render={({ field, isInvalid }) => {
                return (
                  <InputMask
                    mask="(99) 9 9999 9999"
                    placeholder="Celular*"
                    value={field.value}
                    minLength={11}
                    isInvalid={isInvalid}
                    onChange={field.onChange}
                  />
                );
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
