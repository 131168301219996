import { Flex, Box } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import colors from "core/resources/theme/colors";
import Field from "ui/components/Input/Field";
import DefaultButton from "ui/components/Button/DefaultButton";
import { FiCheck } from "react-icons/fi";
import OutlineButton from "ui/components/Button/OutlineButton";
import { BiChevronLeft } from "react-icons/bi";
import useGetUserInfo from "core/features/auth/hooks/useGetUserInfo";
import InputMask from "react-input-mask";
import useAlterarTelefone from "core/features/perfil/hooks/useAlterarTelefone";
import { useEffect } from "react";
import SelectCodigoPais from "ui/components/Input/SelectCodigoPais";

export default function AlterarTelefone() {
  const { isSuccess: isLoadedUser, data: user } = useGetUserInfo();

  const { control, handleSubmit, setValue,  } = useForm<any>({
    defaultValues: {
      telefone: user?.phone || '',
      codigoPais: '+55', // Valor padrão para o Brasil
    },
  });

  const { mutate, isLoading } = useAlterarTelefone();

  const onSubmit = async (form: any) => {
    form.codigoPais = form.codigoPais.replace(/\D/g, ''); // Remove non-digits
    form.telefone = form.telefone.replace(/\D/g, ''); // Remove non-digits
    mutate({
      novoTelefone: `${form.codigoPais}${form.telefone}`, // Concatena o código do país com o telefone
    });
  };
  const last11Digits = user?.phone?.replace(/\D/g, '').slice(-11);

  const firstDigitsWithOutLast11Digits = user?.phone?.slice(0, -11);

  useEffect(() => {
    setValue("codigoPais", '+'+ firstDigitsWithOutLast11Digits || '+55');
    setValue("telefone", last11Digits || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!isLoadedUser) return null;

  return (
    <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
      <Flex flexDir="column" gap={4}>
        <Flex gap={6} flexDir={["column", "column", "row"]}>
          <Box w="100%">
            <Flex align="center" gap={2} flexDir={["column", "column", "row"]}>
              <Box w={["100%", "100%", "100%", "50%"]}>

                <Field
                  control={control}
                  name="codigoPais"
                  inputProps={{ placeholder: "Código do país *" }}
                  rules={{
                    required: {
                      message: "Informe o codigo do país",
                      value: true,
                    },
                  }}
                  render={({ field }) => <SelectCodigoPais {...field} />}
                />
              </Box>
              <Box w={["100%", "100%", "100%", "50%"]}>
                <Field
                  textBorder="Telefone"
                  colorTextBorder={colors.blue}
                  control={control}
                  name="telefone"
                  inputProps={{
                    as: InputMask,
                    mask: "(99) 9 9999-9999",
                    placeholder: "(65) 99999-9999",
                    borderColor: colors.blue,
                  }}
                  rules={{
                    required: {
                      message: "Informe o telefone",
                      value: true,
                    },
                    validate: (value) => value.replace(/\D/g, "").length === 11 || "Informe um celular válido com 11 dígitos",
                  }}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Flex flex={1} justify="end" gap={6} align="center" mt="100px">
        <OutlineButton
          title="VOLTAR"
          color={colors.blue}
          prevIcon={<BiChevronLeft size={20} />}
        />
        <DefaultButton
          maxH={10}
          isLoading={isLoading}
          type="submit"
          bg={colors.red}
          title={"SALVAR"}
          icon={<FiCheck size={20} />}
        />
      </Flex>
    </form>
  );
}
