import { useTokenStore } from "core/features/auth/hooks/useTokenStore";
import { api } from "infra/axios";
import { showSuccess } from "infra/packages/helpers/alerta";
import { useMutation, useQueryClient } from "react-query";

const url = `v1/usuario-logado/telefone/alterar`;

export function useAlterarTelefone() {
  const token = useTokenStore((state) => state.token?.access_token);
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) => {
      const res = await api.put(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res;
    },
    {
      onSuccess: () => {
        showSuccess();
        queryClient.invalidateQueries(["user", token]);
      },
    }
  );
}
export default useAlterarTelefone;
