import { Flex, Grid, Spinner } from "@chakra-ui/react";
import { useEventosAberto } from "core/features/eventos/hooks/useEventosAberto";
import colors from "core/resources/theme/colors";
import { IoTicketSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Button from "ui/components/Button/Button";
import Ticket from "ui/components/Ticket/index";

export default function SelecaoIngresso() {
  const navigate = useNavigate();

  const { data: eventos, isLoading } = useEventosAberto();

  if (isLoading) {
    return <Spinner size="xl" color={colors.blue} />;
  }

  return (
    <Flex flexDir="column">
      <Grid
        templateColumns={{ sm: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
        gap={6}
        m="auto"
      >
        {eventos?.map((evento) => (
          <Ticket
            key={evento?.id}
            hTicket={["300px", "300px", "344px", "350px"]}
            wTicket={["198px", "198px", "245px", "250px"]}
            wImage={["180px", "180px", "220px", "220px"]}
            bgImage={evento?.imagemUrl}
            colorTipo={colors.blue300}
            navegacaoTicket={
              <Button
                onClick={() => navigate(`/ingresso/${evento?.id}/setor`)}
                bg={colors.red}
                title={"COMPRE AGORA"}
                icon={<IoTicketSharp />}
              />
            }
            tipoTicket={evento?.nome}
          />
        ))}
      </Grid>
    </Flex>
  );
}
