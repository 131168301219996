export const API_URL = `https://site-api2.tactonuvem.com.br//`;
export const GRUPO_EMPRESARIAL = "900B34B0-9D5D-4E52-B6ED-80D9C42D8825";
export const EMPRESA = "1";
export const GRUPO_EMPRESA = "1";
export const ACCOUNTS_URL = process.env.REACT_APP_ACCOUNTS_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const GRANT_TYPE = process.env.REACT_APP_GRANT_TYPE;
export const AUDIENCE = process.env.REACT_APP_AUDIENCE;
// base teste - "E5C86886-BD27-413C-ADC9-4E40F337C402"
