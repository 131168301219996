import { Button, Flex } from "@chakra-ui/react";

interface DefaultButtonProps {
  bg: string;
  title: string;
  onClick?: () => void;
  icon?: any;
  maxH?: number;
  prevIcon?: any;
  type?: "button" | "submit" | "reset" | undefined;
  px?: number[];
  color?: string;
  isLoading?: boolean;
}

export default function DefaultButton({
  bg,
  title,
  icon,
  onClick,
  maxH = 10,
  prevIcon,
  type,
  px = [2, 4],
  color = "white",
  isLoading,
  ...ress
}: DefaultButtonProps) {
  return (
    <Button
      mx={2}
      type={type}
      bg="none"
      rounded={8}
      h="max-content"
      p="0"
      onClick={onClick}
      disabled={isLoading}
      isLoading={isLoading}
    >
      <Flex
        fontSize="14px"
        fontWeight="normal"
        rounded={8}
        bg={bg}
        h={[6, 8, 8, maxH]}
        py={4}
        px={px}
        align="center"
        gap={4}
        color={color}
        cursor="pointer"
      >
        {prevIcon} {title} {icon}
      </Flex>
    </Button>
  );
}
