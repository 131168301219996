import { Box, Collapse, Flex, Text } from "@chakra-ui/react";
import { formatMoney } from "core/helpers/number";
import colors from "core/resources/theme/colors";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Field from "ui/components/Input/Field";
import { useIngressosStore } from "ui/pages/Admin/Ingresso/hooks/useIngressosStore";
import ContentResumo from "./components/ContentResumo";
import InformacaoPagamentoBar from "./components/InformacaoPagamentoBar";

interface ResumoCompraProps {
  showResumo?: boolean;
  showCupom?: boolean;
}

export default function ResumoCompra({
  showResumo = false,
  showCupom = false,
}: ResumoCompraProps) {
  const [show, setShow] = useState(showResumo);

  const { control } = useForm<any>({});

  const handleToggle = () => setShow(!show);

  const ingressos = useIngressosStore((state) =>
    state.ingressos?.filter((ingresso) => ingresso.quantity > 0)
  );

  const subtotal = Object.entries(ingressos || []).reduce((acc, curr) => {
    const [, value] = curr;
    return acc + value.quantity * value.item.valorBase;
  }, 0);

  const totalTaxa = Object.entries(ingressos || []).reduce((acc, curr) => {
    const [, value] = curr;
    return acc + value.quantity * value.item.valorServico;
  }, 0);

  const dataSelecionada = useIngressosStore((state) => state.dataSelecionada);
  const navigate = useNavigate();
  useEffect(() => {
    if (!dataSelecionada || subtotal === 0) {
      navigate("/ingresso");
    }
  }, [dataSelecionada, navigate, subtotal]);

  return (
    <Flex
      w="100%"
      justify="space-between"
      bg={colors.white}
      borderBottom={`solid 4px ${colors.gray200}`}
      h="max-content"
      align="center"
      rounded={10}
      py={2}
      pb={4}
      px={4}
      flexDir="column"
    >
      <InformacaoPagamentoBar />

      <Collapse style={{ width: "100%" }} in={show}>
        <Flex flexDir="column">
          {ingressos?.length ? (
            <ContentResumo compraInfo={ingressos} title="INGRESSO ESCOLHIDO" />
          ) : null}

          <Flex flexDir="column" w="100%" gap={1}>
            <Flex bg={colors.gray200} w="100%" h="1px" my={2} />
            <Text fontSize="14px">SUBTOTAL</Text>
            <Flex justify="space-between" w="100%">
              <Text fontSize="18px">Subtotal</Text>
              <Text fontSize="18px" fontWeight="medium">
                {formatMoney(subtotal)}
              </Text>
            </Flex>
            <Flex justify="space-between" w="100%">
              <Text fontSize="18px">Taxa de serviço</Text>
              <Text fontSize="18px" fontWeight="medium">
                {formatMoney(totalTaxa!)}
              </Text>
            </Flex>
          </Flex>

          {showCupom ? (
            <Flex flexDir="column" w="100%">
              <Flex bg={colors.gray200} w="100%" h="1px" my={2} />
              <Flex align="center" gap={4}>
                <Text fontSize="18px">Adicionar cumpom</Text>
                <Box>
                  <Field
                    control={control}
                    name="ingressoItem.cumpom"
                    inputProps={{
                      placeholder: "Código do cumpom",
                      background: "white",
                    }}
                  />
                </Box>
              </Flex>
            </Flex>
          ) : null}
        </Flex>
      </Collapse>

      {!showResumo ? (
        <Flex
          onClick={handleToggle}
          w="20px"
          h="20px"
          bg={colors.lightBlue}
          rounded="full"
          align="center"
          justify="center"
          color={colors.blue}
          cursor="pointer"
        >
          {show ? <BiChevronUp /> : <BiChevronDown />}
        </Flex>
      ) : null}
    </Flex>
  );
}
