import { Flex, Text } from "@chakra-ui/react";
import mascote from "assets/images/svgs/mascote-amarelo.svg";
import colors from "core/resources/theme/colors";
import FormAniversariante from "./components/FormAniversariante";
import OutlineButton from "ui/components/Button/OutlineButton";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export default function Aniversariante() {
  const navigate = useNavigate();
  return (
    <Flex
      py={["150px", "150px", "150px", "350px"]}
      px={["50px", "50px", "30px", "200px"]}
      gap="80px"
      flexDir={"column"}
    >
      <Text
        fontWeight="medium"
        fontSize="24px"
        color={colors.purple500}
        flexWrap="wrap"
        textTransform={"uppercase"}
      >
        Solicitações de aniversário
      </Text>
      <Flex gap={10} flexDir={["column"]} align="center">
        <FormAniversariante />
        <Flex
          bgImage={mascote}
          bgRepeat="no-repeat"
          bgSize="cover"
          w={["100px", "120px", "135px", "233px"]}
          height={["150px", "150px", "210px", "365px"]}
        />
      </Flex>
      <OutlineButton
        w={`200px`}
        onClick={() => navigate("/")}
        title="VOLTAR"
        color={colors.blue}
        prevIcon={<BiChevronLeft size={20} />}
      />
    </Flex>
  );
}
