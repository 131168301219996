import { useQuery } from "react-query";
import { getUserInfo } from "./useApi";
import { useTokenStore } from "./useTokenStore";

function useGetUserInfo() {
  const token = useTokenStore((state) => state?.token?.access_token);

  return useQuery(
    ["user", token],
    async () => {
      return getUserInfo(token);
    },
    {
      enabled: !!token,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
}

export default useGetUserInfo;
