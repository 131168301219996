import { AspectRatio, Flex, Image, Text } from "@chakra-ui/react";
import backgroundAtracaoCard from "assets/images/svgs/backgroundAtracaoCard.svg";
import colors from "core/resources/theme/colors";

interface AtracaoCardProps {
  bgImage: any;
  titleCard: string;
  descriptionCard: string | any;
}

export default function AtracaoCard({
  bgImage,
  descriptionCard,
  titleCard,
}: AtracaoCardProps) {
  return (
    <Flex
      bgImage={backgroundAtracaoCard}
      w={["225px", "250px", "280px", "345px"]}
      h={["640px"]}
      bgRepeat="no-repeat"
      bgSize={"cover"}
      flexDir="column"
      gap={4}
      p={2}
      mr="auto"
      ml="auto"
    >
      <AspectRatio >
        <Image src={bgImage} rounded={10} />
      </AspectRatio>

      <Flex flexDir="column" px={4} gap={4}>
        <Text
          fontWeight="medium"
          fontSize={["14px", "18px"]}
          color={colors.purple500}
        >
          {titleCard}
        </Text>
        <Text align={'justify'} fontSize={["12px", "14px"]}>{descriptionCard}</Text>
      </Flex>
    </Flex>
  );
}
