import { Button, Flex } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { MdChevronRight } from "react-icons/md";

interface Option {
  label: string;
  icon?: any;
  route: any;
  count?: number;
  permissions: string[];
}

interface MenuDetalhesButtonProps {
  onChangeOption: (option: string) => any;
  option: Option;
  isSelected?: boolean;
  isDisabled?: boolean;
  light?: boolean;
}

function MenuDetalhesButton({
  option,
  isSelected,
  isDisabled,
  light,
  onChangeOption,
}: MenuDetalhesButtonProps) {
  return (
    <Button
    borderTopLeftRadius={0}
    borderTopRightRadius={0}
      key={option.route}
      w={light ? undefined : "100%"}
      py={light ? "12px" : "15px"}
      h="auto"
      gap={2}
      rounded={light ? 8 : 0}
      px={"16px"}
      boxShadow="md"
      fontSize="16px"
      fontWeight="400"
      wordBreak="break-all"
      onClick={() => onChangeOption(option.route)}
      disabled={isDisabled}
      _disabled={{ backgroundColor: "white" }}
      cursor={isDisabled ? "not-allowed" : undefined}
      mx={light ? 4 : 0}
      bg={isSelected ? (light ? colors.blue100 : colors.blue) : colors.gray10}
      color={
        isSelected
          ? light
            ? colors.blue
            : "white"
          : isDisabled
          ? "black"
          : undefined
      }
      textAlign="left"
      justifyContent="flex-start"
      _hover={
        isDisabled
          ? { backgroundColor: "white" }
          : isSelected
          ? { backgroundColor: colors.blue }
          : undefined
      }
    >
      <Flex justify="space-between" align="center" w="100%">
        <Flex>
          {option.icon}
          {option.label}
        </Flex>

        <MdChevronRight size={20} />
      </Flex>
    </Button>
  );
}

export default MenuDetalhesButton;
