import { Outlet } from "react-router-dom";
import Layout from "ui/components/Layout/index";

export default function Admin() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}
