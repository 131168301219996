export enum EnumFormaPagamento {
  CartaoCredito = "99",
  CartaoDebito = "100",
  Pix = "101",
}

export enum EnumStatusPagamento {
  AguardandoPagamento = "Aguardando Pagamento",
  Finalizado = "Finalizado",
  CompraCancelada = "Compra Cancelada",
  Disponivel = "Disponivel",
}
