import { Flex, Image } from "@chakra-ui/react";
import header from "assets/images/svgs/backgroundHeader.svg";
import HeaderNavegation from "./components/HeaderNavegation";
import useWindowDimensions from "core/helpers/windowDimensions";
import { useNavigate } from "react-router-dom";
import Container from "../Container";
import { useState } from "react";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const headerHeight = Math.min(405, Math.max(130, width / 5));
  return (
    <Flex
      bgImage={header}
      bgRepeat="no-repeat"
      bgSize="100%"
      position="absolute"
      w="100vw"
      h={headerHeight}
      zIndex={99999}

    >
      <Container py="32px">
        <Flex flex={1} flexDir="row">
          {!isOpen && (
            <Image

              src={'https://tactonuvem.blob.core.windows.net/imagens/900b34b0-9d5d-4e52-b6ed-80d9c42d8825/banners/logoacquapark-01.png'}
              maxWidth={["90px", "100px", "100px", "200px", "240px"]}
              cursor="pointer"
              onClick={() => navigate("/")}
            />
          )}

          <HeaderNavegation isOpen={isOpen} setIsOpen={setIsOpen} />
        </Flex>
      </Container>
    </Flex>
  );
}
