import { Flex } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";

interface OutlineButtonProps {
  title: string;
  onClick?: () => void;
  prevIcon?: any;
  nextIcon?: any;
  color?: string;
  bg?: string;
  bgBorder?: string;
  w?: string;
  h?: string;
}

export default function OutlineButton({
  onClick,
  title,
  prevIcon,
  nextIcon,
  w,
  color = colors.gray500,
  bg = colors.gray100,
  bgBorder = colors.gray300,
  h,
}: OutlineButtonProps) {
  return (
    <Flex
      w={w}
      h={h}
      fontWeight="medium"
      color={color}
      bg={bg}
      px={6}
      py={2}
      rounded={10}
      borderBottom={`solid 3px ${bgBorder}`}
      onClick={onClick}
      cursor="pointer"
      align="center"
      gap={2}
    >
      {prevIcon} {title} {nextIcon}
    </Flex>
  );
}
