import { showError, } from "infra/packages/helpers/alerta";
import { useMutation } from "react-query";
import { signin } from "./useApi";
import { useTokenStore } from "./useTokenStore";

function useSignin(onSuccess?: () => void) {
  const { mutate, ...rest } = useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      const res = await signin(email, password);

      return res;
    },
    {
      onSuccess: (data) => {
        useTokenStore.getState().setToken(data);
        onSuccess?.();
      },
      onError: (error: any) => {
        if (error.response?.data.error_description === 'invalid_username_or_password') {
          showError('Usuário ou senha inválidos')

        } else {
          showError(JSON.stringify(error.response?.data))
        }

        // error.response?.data.details.forEach((detail: any) => {
        //   detail.errors.forEach((error: any) => {
        //     showError(error);
        //   });
        // });
      },
    }
  );

  return {
    ...rest,
    signin: mutate,
  };
}

export default useSignin;
