import { useTokenStore } from "core/features/auth/hooks/useTokenStore";
import { accountApi } from "infra/axios";
import { showSuccess } from "infra/packages/helpers/alerta";
import { useMutation, useQueryClient } from "react-query";

const url = `/api/usuario-logado`;

export function useAlterarDados() {
  const token = useTokenStore((state) => state.token?.access_token);
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) => {
      const res = await accountApi.put(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res;
    },
    {
      onSuccess: () => {
        showSuccess();
        queryClient.invalidateQueries(["user", token]);
      },
    }
  );
}
export default useAlterarDados;
