import useGetUsuarioLogadoEnderecos from "./useGetUsuarioLogadoEnderecos";

function useHasEnderecoCadastrado() {
  const { data, isLoading } = useGetUsuarioLogadoEnderecos();

  const hasAddress = Array.isArray(data)
    ? data.length > 0
    : data === null || data === undefined || data === ""
    ? false
    : Object.keys(data).length > 0;

  return {
    hasAddress,
    isLoading,
  };
}

export default useHasEnderecoCadastrado;
