import axios from "axios";
import {
  ACCOUNTS_URL,
  API_URL,
  EMPRESA,
  GRUPO_EMPRESA,
  GRUPO_EMPRESARIAL,
} from "infra/config";

export const api = axios.create({
  baseURL: API_URL,
});

export const accountApi = axios.create({
  baseURL: ACCOUNTS_URL,
});

const defaultInterceptor = (config: any) => {
  config.headers = config.headers || {};

  config.headers.empresaId = EMPRESA;
  config.headers.grupoEmpresaId = GRUPO_EMPRESA;
  config.headers["Tacto-Grupo-Empresarial"] = GRUPO_EMPRESARIAL;

  return config;
};

api.interceptors.request.use(defaultInterceptor);
accountApi.interceptors.request.use(defaultInterceptor);
