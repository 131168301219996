import { useQuery } from "react-query";
import axios from "axios";
import { API_URL } from "infra/config";

function useGetBairros(cidadeId?: string | null) {
  return useQuery(
    `${API_URL}v1/listagem/bairros-bycidade/${cidadeId}`,
    async () => {
      const { data } = await axios
        .get<{ nome: string; }[]>(`${API_URL}v1/listagem/bairros-bycidade/${cidadeId}`)

      return data;
    },

    {
      enabled: !!cidadeId,
    }
  );
}

export default useGetBairros;
