import { Box, Flex, ListItem, UnorderedList } from "@chakra-ui/react";
import AtracoesCarousel from "ui/components/AtracoesCarousel";
import Container from "ui/components/Layout/Container";
import OffsetTop from "ui/components/Layout/OffsetTop";
import Title from "ui/components/Layout/Title";

export default function Sobre() {
  return (
    <Container>
      <OffsetTop />
      <Title mb="28px">PARQUE AQUÁTICO</Title>

      <Flex mb="32px" fontSize="1.2em">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </Flex>

      <Title mb="28px">ESTRUTURA DO PARQUE</Title>

      <Box fontSize="1.2em">
        O Ácqua Park dispõe a seus visitantes mesas, guarda-vidas treinados para
        garantir sua segurança, monitores preparados para te orientar, além de:
      </Box>

      <Box mt="16px" fontSize="1.2em" mb="2rem">
        <UnorderedList>
          <ListItem>Restaurante</ListItem>
          <ListItem>Quiosques</ListItem>
          <ListItem>Vestiários e Sanitários</ListItem>
          <ListItem>Fraldário</ListItem>
          <ListItem>Estacionamento</ListItem>
        </UnorderedList>
      </Box>

      <AtracoesCarousel />
    </Container>
  );
}
