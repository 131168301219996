import { api } from "infra/axios";
import { useQuery } from "react-query";
import { Informativo } from "../types";

export function useInformativoDetails(id?: number) {
  const url = `/v1/site-informativo/${id}/completo`;

  return useQuery(
    url,
    async () => {
      const { data } = await api.get<Informativo>(url);

      return data;
    },
    { enabled: !!id }
  );
}
