export const countryCodes = [
    { "pais": "Afeganistão", "codigo": "+93" },
    { "pais": "Albânia", "codigo": "+355" },
    { "pais": "Argélia", "codigo": "+213" },
    { "pais": "Andorra", "codigo": "+376" },
    { "pais": "Angola", "codigo": "+244" },
    { "pais": "Antígua e Barbuda", "codigo": "+1-268" },
    { "pais": "Argentina", "codigo": "+54" },
    { "pais": "Armênia", "codigo": "+374" },
    { "pais": "Austrália", "codigo": "+61" },
    { "pais": "Áustria", "codigo": "+43" },
    { "pais": "Azerbaijão", "codigo": "+994" },
    { "pais": "Bahamas", "codigo": "+1-242" },
    { "pais": "Bahrein", "codigo": "+973" },
    { "pais": "Bangladesh", "codigo": "+880" },
    { "pais": "Barbados", "codigo": "+1-246" },
    { "pais": "Bélgica", "codigo": "+32" },
    { "pais": "Belize", "codigo": "+501" },
    { "pais": "Benin", "codigo": "+229" },
    { "pais": "Butão", "codigo": "+975" },
    { "pais": "Bolívia", "codigo": "+591" },
    { "pais": "Bósnia e Herzegovina", "codigo": "+387" },
    { "pais": "Botswana", "codigo": "+267" },
    { "pais": "Brasil", "codigo": "+55" },
    { "pais": "Brunei", "codigo": "+673" },
    { "pais": "Bulgária", "codigo": "+359" },
    { "pais": "Burquina Faso", "codigo": "+226" },
    { "pais": "Burundi", "codigo": "+257" },
    { "pais": "Cabo Verde", "codigo": "+238" },
    { "pais": "Camboja", "codigo": "+855" },
    { "pais": "Camarões", "codigo": "+237" },
    { "pais": "Canadá", "codigo": "+1" },
    { "pais": "República Centro-Africana", "codigo": "+236" },
    { "pais": "Chade", "codigo": "+235" },
    { "pais": "Chile", "codigo": "+56" },
    { "pais": "China", "codigo": "+86" },
    { "pais": "Colômbia", "codigo": "+57" },
    { "pais": "Comores", "codigo": "+269" },
    { "pais": "Congo (Congo-Brazzaville)", "codigo": "+242" },
    { "pais": "Congo (Congo-Kinshasa)", "codigo": "+243" },
    { "pais": "Costa Rica", "codigo": "+506" },
    { "pais": "Croácia", "codigo": "+385" },
    { "pais": "Cuba", "codigo": "+53" },
    { "pais": "Chipre", "codigo": "+357" },
    { "pais": "República Tcheca", "codigo": "+420" },
    { "pais": "Dinamarca", "codigo": "+45" },
    { "pais": "Djibouti", "codigo": "+253" },
    { "pais": "Dominica", "codigo": "+1-767" },
    { "pais": "República Dominicana", "codigo": "+1-809" },
    { "pais": "Equador", "codigo": "+593" },
    { "pais": "Egito", "codigo": "+20" },
    { "pais": "El Salvador", "codigo": "+503" },
    { "pais": "Guiné Equatorial", "codigo": "+240" },
    { "pais": "Eritreia", "codigo": "+291" },
    { "pais": "Estônia", "codigo": "+372" },
    { "pais": "Essuatíni (antiga Suazilândia)", "codigo": "+268" },
    { "pais": "Etiópia", "codigo": "+251" },
    { "pais": "Fiji", "codigo": "+679" },
    { "pais": "Finlândia", "codigo": "+358" },
    { "pais": "França", "codigo": "+33" },
    { "pais": "Gabão", "codigo": "+241" },
    { "pais": "Gâmbia", "codigo": "+220" },
    { "pais": "Geórgia", "codigo": "+995" },
    { "pais": "Alemanha", "codigo": "+49" },
    { "pais": "Gana", "codigo": "+233" },
    { "pais": "Grécia", "codigo": "+30" },
    { "pais": "Granada", "codigo": "+1-473" },
    { "pais": "Guatemala", "codigo": "+502" },
    { "pais": "Guiné", "codigo": "+224" },
    { "pais": "Guiné-Bissau", "codigo": "+245" },
    { "pais": "Guyana", "codigo": "+592" },
    { "pais": "Haiti", "codigo": "+509" },
    { "pais": "Honduras", "codigo": "+504" },
    { "pais": "Hungria", "codigo": "+36" },
    { "pais": "Islândia", "codigo": "+354" },
    { "pais": "Índia", "codigo": "+91" },
    { "pais": "Indonésia", "codigo": "+62" },
    { "pais": "Irã", "codigo": "+98" },
    { "pais": "Iraque", "codigo": "+964" },
    { "pais": "Irlanda", "codigo": "+353" },
    { "pais": "Israel", "codigo": "+972" },
    { "pais": "Itália", "codigo": "+39" },
    { "pais": "Jamaica", "codigo": "+1-876" },
    { "pais": "Japão", "codigo": "+81" },
    { "pais": "Jordânia", "codigo": "+962" },
    { "pais": "Cazaquistão", "codigo": "+7" },
    { "pais": "Quênia", "codigo": "+254" },
    { "pais": "Kiribati", "codigo": "+686" },
    { "pais": "Coreia do Norte", "codigo": "+850" },
    { "pais": "Coreia do Sul", "codigo": "+82" },
    { "pais": "Kuwait", "codigo": "+965" },
    { "pais": "Quirguistão", "codigo": "+996" },
    { "pais": "Laos", "codigo": "+856" },
    { "pais": "Letônia", "codigo": "+371" },
    { "pais": "Líbano", "codigo": "+961" },
    { "pais": "Lesoto", "codigo": "+266" },
    { "pais": "Libéria", "codigo": "+231" },
    { "pais": "Líbia", "codigo": "+218" },
    { "pais": "Liechtenstein", "codigo": "+423" },
    { "pais": "Lituânia", "codigo": "+370" },
    { "pais": "Luxemburgo", "codigo": "+352" },
    { "pais": "Madagáscar", "codigo": "+261" },
    { "pais": "Malawi", "codigo": "+265" },
    { "pais": "Malásia", "codigo": "+60" },
    { "pais": "Maldivas", "codigo": "+960" },
    { "pais": "Mali", "codigo": "+223" },
    { "pais": "Malta", "codigo": "+356" },
    { "pais": "Ilhas Marshall", "codigo": "+692" },
    { "pais": "Mauritânia", "codigo": "+222" },
    { "pais": "Maurícias", "codigo": "+230" },
    { "pais": "México", "codigo": "+52" },
    { "pais": "Micronésia", "codigo": "+691" },
    { "pais": "Moldova", "codigo": "+373" },
    { "pais": "Mônaco", "codigo": "+377" },
    { "pais": "Mongólia", "codigo": "+976" },
    { "pais": "Montenegro", "codigo": "+382" },
    { "pais": "Marrocos", "codigo": "+212" },
    { "pais": "Moçambique", "codigo": "+258" },
    { "pais": "Mianmar (antiga Birmânia)", "codigo": "+95" },
    { "pais": "Namíbia", "codigo": "+264" },
    { "pais": "Nauru", "codigo": "+674" },
    { "pais": "Nepal", "codigo": "+977" },
    { "pais": "Países Baixos", "codigo": "+31" },
    { "pais": "Nova Caledônia", "codigo": "+687" },
    { "pais": "Niger", "codigo": "+227" },
    { "pais": "Nigéria", "codigo": "+234" },
    { "pais": "Niue", "codigo": "+683" },
    { "pais": "Noruega", "codigo": "+47" },
    { "pais": "Omã", "codigo": "+968" },
    { "pais": "Paquistão", "codigo": "+92" },
    { "pais": "Palau", "codigo": "+680" },
    { "pais": "Panamá", "codigo": "+507" },
    { "pais": "Papua-Nova Guiné", "codigo": "+675" },
    { "pais": "Paraguai", "codigo": "+595" },
    { "pais": "Peru", "codigo": "+51" },
    { "pais": "Polônia", "codigo": "+48" },
    { "pais": "Portugal", "codigo": "+351" },
    { "pais": "Qatar", "codigo": "+974" },
    { "pais": "Romênia", "codigo": "+40" },
    { "pais": "Ruanda", "codigo": "+250" },
    { "pais": "Rússia", "codigo": "+7" },
    { "pais": "São Cristóvão e Nevis", "codigo": "+1-869" },
    { "pais": "São Tomé e Príncipe", "codigo": "+239" },
    { "pais": "San Marino", "codigo": "+378" },
    { "pais": "Santa Lúcia", "codigo": "+1-758" },
    { "pais": "São Vicente e Granadinas", "codigo": "+1-784" },
    { "pais": "Samoa", "codigo": "+685" },
    { "pais": "Senegal", "codigo": "+221" },
    { "pais": "Serbia", "codigo": "+381" },
    { "pais": "Seychelles", "codigo": "+248" },
    { "pais": "Sierra Leoa", "codigo": "+232" },
    { "pais": "Singapura", "codigo": "+65" },
    { "pais": "Somália", "codigo": "+252" },
    { "pais": "Sri Lanka", "codigo": "+94" },
    { "pais": "Suazilândia", "codigo": "+268" },
    { "pais": "Sudão", "codigo": "+249" },
    { "pais": "Suriname", "codigo": "+597" },
    { "pais": "Suécia", "codigo": "+46" },
    { "pais": "Suíça", "codigo": "+41" },
    { "pais": "Svalbard e Jan Mayen", "codigo": "+47" },
    { "pais": "Síria", "codigo": "+963" },
    { "pais": "Taiwan", "codigo": "+886" },
    { "pais": "Tajiquistão", "codigo": "+992" },
    { "pais": "Tanzânia", "codigo": "+255" },
    { "pais": "Timor-Leste", "codigo": "+670" },
    { "pais": "Togo", "codigo": "+228" },
    { "pais": "Tonga", "codigo": "+676" },
    { "pais": "Trinidad e Tobago", "codigo": "+1-868" },
    { "pais": "Tunísia", "codigo": "+216" },
    { "pais": "Turcomenistão", "codigo": "+993" },
    { "pais": "Turquia", "codigo": "+90" },
    { "pais": "Tuvalu", "codigo": "+688" },
    { "pais": "Ucrânia", "codigo": "+380" },
    { "pais": "Emirados Árabes Unidos", "codigo": "+971" },
    { "pais": "Uruguai", "codigo": "+598" },
    { "pais": "Uzbequistão", "codigo": "+998" },
    { "pais": "Vanuatu", "codigo": "+678" },
    { "pais": "Vaticano", "codigo": "+39-06" },
    { "pais": "Venezuela", "codigo": "+58" },
    { "pais": "Vietnã", "codigo": "+84" },
    { "pais": "Zâmbia", "codigo": "+260" },
    { "pais": "Zimbábue", "codigo": "+263" }
];
