import { DiaUtil } from "core/features/ingresso/typings";
import colors from "core/resources/theme/colors";
import moment from "moment";

export const buildCalendar = (value: moment.Moment) => {
  const startDay = value.clone().startOf("month").startOf("week");
  const endDay = value.clone().endOf("month").endOf("week");

  const day = startDay.clone().subtract(1, "day");
  const calendar = [];
  while (day.isBefore(endDay, "day")) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, "day").clone())
    );
  }
  return calendar;
};

export function isSelect(day: moment.Moment, value: moment.Moment) {
  return value.isSame(day, "day");
}

export function isPast(day: moment.Moment) {
  return day.isBefore(new Date(), "day");
}

export function isToday(day: moment.Moment) {
  return day.isSame(new Date(), "day");
}

function isOpenDay(day: moment.Moment, daysOpen: DiaUtil[]) {
  return daysOpen.some(
    (open) =>
      moment(open.dataReferencia).format("YYYY-MM-DD") ===
      day.format("YYYY-MM-DD")
  );
}

export function getDayBgColor(
  day: moment.Moment,
  value: moment.Moment,
  daysOpen: DiaUtil[]
) {
  if (isSelect(day, value)) return colors.green;
  if (isOpenDay(day, daysOpen) && !isPast(day)) return colors.blue;

  return colors.gray100;
}

export function getDayColor(
  day: moment.Moment,
  value: moment.Moment,
  daysOpen: DiaUtil[]
) {
  if (isSelect(day, value)) return colors.white;
  if (isOpenDay(day, daysOpen) && !isPast(day)) return colors.white;

  return colors.blue;
}
