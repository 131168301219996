import { Flex, Spinner, Text } from "@chakra-ui/react";
import ticket from "assets/images/svgs/ticket.svg";
import { formatMoney } from "core/helpers/number";
import colors from "core/resources/theme/colors";

interface TicketProps {
  navegacaoTicket: any;
  tipoTicket?: string;
  bgImage: any;
  wTicket?: string[];
  hTicket?: string[];
  wImage?: string[];
  hImage?: string[];
  colorTipo?: string;
  fontSizeTipo?: string[];
  titlePreco?: any;
  mbtipoTicket?: string;
  mtipoTicket?: string;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onClickImage?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

export default function Ticket({
  navegacaoTicket,
  tipoTicket,
  bgImage,
  wTicket = ["195px"],
  hTicket = ["270px"],
  wImage = ["100%"],
  hImage,
  colorTipo = "black",
  fontSizeTipo = ["14px"],
  titlePreco,
  loading,
  mtipoTicket = "auto",
  mbtipoTicket = "16px",
  onClick,
  onClickImage,
}: TicketProps) {
  return (
    <Flex
      bgImage={ticket}
      w={wTicket}
      h={hTicket}
      flexDir="column"
      m="auto"
      bgRepeat="no-repeat"
      bgSize={"cover"}
      onClick={onClick}
    >
      <>
        {loading ? (
          <Spinner
            m={"auto 0"}
            alignSelf={"center"}
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          <Flex
            onClick={onClickImage}
            cursor="pointer"
            bgRepeat="no-repeat"
            bgSize={"cover"}
            sx={{ aspectRatio: "1" }}
            bgImage={bgImage}
            w={wImage}
            h={hImage}
            m="auto"
            mt="10px"
            rounded="6px"
          >
            <Flex
              w="100%"
              h="100%"
              ml={`calc(${wImage} - 35px)`}
              mt={`calc(${hImage} - 40px)`}
            />

          </Flex>
        )}
      </>

      <Flex flexDir="column" px={2}>
        <Flex
          m={mtipoTicket}
          mb={mbtipoTicket}
          color={colorTipo}
          fontSize={fontSizeTipo}
        >
          <Text
            fontWeight={"bold"}
            textAlign="center"
            fontSize={"1rem"}
            mb={"0.5rem"}
          >
            {tipoTicket}
          </Text>
        </Flex>
      </Flex>
      {titlePreco ? (
        <Flex gap={1} m="auto" align="center">
          <Text>A partir de </Text>
          <Text fontWeight="bold" fontSize="18px" color={colors.gray600}>
            {" "}
            {formatMoney(titlePreco)}
          </Text>
        </Flex>
      ) : null}
      <Flex m="auto" mb="8px">
        {navegacaoTicket}
      </Flex>
    </Flex>
  );
}
