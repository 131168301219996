import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import useAlterarDadosVisitante from "core/features/ingresso/hooks/useAlterarDadosVisitante";
import { useDiasDisponiveis } from "core/features/ingresso/hooks/useDiasDisponiveis";
import colors from "core/resources/theme/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "ui/components/Button/DefaultButton";
import OutlineButton from "ui/components/Button/OutlineButton";
import Calendar from "ui/components/Calendar/Calendar";
import Field from "ui/components/Input/Field";
import InputMask from "ui/components/Input/InputMask";
import LegendaCalendar from "ui/pages/Admin/Ingresso/components/DataVisita/components/LegendaCalendar";

interface ModalAlterarDataProps {
  visible: boolean;
  onClose: () => void;
  idVenda: number;
  idIngresso: number;
  eventoId: number;
}

export default function ModalAlterarData({
  onClose,
  visible,
  idVenda,
  idIngresso,
  eventoId,
}: ModalAlterarDataProps) {
  const { control, handleSubmit, reset } = useForm<any>({});
  const { data } = useDiasDisponiveis(idVenda, idIngresso);
  const [tipoDocumento, setTipoDocumento] = useState<string>("");

  const handleCloseAndDelete = () => {
    onClose();
    reset({});
  };

  const { mutate } = useAlterarDadosVisitante(
    idVenda,
    idIngresso,
    handleCloseAndDelete
  );

  const onSubmit = async (form: any) => {
    const payload = { ingressoItemId: idIngresso, ...form };
    mutate(payload);
  };
  return (
    <>
      <Modal size={"xl"} isOpen={visible} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>DADOS DO VISITANTE</ModalHeader>
            <ModalCloseButton />

            <Divider mb={5} />
            <ModalBody>
              <Flex flexDir={["column"]} gap={4} w="100%">
                <Flex gap={6} flexDir="column">
                  <Box w="100%">
                    <Field
                      textBorder="Nome"
                      control={control}
                      name="nome"
                      inputProps={{ placeholder: "Nome*" }}
                      rules={{
                        required: {
                          message: "Informe seu nome",
                          value: true,
                        },
                      }}
                    />
                  </Box>
                  <Flex gap={6} flexDir={["column", "column", "row"]}>
                    <RadioGroup
                      onChange={setTipoDocumento}
                      value={tipoDocumento}
                    >
                      <Stack direction="row">
                        <Radio value="cpf">CPF</Radio>
                        <Radio value="passaporte">PASSAPORTE</Radio>
                      </Stack>
                    </RadioGroup>
                    {tipoDocumento === "cpf" && (
                      <Box w="100%">
                        <Field
                          textBorder="CPF"
                          control={control}
                          name="documento"
                          inputProps={{ placeholder: "CPF*" }}
                          rules={{
                            required: "Campo obrigatório",
                            validate: (v) => isValidCPF(v) || "CPF inválido",
                          }}
                          render={({ field, isInvalid }) => {
                            return (
                              <InputMask
                                {...field}
                                isInvalid={isInvalid}
                                mask="999.999.999-99"
                                placeholder="CPF*"
                              />
                            );
                          }}
                        />
                      </Box>
                    )}
                    {tipoDocumento === "passaporte" && (
                      <Box w="100%">
                        <Field
                          textBorder="PASSAPORTE"
                          control={control}
                          name="documento"
                          inputProps={{ placeholder: "PASSAPORTE*" }}
                          rules={{
                            required: "Campo obrigatório",
                          }}
                        />
                      </Box>
                    )}

                    <Box w="100%">
                      <Field
                        textBorder="Email"
                        control={control}
                        name="email"
                        inputProps={{ placeholder: "Email*" }}
                        rules={{
                          required: {
                            message: "Informe seu E-mail",
                            value: true,
                          },
                        }}
                      />
                    </Box>
                  </Flex>

                  <Flex flexDir="column" gap={4} justifyContent={"s"}>
                    <Text mb={2} fontSize="18px">
                      Selecione uma data de utilização:
                    </Text>
                    <Box w="100%">
                      <Field
                        control={control}
                        name="dataEscolhaId"
                        errorMessage="Data de visita é obrigatória"
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <Flex flexDir="column" gap={4}>
                            <Calendar
                              justifySemanas
                              widthCalendar={["100%"]}
                              mlSemanas={["8px", "8px", "8px", "20px"]}
                              pendingXCalendar={[1, 1, 1, 2]}
                              fontSizeStatusDay={["7px", "7px", "7px", "14px"]}
                              daysOpen={data}
                              fieldOnChange={(value) =>
                                field?.onChange?.(value?.id)
                              }
                              onChangeDiaUtil={(value) =>
                                field?.onChange?.(value?.id)
                              }
                            />
                            <Flex justifyContent="space-between" mb={2}>
                              <LegendaCalendar
                                bg={colors.blue}
                                titleLegenda="Parque aberto"
                              />
                              <LegendaCalendar
                                bg={colors.green}
                                titleLegenda="Data selecionada"
                              />
                              <LegendaCalendar
                                bg={colors.gray100}
                                titleLegenda="Parque fechado"
                              />
                            </Flex>
                          </Flex>
                        )}
                      />
                    </Box>
                  </Flex>
                </Flex>
                <Box textAlign={"center"}>
                  <Text color={colors.red} fontWeight={"bold"}>
                    Atenção: Esses dados não poderão ser Alterados
                  </Text>
                </Box>
              </Flex>
            </ModalBody>
            <Divider my={10} />

            <Flex
              flex={1}
              justifyContent="end"
              w="100%"
              align="center"
              mb={5}
              gap={4}
              px={10}
            >
              <OutlineButton title="FECHAR" onClick={onClose} />
              <DefaultButton type="submit" title="CONFIRMAR" bg={colors.red} />
            </Flex>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
