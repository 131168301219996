import { Flex, Text, Grid } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import CardIngresso from "./CardIngresso";
import { IngressoItemDetalhado } from "core/features/ingresso/typings";

interface MeusIngressosProps {
  idVenda: number;
  eventoId: number;
  data: IngressoItemDetalhado[];
}

export default function MeusIngressos({
  data,
  idVenda,
  eventoId,
}: MeusIngressosProps) {
  // const [poppoverOpen, setPoppoverOpen] = useState(false);
  return (
    <Flex w="100%" flexDir="column" gap={10}>
      <Flex flexDir={["column", "column", "row"]}>
        <Text
          fontWeight="medium"
          fontSize="24px"
          color={colors.purple500}
          flexWrap="wrap"
        >
          MEUS INGRESSOS
        </Text>
        <Flex flex={1} justify={["start", "end"]} gap={4}></Flex>
      </Flex>
      <Grid
        templateColumns={{ sm: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
        gap={6}
        w="100%"
      >
        {data.map((item, index) => (
          <CardIngresso
            key={index}
            data={item}
            idVenda={idVenda}
            eventoId={eventoId}
          />
        ))}
      </Grid>
    </Flex>
  );
}
