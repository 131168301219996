import { Flex } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";

interface StepTagProps {
  step: string;
}

export default function StepTag({ step }: StepTagProps) {
  return (
    <Flex
      w="30px"
      h="30px"
      bg={colors.lightBlue}
      color={colors.blue}
      fontWeight="medium"
      rounded="full"
      align="center"
      justify="center"
      shrink={0}
    >
      {step}
    </Flex>
  );
}
