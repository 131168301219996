import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import DadosForm from "../FormaPagamento/components/Pix/DadosForm";

interface ModalEnderecoProps {
  visible: boolean;
  onClose: () => void;
  onSubmit?: (data: any) => void;
  data: any;
}

function ModalEndereco({
  data,
  onClose,
  visible,
  onSubmit,
}: ModalEnderecoProps) {
  return (
    <Modal size="2xl" isOpen={visible} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Precisamos que informe seu endereço para continuar com o pagamento
        </ModalHeader>
        <ModalCloseButton />

        <Divider mb={5} />
        <ModalBody>
          <DadosForm data={data} onSubmit={onSubmit} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ModalEndereco;
