import { Flex, Text } from "@chakra-ui/react";
import { DiaUtil } from "core/features/ingresso/typings";
import moment from "moment";
import { getDayBgColor, getDayColor, isPast } from "./../utils";

interface CardDayProps {
  widthCalendarDay: string[];
  heightCalendarDay: string[];
  day: moment.Moment;
  selectDay: moment.Moment;
  daysOpen: DiaUtil[];
  isCurrentMonth: boolean;
  fontSizeStatusDay?: string[];
  setValue: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setSelectDay: React.Dispatch<React.SetStateAction<moment.Moment>>;
  fieldOnChange?: (...event: any[]) => void;
  onChangeDiaUtil?: (diaUtil?: DiaUtil) => void;
}

export default function CardDay({
  heightCalendarDay,
  widthCalendarDay,
  day,
  daysOpen,
  setValue,
  isCurrentMonth,
  setSelectDay,
  selectDay,
  fontSizeStatusDay = ["14px"],
  fieldOnChange,
  onChangeDiaUtil,
}: CardDayProps) {
  function isOpen(day: moment.Moment) {
    return daysOpen?.some(
      (open) =>
        moment(open.dataReferencia).format("YYYY-MM-DD") ===
        day.format("YYYY-MM-DD")
    );
  }

  const openned = isOpen(day) && !isPast(day);

  return (
    <Flex
      w={widthCalendarDay}
      h={heightCalendarDay}
      p="0"
      mx="3px"
      my="3px"
      rounded={4}
      onClick={() => {
        if (!openned) {
          return;
        }

        setSelectDay(day);
        fieldOnChange!(day);
        onChangeDiaUtil?.(
          daysOpen.find(
            (d) =>
              moment(d.dataReferencia).format("YYYY-MM-DD") ===
              day.format("YYYY-MM-DD")
          )
        );
      }}
      bg={getDayBgColor(day, selectDay, daysOpen)}
      cursor={openned ? "pointer" : "not-allowed"}
      opacity={isCurrentMonth ? 1 : 0.2}
    >
      <Flex
        w="100%"
        h="100%"
        p="4px"
        color={getDayColor(day, selectDay, daysOpen)}
        flexDir="column"
        onClick={() => !isPast(day) && setValue(day)}
        justify="space-between"
      >
        <Flex>{day.format("D").toString()}</Flex>
        {!openned ? null : (
          <Text
            fontSize={fontSizeStatusDay}
            align="center"
            mb="4px"
            color={getDayColor(day, selectDay, daysOpen)}
          >
            ABERTO
          </Text>
        )}
      </Flex>
    </Flex>
  );
}
