import { useState } from "react";
import { Collapse, Flex, Text, Box } from "@chakra-ui/react";

import colors from "core/resources/theme/colors";
import { BiChevronUp } from "react-icons/bi";
import { BiChevronDown } from "react-icons/bi";

interface CollapsibleCardProps {
  titleCard: string;
  subtitleCard?: string;
  children: any;
}

export default function CollapsibleCard({
  titleCard,
  subtitleCard,
  children,
}: CollapsibleCardProps) {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Flex
      bg={colors.gray10}
      borderBottom={`solid 3px ${colors.gray300}`}
      rounded={10}
      pb={4}
      overflow="visible"
      flexDir="column"
    >
      <Flex
        w="100%"
        justifyContent="flex-start"
        py={2}
        px={6}
        cursor="pointer"
        onClick={() => setCollapsed((state) => !state)}
      >
        <Flex flexDir="column" w={"100%"}>
          <Text fontWeight="600" fontSize="16px">
            {titleCard}
          </Text>
          <Text color={colors.gray600}>{subtitleCard}</Text>
        </Flex>

        <Flex
          w="25px"
          h="25px"
          bg={colors.lightBlue}
          rounded="full"
          align="center"
          justify="center"
          color={colors.blue}
          cursor="pointer"
          ml="auto"
        >
          {collapsed ? <BiChevronDown size={20} /> : <BiChevronUp size={20} />}
        </Flex>
      </Flex>

      <Collapse in={!collapsed}>
        <Box overflow="visible" py={2} px={6} w="100%">
          {children}
        </Box>
      </Collapse>
    </Flex>
  );
}
