import { Flex, Text } from "@chakra-ui/react";
import { EventoSetorOpcao } from "core/features/eventos/types";
import { formatMoney } from "core/helpers/number";
import colors from "core/resources/theme/colors";

interface ContentResumoProps {
  title: string;
  compraInfo?: {
    id: number;
    quantity: number;
    item: EventoSetorOpcao;
  }[];
}

export default function ContentResumo({
  title,
  compraInfo,
}: ContentResumoProps) {
  return (
    <Flex flexDir="column" w="100%" gap={1}>
      <Flex bg={colors.gray200} w="100%" h="1px" my={2} />
      <Text fontSize="14px">{title}</Text>
      {compraInfo?.map((ingressos) => (
        <Flex justify="space-between" w="100%" key={ingressos.id}>
          <Flex gap={1}>
            {ingressos.quantity ? (
              <Text fontSize="18px">{ingressos.quantity}x </Text>
            ) : null}
            <Text fontSize="18px">{ingressos.item.nome}</Text>
          </Flex>

          <Text fontSize="18px" fontWeight="medium">
            {formatMoney(
              (ingressos.item.valorBase + (ingressos.item.valorTaxaWeb || 0)) *
                ingressos.quantity
            )}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
}
