import { Select } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";

export interface SelectSexoProps {
  value: any;
  onChange: (v: any) => void;
}

function SelectSexo({ value, onChange }: SelectSexoProps) {
  return (
    <Select
      onChange={onChange}
      value={value}
      fontSize="14px"
      color={colors.gray500}
    >
      <option value="">Selecione</option>
      <option value={"m"}>Masculino</option>
      <option value={"f"}>Feminino</option>
      <option value={"o"}>Outro</option>
    </Select>
  );
}

export default SelectSexo;
