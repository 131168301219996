import { api } from "infra/axios";
import { useQuery } from "react-query";
import { useTokenStore } from "./useTokenStore";

function useGetUsuarioLogadoEnderecos() {
  const url = `v1/usuario-logado/endereco/lista`;
  const token = useTokenStore((state) => state?.token?.access_token);

  return useQuery(["usuario-logado-enderecos", token], async () => {
    const res = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });
}

export default useGetUsuarioLogadoEnderecos;
