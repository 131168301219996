import { Flex, Text, useMediaQuery } from "@chakra-ui/react";
import bgStar from "assets/images/svgs/backgroundStars.svg";
import mascote from "assets/images/svgs/mascote-amarelo.svg";
import colors from "core/resources/theme/colors";
import Calendar from "ui/components/Calendar/Calendar";

export default function CalendarioFuncionamento() {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  return (
    <Flex>
      <Flex
        bgImage={bgStar}
        width="100%"
        height="1050px"
        bgSize="cover"
        bgRepeat="no-repeat"
        mt={["150px", "150px", "150px", "350px"]}
        gap={["100px", "100px", "10px", "130px"]}
        flexDir={isLargerThan800 ? "row" : "column"}
        align={isLargerThan800 ? "" : "center"}
        px={isLargerThan800 ? "" : ["0px", "10px"]}
      >
        <Flex flexDir="column" gap="20px" px={["20px", "0px", "20px", "200px"]}>
          <Text
            fontWeight="medium"
            fontSize="24px"
            color={colors.purple500}
            flexWrap="wrap"
          >
            CALENDÁRIO DE FUNCIONAMENTO
          </Text>

          <Flex flexDir="column" gap={2}>
            <Text fontSize="16px">Sábado, domingo e Feriados*:</Text>
            <Text fontSize="16px">
              9h às 17h*Consultar Calendário de Abertura.
            </Text>

            {isLargerThan800 ? (
              <Flex
                transform="scaleX(-1)"
                bgImage={mascote}
                bgRepeat="no-repeat"
                bgSize="cover"
                w={["120px", "120px", "135px", "233px"]}
                height={["150px", "150px", "210px", "365px"]}
              />
            ) : null}
          </Flex>
        </Flex>
        <Calendar
          widthCalendar={["360px", "360px", "360px", "700px"]}
          heightCalendar={["355px", "355px", "355px", "640px"]}
          widthCalendarDay={["44px", "44px", "44px", "90px"]}
          heightCalendarDay={["44px", "44px", "44px", "90px"]}
          gapSemanas={["23px", "23px", "23px", "67px"]}
          mlSemanas={["8px", "8px", "8px", "40px"]}
          pendingXCalendar={[1, 1, 1, 2]}
          fontSizeStatusDay={["7px", "7px", "7px", "14px"]}
          daysOpen={[]}
        />
      </Flex>
    </Flex>
  );
}
