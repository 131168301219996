import { useTokenStore } from "core/features/auth/hooks/useTokenStore";
import { api } from "infra/axios";
import { showErrorAxios } from "infra/packages/helpers/alerta";
import { showSuccess } from "infra/packages/helpers/alerta";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export function useAlterarDadosVisitante(
  idVenda: number,
  itemId: number,
  onClose: () => void
) {
  const token = useTokenStore((state) => state.token?.access_token);
  const queryClient = useQueryClient();
  const url = `/v1/ingressos/${idVenda}/marcar-utilizador`;
  const params = useParams<{ id: string }>();

  return useMutation(
    async (payload: any) => {
      const res = await api.put(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res;
    },
    {
      onSuccess: () => {
        showSuccess();
        queryClient.invalidateQueries(["eventos", params.id]);
        queryClient.invalidateQueries([
          `v1/ingressos/meus-ingressos/${idVenda}/detalhado-item/${itemId}`,
        ]);
        onClose();
      },
      onError: (error: any) => {
        showErrorAxios(error, error?.message);
        onClose();
      },
    }
  );
}
export default useAlterarDadosVisitante;
