import colors from "core/resources/theme/colors";
import styled from "styled-components";
interface FieldContainerProps {
  borderColor?: string;

  invalid: boolean;
}

export const FieldContainer = styled.div<any>`
  width: 100%;
  .chakra-input {
    font-size: ${(props) => props.fontSize}px !important;
  }
  .chakra-input:hover,
  .chakra-input:focus {
    border-color: ${({
      borderColor = colors.gray300,
      invalid,
    }: FieldContainerProps) => (invalid ? colors.red : borderColor)} !important;
    box-shadow: 0 0 0 1px
      ${({ borderColor = colors.gray300, invalid }: FieldContainerProps) =>
        invalid ? colors.red : borderColor} !important;
    transition: all 0.2s;
  }
`;
