import { Flex, Text } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { useNavigate, useParams } from "react-router-dom";
import OutlineButton from "ui/components/Button/OutlineButton";
import FormaPagamento from "./components/FormaPagamento/index";
import ResumoCompra from "./components/ResumoCompra/index";

export default function Pagamento() {
  const { eventoId, setorId } = useParams<{
    eventoId: string;
    setorId: string;
  }>();
  const navigate = useNavigate();

  return (
    <Flex flexDir="column" gap={6}>
      <Flex justify="space-between">
        <Text
          fontWeight="medium"
          fontSize="24px"
          color={colors.purple500}
          flexWrap="wrap"
        >
          Resumo da compra
        </Text>
        <OutlineButton
          title="EDITAR CARRINHO"
          onClick={() => {
            navigate(`/ingresso/${eventoId}/setor/${setorId}`);
          }}
        />
      </Flex>
      <ResumoCompra showResumo={true} />

      <FormaPagamento />
    </Flex>
  );
}
