import { Select } from "@chakra-ui/react";
import { countryCodes } from "core/helpers/countryCodes";
import colors from "core/resources/theme/colors";
import { useEffect } from "react";

export interface SelectCodigoPaisProps {
  value: any;
  onChange: (v: any) => void;
}

const BRASIL_CODIGO = "+55"; // Código de telefone do Brasil

function SelectCodigoPais({ value, onChange }: SelectCodigoPaisProps) {
  const selectedValue = value || BRASIL_CODIGO;
useEffect(() => {
  if (selectedValue === BRASIL_CODIGO) {
    onChange(BRASIL_CODIGO);
  }
}, [selectedValue,onChange]);
  return (
    <Select
      onChange={onChange}
      value={selectedValue}
      fontSize="14px"
      color={colors.gray500}
    >
      <option value="">Selecione o país</option>
      {countryCodes.sort((a, b) => a.pais.localeCompare(b.pais)).map((country) => (
        <option key={country.pais} value={country.codigo}>
          {country.pais} ({country.codigo})
        </option>
      ))}
    </Select>
  );
}

export default SelectCodigoPais;
