import { Text, TextProps } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";

export default function Title({ children, ...props }: TextProps) {
  return (
    <Text
      fontWeight="medium"
      fontSize="24px"
      color={colors.purple500}
      flexWrap="wrap"
      {...props}
    >
      {children}
    </Text>
  );
}
