import { Flex } from "@chakra-ui/react";

interface MediaFoooterProps {
  icon: any;
  link?: string;
}

export default function MediaFoooter({ icon, link }: MediaFoooterProps) {
  return (
    <a target="_blank" rel="noreferrer" href={link}>
      <Flex
        bg="red"
        w="40px"
        h="40px"
        rounded="full"
        justifyContent="center"
        align="center"
        cursor="pointer"
      >
        {icon}
      </Flex>
    </a>
  );
}
