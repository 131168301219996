import { useBreakpointValue } from "@chakra-ui/react";
import { CARRINHO_STEP } from "core/features/carrinho/enums";
import { steps } from "../utils/steps";

function useStepTitle(step: CARRINHO_STEP, nextStep: CARRINHO_STEP) {
  const currentStepTitles = steps[step];
  const nextStepTitles = steps[nextStep];

  const title = useBreakpointValue({
    base: currentStepTitles?.small,
    md: currentStepTitles?.large,
  });

  const nextTitle = useBreakpointValue({
    base: nextStepTitles?.small,
    md: nextStepTitles?.large,
  });

  return [title, nextTitle];
}

export default useStepTitle;
