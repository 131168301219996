import { QueryClient, QueryClientProvider, useQueryClient } from "react-query";
import { useCallback } from "react";
import { showError, showErrorAxios } from "../helpers/alerta";
import { ValidationDetails } from "core/features/eventos/hooks/useComprarIngressoCC";
import { AxiosError } from "axios";


//Config global do react-query
//Define tratamento de erro padrao para Queries e Mutation
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: "always",
      onError(err: any) {
        if (err.response) {
          showErrorAxios(err);
        } else {
          showError();
        }
      },
    },
    mutations: {
      onError(error: AxiosError<ValidationDetails> | any) {
        error.response?.data.details.forEach((detail: { errors: any[]; }) => {
          detail.errors.forEach((error) => {
            showError(error);
          });
        });
      },
    },
  },
});


//Provider do react-query
export const ReactQueryProvider: any = ({ children }: any) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);


//Hook para invalidar queries baseado na string key
export const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  const doInvalidateQueryWithStringKey = useCallback(
    (key: string) => {
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          return typeof queryKey === "string"
            ? queryKey.startsWith(key)
            : false;
        },
      });
    },
    [queryClient]
  );

  return {
    doInvalidateQueryWithStringKey,
  };
};
