import {
  Box,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import Field from "ui/components/Input/Field";
import InputMask from "ui/components/Input/InputMask/index";
import DefaultButton from "ui/components/Button/DefaultButton";
import colors from "core/resources/theme/colors";
import { BiMailSend } from "react-icons/bi";
import { useMutation } from "react-query";
import { showSuccess } from "infra/packages/helpers/alerta";
import { useNavigate } from "react-router-dom";
import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import { useSolicitarAniversario } from "core/features/aniversariante/hooks/useSolicitacaoAniversario";
import { useEffect, useState } from "react";
import DatePicker from "ui/components/Input/DatePicker";
import Swal from "sweetalert2";
import useIsLoggedIn from "core/features/auth/hooks/useIsLoggedIn";
import { useTokenStore } from "core/features/auth/hooks/useTokenStore";

export default function FormAniversariante() {
  const { control, handleSubmit, reset } = useForm<any>({
    defaultValues: { qtdePessoas: 0 },
  });
  const navigate = useNavigate();
  const [selectedMode, setSelectedMode] = useState<string>("1");
  const isLogged = useIsLoggedIn();
  const token = useTokenStore((state) => state?.token?.access_token);

  const { mutate, isLoading } = useMutation(useSolicitarAniversario, {
    onSuccess() {
      showSuccess("Solicitação enviada com sucesso");
      reset({});
      navigate("/");
    },
  });

  useEffect(() => {
    reset({ qtdePessoas: 0 });
  }, [selectedMode, reset]);
  const onSubmit = (form: any) => {
    if (!isLogged) {
      return Swal.fire(
        "Usuário não encontrado",
        "Você precisa fazer login para realizar esta operação",
        "warning"
      );
    }
    const rs = {
      ...form,
      token,
      dataNascimento: form.dataNascimento.toISOString().split("T")[0],
      dataPrevista: form.dataPrevista.toISOString().split("T")[0],
      qtdePessoas: Number(form.qtdePessoas),
      selectedMode,
    };

    mutate(rs);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        flexDir="column"
        w={["100%", "100%", "100%", "600px", "1000px"]}
        gap={10}
      >
        <Flex gap={32}>
          <RadioGroup onChange={setSelectedMode} value={selectedMode}>
            <Stack direction="row" textTransform={"uppercase"}>
              <Radio value="1">Cortesia</Radio>
              <Radio value="2">Orçamento</Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        <Box>
          <Field
            control={control}
            name="nome"
            inputProps={{ placeholder: "Nome*" }}
            rules={{
              required: {
                message: "Informe seu nome",
                value: true,
              },
            }}
          />
        </Box>
        <Flex
          flexDir={["column", "row"]}
          justifyContent="space-between"
          gap={4}
        >
          <Box w="100%">
            <Field
              control={control}
              name="telefone"
              rules={{
                required: {
                  message: "Telefone*",
                  value: true,
                },
              }}
              render={({ field }) => (
                <InputMask
                  {...field}
                  placeholder="Telefone*"
                  mask="(99) 99999-9999"
                />
              )}
            />
          </Box>{" "}
          <Box w="100%">
            <Field
              control={control}
              name="cpF_Passaport"
              inputProps={{ placeholder: "CPF " }}
              rules={{
                required: "Campo obrigatório",
                validate: (v) => isValidCPF(v) || "CPF inválido",
              }}
              render={({ field, isInvalid }) => {
                return (
                  <InputMask
                    {...field}
                    isInvalid={isInvalid}
                    mask="999.999.999-99"
                    placeholder="CPF*"
                  />
                );
              }}
            />
          </Box>
        </Flex>
        <Flex
          w={"100%"}
          flexDir={["column", "row"]}
          justifyContent="space-between"
          gap={4}
        >
          <Box w="100%">
            <Field
              label="Data de Nascimento"
              control={control}
              name="dataNascimento"
              rules={{
                required: {
                  message: "Informe a sua data de aniversário",

                  value: true,
                },
              }}
              render={({ field }) => {
                return (
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selectedDate={field.value}
                    onChange={(date: Date) => {
                      field.onChange(date);
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box w="100%">
            {selectedMode === "2" ? (
              <Field
                label="Data da Comemoração"
                control={control}
                name="dataPrevista"
                rules={{
                  required: {
                    message: "Informe a sua data de comemoração",

                    value: true,
                  },
                }}
                render={({ field }) => {
                  return (
                    <DatePicker

                      dateFormat="dd/MM/yyyy"
                      selectedDate={field.value}
                      onChange={(date: Date) => {
                        if (date.getDay() === 6 || date.getDay() === 0) {
                          field.onChange(date);
                        }
                      }}
                      filterDate={(date: Date) =>
                        date.getDay() === 6 || date.getDay() === 0
                      }
                    />
                  );
                }}
              />
            ) : (
              <Field
                label="Data da Comemoração"
                control={control}
                name="dataPrevista"
                rules={{
                  required: {
                    message: "Informe a sua data de comemoração",

                    value: true,
                  },
                }}
                render={({ field }) => {
                  return (
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selectedDate={field.value}
                      onChange={(date: Date) => {
                        if (date.getDay() === 6) {
                          field.onChange(date);
                        }
                      }}
                      filterDate={(date: Date) => date.getDay() === 6}
                    />
                  );
                }}
              />
            )}
          </Box>
          {selectedMode === "2" && (
            <Box w="100%">
              <Field
                label="Quantidade de pessoas"
                control={control}
                name="qtdePessoas"
                render={({ field }) => (
                  <Input
                    type={"number"}
                    placeholder="Qtde Pessoas"
                    {...field}
                  />
                )}
              />
            </Box>
          )}
        </Flex>
        <Box>
          <Field
            name="mensagemCliente"
            rules={{
              required: "Informe sua mensagem",
            }}
            control={control}
            render={({ field, isInvalid }) => (
              <Textarea
                isInvalid={isInvalid}
                className="chakra-input"
                rows={8}
                placeholder="Mensagem"
                {...field}
              />
            )}
          />
        </Box>
        <Flex w="100%" flexDir={"column"} gap={4} justify="center">
          <Text fontSize={"1rem"}>
            {selectedMode === "1"
              ? "ATENÇÃO: Após o envio do formulário completo, basta comparecer à portaria do parque na data escolhida munido do seu documento pessoal com foto! Comemorar aqui é muito mais divertido :)"
              : "ATENÇÃO: Após o envio do formulário entraremos em contato via WhatsApp para passar o seu orçamento. Fazer a sua festa aqui é muito mais divertido :)"}
          </Text>
          <DefaultButton
            isLoading={isLoading}
            type="submit"
            title="ENVIAR"
            bg={colors.red}
            icon={<BiMailSend size={20} />}
          />
        </Flex>
      </Flex>
    </form>
  );
}
