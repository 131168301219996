import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

interface ModalRecuperarSenhaProps {
  visible: boolean;
  onClose: () => void;
}

function TermoPoliticaModal({ onClose, visible }: ModalRecuperarSenhaProps) {
  return (
    <Modal size="5xl" isOpen={visible} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w="900px">
        <ModalHeader>Condições e regulamento para compra ingresso.</ModalHeader>
        <ModalCloseButton />

        <Divider mb={5} />
        <ModalBody>
          <Stack spacing={2}>
            <Text fontSize="lg">
              1. Os ingressos são válidos por até 90 (noventa) dias a partir da data da compra
            </Text>

            <Text fontSize="lg">
              2. É obrigatória a apresentação de documento oficial com foto.
            </Text>

            <Text fontSize="lg">
              3. Os ingressos promocionais antecipados devem ser adquiridos pelo
              site ou nos pontos de vendas autorizados. O ingresso promocional
              antecipado não é vendido na bilheteria do parque.{" "}
            </Text>

            <Text fontSize="lg">
              4. Para compras de ingressos, serão aceitos como método de pagamento os cartões de crédito Visa, Mastercard, American Express, Elo em até 3x sem juros e Pix. Compras de alimentos e outros somente à vista.
            </Text>

            <Text fontSize="lg">
              5. Crianças de até 4 anos não pagam ingressos e devem estar
              acompanhadas de um adulto. Para o acesso ao parque, na entrada,
              será necessária a apresentação de um documento oficial com foto e
              no caso das crianças que não possuam esse tipo de documento poderá
              ser apresentada a certidão de nascimento.
            </Text>

            <Text fontSize="lg">
              6. Crianças entre 5 e 9 anos pagam pelo ingresso “Infantil” e devem estar acompanhadas de um adulto. Para o acesso ao parque, na entrada, será necessária a apresentação de um documento oficial com foto e no caso das crianças que não possuam esse tipo de documento poderá ser apresentada a certidão de nascimento
            </Text>

            <Text fontSize="lg">

              7. Tem direito a meia entrada por lei, estudantes, pessoas com idade superior a 60 anos e doadores de sangue, jornalistas e jovens de baixa renda. <strong>É obrigatória a apresentação de comprovante de meia-entrada válido por lei, para ingressos com esse benefício.</strong>


            </Text>

            <Text fontSize="lg">
              8. Pessoas com deficiência, portadores de Síndrome de Down e
              Espectro Autista não pagam ingresso e também os seus
              acompanhantes, caso haja necessidade apontada em laudo médico ou
              carteirinha.{" "}
              <strong>
                É obrigatória a apresentação de comprovante de gratuidade válido
                por lei, para ingressos com esse benefício.
              </strong>
            </Text>

            <Text fontSize="lg">
              9. O parque abrirá aos sábados e domingos das 9:00 às 17:00, salvo em caso de força maior. Os dias e horários operacionais sujeitos à alteração sem prévio aviso.
            </Text>

            <Text fontSize="lg">
              10. Em caso de chuvas fortes, ventos, raios e trovoadas a administração do Ácqua Park Pantanal pode interromper a qualquer momento o funcionamento de todos os brinquedos/atrações que entender necessário como: toboáguas, piscinas, tirolesa e de toda estrutura que entender necessário para manter a segurança dos presentes;
            </Text>

            <Text fontSize="lg">
              11. O Ácqua Park Pantanal não se responsabiliza por ingressos
              adquiridos fora dos postos oficiais de venda.
            </Text>

            <Text fontSize="lg">
              12. Não é permitido entrar com alimentos e bebidas, inclusive com
              itens para aniversário como bolos e doces. `No caso de pessoas com
              restrição alimentar, será autorizada a entrada com o alimento,
              mediante apresentação do Laudo médico em quantidade suficiente
              para o portador da restrição.
            </Text>

            <Text fontSize="lg">
              13.É permitido entrar e sair do parque quantas vezes precisar durante o horário de funcionamento do dia escolhido estando com a pulseira de acesso desde que a mesma não esteja violada.
            </Text>

            <Text fontSize="lg">
              14. O parque não se responsabiliza por objetos e pertences dos
              usuários.
            </Text>

            <Text fontSize="lg">
              15. O parque produz fotos e vídeos para posterior publicação. Ao
              comprar o ingresso você concorda e autoriza a utilização gratuita
              de sua imagem, sem limite de vezes e tempo, sem que isso
              caracterize uso indevido de imagem ou qualquer outro direito da
              personalidade e sem que dessa utilização ocorra qualquer ônus e/ou
              indenização.
            </Text>

            <Text fontWeight="black" fontSize="lg">
              16. É totalmente proibido o uso de produtos considerados entorpecentes, listados na resolução RDC
              143/17 da ANVISA, sendo certo que a desobediência dessa imposição, acarretará na retirada
            </Text>
            <Text fontWeight="black" fontSize="lg">
              17. Não é responsabilidade do parque zelar pela saúde dos usuários, sendo certo que já consta no item 18 deste regulamento as proibições de itens que impedem o acesso ao parque aquático, cabendo reforçar que doenças de pele e demais lesões não serão fiscalizadas pelo Ácqua Park Pantanal, mas que se detectadas pelos monitores, médicos, enfermeiros, salva-vidas ou qualquer outro preposto, acarretará na imediata retirada da pessoa e na adoção de medidas de suspensão do direito de uso até se encontrar apto para uso do parque aquático.
            </Text>
            <Text fontWeight="black" fontSize="lg">
              18. NÃO SERÁ PERMITIDO NAS PISCINAS DO PARQUE AQUÁTICO:
              •	Usar curativos, faixas, gaze, gesso, absorventes higiênicos, algodão ou pomadas;
              •	Usar bronzeadores, sabonetes, copos e utensílios de vidro;
              •	Realizar brincadeiras que impliquem riscos aos demais frequentadores do Parque;
              •	Usar shorts, bermudas, calça jeans, vestidos ou qualquer roupa que possua zíper, botões, ilhós e outros adereços que possam danificar os equipamentos do parque;
              •	Não será permitido qualquer tipo de comportamento indevido que possa ofender a moral e a dignidade dos usuários;
            </Text>
            <Text fontWeight="black" fontSize="lg">
              19. O traje de banho masculino será preferencialmente sunga, podendo ainda usar calção com forração interna tipo tactel. O traje feminino será preferencialmente biquíni, maiô ou shorts de malha.             </Text>
            <Text fontWeight="black" fontSize="lg">
              20. Ao adquirir o ingresso, o cliente declara conhecer e concordar com todas as informações acima.            </Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default TermoPoliticaModal;
