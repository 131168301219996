import { CARRINHO_STEP } from "core/features/carrinho/enums";
import { useLocation } from "react-router-dom";

function useCurrentStep() {
  const pathname = useLocation().pathname;

  if (/^\/ingresso\/(\d+)$/.test(pathname)) {
    return CARRINHO_STEP.INGRESSO;
  }

  if (/^\/ingresso\/(\w+)\/setor$/.test(pathname)) {
    return CARRINHO_STEP.DATA;
  }

  if (/^\/ingresso\/(\w+)\/setor\/(\w+)$/.test(pathname)) {
    return CARRINHO_STEP.TIPO_INGRESSO;
  }

  if (/^\/ingresso\/(\w+)\/setor\/(\w+)\/identificacao$/.test(pathname)) {
    return CARRINHO_STEP.IDENTIFICACAO;
  }

  if (/^\/ingresso\/(\w+)\/setor\/(\w+)\/pagamento$/.test(pathname)) {
    return CARRINHO_STEP.PAGAMENTO;
  }

  if (
    /^\/ingresso\/([^/]+)\/setor\/([^/]+)\/confirmacao\/pix$/.test(pathname)
  ) {
    return CARRINHO_STEP.CONFIRMACAO;
  }

  return CARRINHO_STEP.INGRESSO;
}

export default useCurrentStep;
