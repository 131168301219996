import { api } from "infra/axios";
import { useQuery } from "react-query";
import { Evento } from "../types";

export function useEventoDestaque() {
  const url = `/v1/ingressos/eventos-destaque`;

  return useQuery<Evento>(url, async () => {
    const { data } = await api.get(url);

    return data;
  });
}
