import { Flex, Image, Text } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import backgroundDescricaoHome from "assets/images/svgs/backgrounDescricaoHome.svg";
import EstruturaImagesContent from "ui/components/EstruturaImagesContent";
import { useNavigate } from "react-router-dom";
import { useGetImagensSite } from "core/features/hooks/useGetImagensSite";
interface DescricaoHomeProps {
  isLargerThan800: boolean;
}

export default function DescricaoHome({ isLargerThan800 }: DescricaoHomeProps) {
  const navigate = useNavigate();
  const { data: imagens } = useGetImagensSite();

  return (
    <Flex
      bgImage={["none", "none", "none", backgroundDescricaoHome]}
      bg="white"
      w="100%"
      h={["max-content", "max-content", "max-content", "max-content"]}
      bgRepeat="no-repeat"
      bgSize={"cover"}
      px={["10px", "15px", "20px", "20px", "150px"]}
    >
      <Flex flexDir={"column"} pt="20px" gap={10} w="100%">
        <Text
          fontWeight="medium"
          fontSize="32px"
          color={colors.purple500}
          flexWrap="wrap"
        >
          A MAIOR PISCINA DE ONDAS DO MATO GROSSO
        </Text>
        <Text fontSize="24px">
          O Ácqua Park Pantanal é um dos maiores parques aquáticos do Brasil com
          a maior piscina de ondas do Mato Grosso.
        </Text>
        <EstruturaImagesContent
          isLarger={isLargerThan800}
          qtdeFotos={5}
          isHome
        />

        <Flex
          mt={8}
          gap={10}
          w="100%"
          justify={"center"}
          align={"center"}
          flexDir={["column", "column", "column", "column", "row"]}
        >
          <Image
            loading={"lazy"}
            w={[550, 550, 550, 550, 550, 650]}
            cursor="pointer"
            onClick={() => navigate("/aniversariante")}
            src={imagens?.bannerStatico01}
          />

          {imagens?.bannerVideo01 && (
            <video
              width="550"
              autoPlay
              muted
              loop
              playsInline
              onClick={() => navigate("/aniversariante")}
            >
              <source src={imagens?.bannerVideo01} type="video/mp4" />
            </video>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
