import { AxiosError } from "axios";
import { useTokenStore } from "core/features/auth/hooks/useTokenStore";
import { api } from "infra/axios";
import { showError } from "infra/packages/helpers/alerta";
import { useMutation, useQueryClient } from "react-query";
import { ValidationDetails } from "./useComprarIngressoCC";

export interface Cartao {
  principal: boolean;
  nomePortador: string;
  numeroCartao: string;
  mesExpiracao: string;
  anoExpiracao: string;
  codigoSeguranca: string;
  cpf: string;
}

function useCadastrarCartao(onSuccess?: () => void) {
  const url = `/v1/cartoes`;
  const token = useTokenStore((state) => state?.token?.access_token);

  const queryClient = useQueryClient();

  return useMutation(
    async (cartao: Cartao) => {
      const { data } = await api.post(url, cartao, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    {
      onSuccess: () => {
        onSuccess?.();
        queryClient.invalidateQueries(url);
      },
      onError: (error: AxiosError<ValidationDetails>) => {
        error.response?.data.details.forEach((detail) => {
          detail.errors.forEach((error) => {
            showError(error);
          });
        });
      },
    }
  );
}

export default useCadastrarCartao;
