import { Flex } from "@chakra-ui/layout";
import React from "react";

interface ContentItemProps {
  titulo: string;
  descricao?: string | React.ReactElement;
}

export default function ContentItem({ titulo, descricao }: ContentItemProps) {
  return (
    <Flex direction="column" mb="3">
      <Flex color="#768398" fontSize="14px">
        {titulo}
      </Flex>
      <Flex fontSize="18px">{descricao}</Flex>
    </Flex>
  );
}
