import { api } from "infra/axios";
import { useQuery } from "react-query";

export interface ImagensUteis {
    bannerStatico01: string
    bannerStatico02: string
    bannerStatico03: string
    bannerVideo01: string
    bannerVideo02: string
    bannerVideo03: string
}
export function useGetImagensSite() {
    const url = `/v1/site-configuracao/imagens-uteis`;

    return useQuery(url, async () => {
        const { data } = await api.get<ImagensUteis>(url);

        return data;
    });
}
