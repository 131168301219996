import { useQueryClient } from "react-query";
import { useTokenStore } from "./useTokenStore";
import { useNavigate } from "react-router-dom";

function useLogout() {
  const queryClient = useQueryClient();
  const token = useTokenStore((state) => state.token?.access_token);
  const navigate = useNavigate();
  return () => {
    useTokenStore.getState().setToken(undefined);
    queryClient.invalidateQueries(["user", token]);
    navigate("/");
  };
}

export default useLogout;
