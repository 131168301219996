import { FormularioCadastroUsuario } from "core/features/register/typings";
import { useState } from "react";
import { useMutation } from "react-query";
import { genCadastroToken, signup } from "./useApi";
import useSignin from "./useSignin";
import { useTokenStore } from "./useTokenStore";
import { showError, } from "infra/packages/helpers/alerta";


function useSignup(onSuccess?: () => void) {
  const [token, setToken] = useState<string | null>(null);

  const { mutateAsync } = useSignin();

  return useMutation(
    async (body: FormularioCadastroUsuario) => {
      if (!token) {
        const { access_token } = await genCadastroToken();
        setToken(access_token);

        await signup(body, access_token);
        return await mutateAsync({ email: body.email, password: body.senha });
      }

      await signup(body, token);
      return await mutateAsync({ email: body.email, password: body.senha });
    },
    {
      onSuccess: (data) => {
        useTokenStore.getState().setToken(data);
        onSuccess?.();
      },
      onError: (error: any) => {

        showError(JSON.stringify(error.response?.data))

      },
    }
  );
}

export default useSignup;
