import { useCallback, useState } from "react";
import InputMask from "../InputMask";
import { Button } from "@chakra-ui/button";
import { Box } from "@chakra-ui/layout";
import colors from "core/resources/theme/colors";
import { showError } from "infra/packages/helpers/alerta";

export interface CepResponse {
  logradouro: string;
  numero: string;
  nomeEstado: string;
  nomeCidade: string;
  ufSigla: string;
  bairroId: string;
  nomeBairro: string;
  ufId: number;
  cidadeId: number;
  habilitaCadastro: boolean
}

interface InputCepProps {
  value: any;
  onChange: (val: any) => any;
  onLoadCep: (data: CepResponse) => any;
}
function InputCep({
  value,
  onChange,
  onLoadCep,
  ...props
}: InputCepProps & Record<string, any>) {
  const [isLoading, setIsLoading] = useState(false);

  const handleCep = useCallback(async () => {
    if (!value) {
      return;
    }

    try {
      setIsLoading(true);

      const cep = value.replace(/\D/g, "");
      fetch(`https://site-api2.tactonuvem.com.br//v1/api/ceps/${cep}`)
        .then((res) => res.json())
        .then((data) => {
          if (!data) {
            showError("CEP não encontrado");
          }
          onLoadCep(data);
        });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [onLoadCep, value]);

  return (
    <Box position="relative">
      <InputMask
        placeholder="CEP*"
        mask="99999-999"
        value={value}
        onChange={(v: any) => {
          onChange(v);
        }}
        onKeyDown={(ev) => {
          if (ev.keyCode === 13 && typeof onLoadCep === "function") {
            handleCep();
          }
        }}
        {...props}
      />
      <Button
        isLoading={isLoading}
        disabled={isLoading}
        position="absolute"
        right="4px"
        fontWeight="normal"
        bg={colors.red}
        color={colors.white}
        _hover={{
          bg: colors.red,
        }}
        bottom="2px"
        size="sm"
        onClick={() => {
          if (typeof handleCep === "function") {
            handleCep();
          }
        }}
      >
        {isLoading ? null : "Buscar"}
      </Button>
    </Box>
  );
}

export default InputCep;
