import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    
    box-sizing: border-box
  }

  body{
    overflow-x: hidden;
    
  }

  body, input, button {
    font: 14px Outfit, sans-serif;
    color:'#718096';
  }

  button {
    cursor: pointer;
  }

  
  
`;
