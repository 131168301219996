import { useTokenStore } from "core/features/auth/hooks/useTokenStore";
import { api } from "infra/axios";
import { useQuery } from "react-query";

export function useMeusIngressos() {
  const url = `/v1/ingressos/meus-ingressos`;
  const token = useTokenStore((state) => state.token?.access_token);

  return useQuery(url, async () => {
    const { data } = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  });
}
