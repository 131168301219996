import { Flex, Spinner, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { useInformativosList } from "core/features/informativos/hooks/useInformativosList";
import colors from "core/resources/theme/colors";
import backgroundAtracaoCard from "assets/images/svgs/backgroundAtracaoCard.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
export default function Informativos() {
  const { data, isLoading } = useInformativosList();
  return (
    <Flex
      py={["150px", "150px", "150px", "350px"]}
      px={["50px", "50px", "30px", "200px"]}
      gap="80px"
      flexDir={"column"}
    >
      <Text
        fontWeight="medium"
        fontSize="24px"
        color={colors.purple500}
        flexWrap="wrap"
      >
        INFORMATIVOS
      </Text>
      {isLoading ? (
        <Spinner
          alignSelf={"center"}
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      ) : (
        <Wrap gap={16} w="100%" justify={"center"}>
          {data?.map((info: any, key: number) => {
            return (
              <InformativoCard
                key={key}
                id={info.id}
                image={info.imagemUrlCompleta}
                title={info.titulo}
                date={info.dataInformativo}
              />
            );
          })}
        </Wrap>
      )}
    </Flex>
  );
}

const InformativoCard = ({ image, title, date, id }: any) => {
  const navigate = useNavigate();

  return (
    <WrapItem

      cursor={"pointer"}
      onClick={() => navigate(`/informativos/${id}`)}
    >
      <Flex
        bgImage={backgroundAtracaoCard}
        bgRepeat="no-repeat"
        bgSize={"cover"}
        flexDir="column"
        h='450px'
        gap={4}
        p={4}
        mr="auto"
        ml="auto"
      >

        <Flex
          bgImage={image}
          w={240}
          sx={{ aspectRatio: '1' }}
          bgRepeat="no-repeat"
          bgSize={"cover"}
          rounded={10}
        />

        <Flex flexDir="column" px={4} gap={8}>
          <Text
            fontWeight="medium"
            fontSize={["14px", "18px"]}
            color={colors.purple500}
          >
            {title}
          </Text>
          <Text fontSize={["12px", "14px"]}>
            {moment(date).format("DD/MM/YYYY")}
          </Text>
        </Flex>
      </Flex>
    </WrapItem>
  );
};
