import { Flex } from "@chakra-ui/react";
import useGetDiasUteis from "core/features/eventos/hooks/useGetDiasUteis";
import { DiaUtil, IngressoDto } from "core/features/ingresso/typings";
import colors from "core/resources/theme/colors";
import { Control } from "react-hook-form";
import Calendar from "ui/components/Calendar/Calendar";
import Field from "ui/components/Input/Field";
import LegendaCalendar from "./components/LegendaCalendar";

export default function DataVisita({
  control,
  onChangeDiaUtil,
}: {
  form?: IngressoDto;
  control: Control<{
    setorId: number;
    dataVisita: string;
  }>;
  onChangeDiaUtil?: (diaUtil?: DiaUtil) => void;
}) {
  const { data } = useGetDiasUteis();

  return (
    <Flex flexDir="column">
      <Flex gap="60px" m="auto">
        <Field
          control={control}
          name="dataVisita"
          errorMessage="Data de visita é obrigatória"
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <Flex flexDir="column" gap={4}>
              <Calendar
                widthCalendar={["360px", "360px", "360px", "550px"]}
                heightCalendar={["355px", "355px", "355px", "510px"]}
                widthCalendarDay={["44px", "44px", "44px", "70px"]}
                heightCalendarDay={["44px", "44px", "44px", "70px"]}
                gapSemanas={["23px", "23px", "23px", "48px"]}
                mlSemanas={["8px", "8px", "8px", "20px"]}
                pendingXCalendar={[1, 1, 1, 2]}
                fontSizeStatusDay={["7px", "7px", "7px", "14px"]}
                daysOpen={data || []}
                fieldOnChange={field.onChange}
                onChangeDiaUtil={onChangeDiaUtil}
              />
              <Flex justifyContent="space-between" px={4} mb={2}>
                <LegendaCalendar
                  bg={colors.blue}
                  titleLegenda="Parque aberto"
                />
                <LegendaCalendar
                  bg={colors.green}
                  titleLegenda="Data selecionada"
                />
                <LegendaCalendar
                  bg={colors.gray100}
                  titleLegenda="Parque fechado"
                />
              </Flex>
            </Flex>
          )}
        />
      </Flex>
    </Flex>
  );
}
