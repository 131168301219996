import { Box, Flex } from "@chakra-ui/react";
import Container from "ui/components/Layout/Container";
import BarStep from "./components/BarStep";
import Pagamento from "./components/Pagamento";
import useCurrentStep from "./hooks/useCurrentStep";
import useStepTitle from "./hooks/useStepTitle";

function PagamentoPage() {
  const activeStep = useCurrentStep();
  const [titleStep, titleNextStep] = useStepTitle(activeStep, activeStep + 1);

  return (
    <Container>
      <Flex flexDir="column">
        <Flex
          pt={["130px", "130px", "130px", "330px"]}
          mb="80px"
          flexDir={"column"}
          gap={8}
        >
          <BarStep
            step={activeStep}
            titleNextStep={titleNextStep!}
            nextStep={activeStep + 1}
            titleStep={titleStep!}
          />

          <Box>
            <Pagamento />
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
}

export default PagamentoPage;
