import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIngressosStore } from "./useIngressosStore";

function useBackToBeginning() {
  const dataSelecionada = useIngressosStore((state) => state.dataSelecionada);
  const navigate = useNavigate();

  useEffect(() => {
    if (!dataSelecionada) {
      navigate("/ingresso");
    }
  }, [dataSelecionada, navigate]);
}

export default useBackToBeginning;
