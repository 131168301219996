import { api } from "infra/axios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Evento } from "../types";

function useEventoDetalhado() {
  const params = useParams<{ eventoId: string }>();
  const url = `/v1/ingressos/evento/${params.eventoId}/detalhado`;

  return useQuery(["eventos", params.eventoId], async () => {
    const { data } = await api.get<Evento>(url);

    return data;
  });
}

export default useEventoDetalhado;
