import {
  Flex,
  Text,
  useMediaQuery,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import AtracoesCarousel from "ui/components/AtracoesCarousel";
import EstruturaImagesContent from "../../../components/EstruturaImagesContent";
import OutlineButton from "ui/components/Button/OutlineButton";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export default function ParqueAquatico() {
  const navigate = useNavigate();
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  return (
    <Flex
      py={["150px", "150px", "150px", "350px"]}
      px={["50px", "50px", "30px", "200px"]}
      gap="40px"
      flexDir={"column"}
    >
      <Flex flexDir={"column"} pt="20px" gap={10} w="100%">
        <Text
          fontWeight="medium"
          fontSize="24px"
          color={colors.purple500}
          flexWrap="wrap"
        >
          PARQUE AQUÁTICO
        </Text>
        <Text fontSize="16px">
          O Ácqua Park Pantanal nasceu no meio da natureza, na região do
          pantanal de Mato Grosso, a apenas 20 km de Cuiabá. Cercado por lagos,
          árvores do cerrado e solo que remontam a 5 milhões de anos.
        </Text>
        <Text fontSize="16px">
          Está localizado na rota do turismo, entre as cidades de Chapada dos
          Guimarães e Cuiabá.
        </Text>
        <Text fontSize="16px">
          É grande a facilidade de acesso pois a estrada é duplicada e
          iluminada.
        </Text>
      </Flex>
      <Flex flexDir={"column"} pt="20px" gap={10} w="100%">
        <Text
          fontWeight="medium"
          fontSize="24px"
          color={colors.purple500}
          flexWrap="wrap"
        >
          ESTRUTURA DO PARQUE
        </Text>
        <Text fontSize="16px">
          O parque conta com estrutura completa composta por dezenas atrações
          que agradam a todos os estilos e idades, como por exemplo:
        </Text>

        <Text>
          <UnorderedList fontSize="16px">
            <ListItem>PISCINA DE ONDAS</ListItem>
            <ListItem>CACHOEIRA VÉU DE NOIVA</ListItem>
            <ListItem>CACHOEIRA JABUTI</ListItem>
            <ListItem>ACQUALÂNDIA</ListItem>
            <ListItem>PISCINA OASIS SEMI COBERTA</ListItem>
            <ListItem>BAR MOLHADO</ListItem>
            <ListItem>
              03 TOBOÁGUAS RADICAIS: FANTÁSTICO, FABULOSO E MAGNÍFICO
            </ListItem>
            <ListItem>TOBOÁGUAS INFANTIS</ListItem>
            <ListItem>PISCINA INFANTIL COM BRINQUEDÃO</ListItem>
            <ListItem>TIROLESA</ListItem>
          </UnorderedList>
        </Text>

        <Text fontSize="16px">
          A estrutura conta ainda com estacionamento gratuito, loja com os
          últimos lançamentos em moda praia e um complexo gastronômico muito
          variado que inclui restaurante, doceria, cafeteria, confeitaria, bares
          e lanchonetes. Neles servimos porções, comidas típicas, massas,
          carnes, peixes, lanches, doces e salgados, além de bebidas variadas.
        </Text>
        <Text fontSize="16px">
          Também é possível realizar eventos durante o horário de funcionamento
          do parque. Aniversários, reuniões e confraternizações para grupos.
          Temos bangalôs privativos, camarotes, decoração com painéis temáticos,
          mesas e cadeiras e ainda é possível encomendar bolos, doces, salgados
          e serviços de buffet.
        </Text>
        <Text fontSize="16px">
          Para a segurança dos clientes, o Ácqua Park Pantanal tem uma equipe de
          guarda-vidas especializada e treinada que fazem a monitoria em todas
          as suas atrações.
        </Text>
        <Text fontSize="16px">
          O parque é referência em entretenimento e garante muita diversão,
          aventura e um verdadeiro banho de alegria para toda a família.
        </Text>
      </Flex>

      <AtracoesCarousel />

      <Text
        fontWeight="medium"
        fontSize="24px"
        color={colors.purple500}
        flexWrap="wrap"
      >
        CONHEÇA NOSSA ESTRUTURA
      </Text>
      <EstruturaImagesContent isLarger={isLargerThan800} qtdeFotos={30} />
      <OutlineButton
        w={`200px`}
        onClick={() => navigate("/")}
        title="VOLTAR"
        color={colors.blue}
        prevIcon={<BiChevronLeft size={20} />}
      />
    </Flex>
  );
}
