import { Grid, Box, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import MenuDetalhes from "ui/components/Menu/MenuDetalhes/index";
import FormasPagamento from "./FormasPagamento";
import InformaçõesPessoais from "./InformacoesPessoais";
import colors from "core/resources/theme/colors";
import MenuDetalhesButton from "ui/components/Menu/MenuDetalhes/MenuButton";
import AlterarTelefone from "./AlterarTelefone";

export enum PerfilTabsRoutes {
  InformacoesPessoais = "informacoesPessoais",
  FormasPagamento = "FormasPagamento",
  AlterSenha = "alterarSenha",
  AlterarTelefone = "alterarTelefone",
}

const tabs = [
  {
    label: "Informações pessoais",
    route: PerfilTabsRoutes.InformacoesPessoais,
    permissions: [],
  },
  {
    label: "Formas de Pagamento",
    route: PerfilTabsRoutes.FormasPagamento,
    permissions: [],
  },
  {
    label: "Alterar Telefone",
    route: PerfilTabsRoutes.AlterarTelefone,
    permissions: [],
  },
];

function EditarPerfilTabs() {
  const {tab} = useParams();
  const [selectedTab, setSelectedTab] = useState<PerfilTabsRoutes>(
    tab as PerfilTabsRoutes || PerfilTabsRoutes.InformacoesPessoais
  );
  
  return (
    <Flex
      py={["150px", "150px", "150px", "350px"]}
      px={["50px", "50px", "50px", "200px"]}
      gap="80px"
    >
      <Flex flexDir="column" w="100%">
        {" "}
        <Text
          fontWeight="medium"
          fontSize="24px"
          color={colors.purple500}
          flexWrap="wrap"
        >
          MEUS DADOS
        </Text>
        <Grid
          templateColumns={["1fr", "1fr", "1fr", "1fr", "1fr 4fr"]}
          gridGap={8}
          mt={8}
        >
          <Box h="fit-content">
            <MenuDetalhes
              options={tabs}
              selectedOption={selectedTab}
              onChangeOption={(tab) => {
                setSelectedTab(tab);
              }}
            />
            <Box w={["100%", "100%", "100%", "100%", "300px"]}          
            >
              <MenuDetalhesButton
                key={3}
                option={{
                  label: "Alterar Senha",
                  route: PerfilTabsRoutes.AlterSenha,
                  permissions: [],
                }}
                isSelected={false}
                isDisabled={false}
                onChangeOption={() =>
                  window.open(
                    "https://accounts.tactonuvem.com.br/Manage/Index",
                    "_blank"
                  )
                }
              />
            </Box>
           
          </Box>

          <Box>
            {selectedTab === PerfilTabsRoutes.InformacoesPessoais ? (
              <InformaçõesPessoais />
            ) : null}

            {selectedTab === PerfilTabsRoutes.FormasPagamento ? (
              <FormasPagamento />
            ) : null}
             {selectedTab === PerfilTabsRoutes.AlterarTelefone ? (
              <AlterarTelefone />
            ) : null}
          </Box>
        </Grid>
      </Flex>
    </Flex>
  );
}

export default EditarPerfilTabs;
