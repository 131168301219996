import { Box } from "@chakra-ui/react";
import Header from "./Header/index";
import Footer from "./Footer/index";

export default function Layout({ children }: any) {
  return (
    <Box minH="100vh" bg={"#FAFAFA"} maxW="100vw">
      <Box justifyContent="space-between">
        <Header />
        <Box>{children}</Box>
        <Footer />
      </Box>
    </Box>
  );
}
