import { Box, Flex } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { BiChevronLeft } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import OutlineButton from "ui/components/Button/OutlineButton";
import { Tela } from "ui/pages/Admin/Ingresso/components/Identificacao";
import CadastroConta from "./Components/CadastroConta";
import EntrarConta from "./Components/EntrarConta";

interface LoginProps {
  prevStep?: () => void;
  setIsReset?: (value: React.SetStateAction<Tela>) => void;
  setTela?: (value: React.SetStateAction<Tela>) => void;
  goToIfSucceed?: string;
}

export function LoginForm({
  setIsReset,
  setTela,
  prevStep,
  goToIfSucceed,
}: LoginProps) {
  const location = useLocation();

  return (
    <Flex flexDir="column" py={[8, 8, 16, 16, 20]} px={[8, 16, 24, 32, 32]}>
      <Flex
        gap={8}
        justify="space-between"
        w="100%"
        flexDir={["column", "column", "column", "row", "row"]}
      >
        <EntrarConta setIsReset={setIsReset} goToIfSucceed={goToIfSucceed} />
        <CadastroConta setTela={setTela} />
      </Flex>
    
      {location.pathname !== "/auth/login" ? (
        <Flex justify="start" mt="100px">
          <OutlineButton
            title="VOLTAR"
            color={colors.blue}
            prevIcon={<BiChevronLeft size={20} />}
            onClick={() => prevStep!()}
          />
        </Flex>
      ) : null}
    </Flex>
  );
}

export default function Login(props: LoginProps) {
  return (
    <Box
      py={["140px", "140px", "140px", "330px"]}
      px={["30px", "30px", "20px", "180px"]}
    >
      <LoginForm {...props} />
    </Box>
  );
}
