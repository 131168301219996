import { FormularioCriarUsuario } from "./typings";
import { create } from "zustand";

export interface FormularioCadastroProps {
  selectedForm: FormularioCriarUsuario | null;
  setSelectedForm: (selectedForm: FormularioCriarUsuario | null) => void;
}

export const useRegisterStore = create<FormularioCadastroProps>((set) => ({
  selectedForm: null,
  setSelectedForm: (selectedForm: FormularioCriarUsuario | null) =>
    set(() => ({ selectedForm })),
}));
