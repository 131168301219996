import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Text } from "@chakra-ui/react";
import {
  Control,
  useController,
  RegisterOptions,
  ControllerRenderProps,
  ControllerFieldState,
  UseFormStateReturn,
} from "react-hook-form";
import { FieldContainer } from "./styles";

import { useMemo } from "react";
import { formatMoney } from "core/helpers/number";
import InputMoney from "../InputMoney";
import InputMask from "../InputMask";
import moment from "moment";
import colors from "core/resources/theme/colors";

function Field({
  control,
  label,
  shouldUnregister = false,
  name,
  rules,
  render,
  textBorder,
  colorTextBorder = colors.gray500,
  bgTextBorder = "white",
  inputProps,
  errorMessage,
  readonly,
  sizeLabel = "13px",
}: {
  control: Control<any, object>;
  rules?: Omit<
    RegisterOptions<any, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled" | "min"
  >;
  textBorder?: string;
  bgTextBorder?: string;
  colorTextBorder?: string;
  label?: string;
  shouldUnregister?: boolean;
  name: string;
  sizeLabel?: string;
  render?: ({
    field,
    fieldState,
    formState,
    isInvalid,
  }: {
    field: ControllerRenderProps<any, any>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<any>;
    isInvalid?: boolean;
  }) => React.ReactElement;
  inputProps?: any;
  errorMessage?: string;
  readonly?: boolean;
}) {
  const { field, fieldState, formState } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue: "",
  });

  let { invalid, error } = fieldState;

  invalid = invalid || !!errorMessage;

  const isRequired = useMemo(() => {
    if (typeof rules?.required === "object") {
      return rules.required.value;
    }

    if (rules?.required) {
      return true;
    }

    return false;
  }, [rules]);

  return (
    <>
      <FieldContainer
        isInvalid={invalid}
        borderColor={inputProps?.borderColor}
        fontSize={inputProps?.fontSize || 13}
      >
        <FormControl isInvalid={invalid}>
          {textBorder ? (
            <Box
              fontSize="12px"
              position="absolute"
              top="-0.7em"
              marginLeft="15px"
              background={bgTextBorder}
              px="10px"
              display="inline"
              zIndex={10}
              color={colorTextBorder}
            >
              {textBorder}
            </Box>
          ) : null}

          <Box>
            {label ? (
              <FormLabel
                mb="0"
                color="black"
                fontWeight="600"
                fontSize={sizeLabel}
                noOfLines={1}
              >
                {label}
                {isRequired ? "*" : null}
              </FormLabel>
            ) : null}

            {!readonly && typeof render !== "function" ? (
              <RenderInput
                field={field}
                inputProps={inputProps}
                isInvalid={invalid}
              />
            ) : null}

            {!readonly && typeof render === "function"
              ? render({ field, fieldState, formState, isInvalid: invalid })
              : null}

            {readonly ? (
              <RenderReadonly field={field} inputProps={inputProps} />
            ) : null}

            {invalid ? (
              <FormErrorMessage mt="2px">
                {error?.message || errorMessage || "Campo inválido"}
              </FormErrorMessage>
            ) : null}
          </Box>
        </FormControl>
      </FieldContainer>
    </>
  );
}

function RenderInput({
  field,
  inputProps,
  isInvalid,
}: {
  field: ControllerRenderProps;
  inputProps?: any;
  isInvalid?: boolean;
}) {
  if (inputProps?.type === "money") {
    return <InputMoney {...field} {...inputProps} isInvalid={isInvalid} />;
  }

  if (inputProps?.type === "cpf") {
    return (
      <InputMask
        {...field}
        isInvalid={isInvalid}
        mask="999.999.999-99"
        placeholder="XXX.XXX.XXX-XX"
      />
    );
  }

  if (inputProps?.type === "phone") {
    return (
      <InputMask
        {...field}
        mask="(99) 9999-9999"
        placeholder="(XX) XXXX-XXXX"
        isInvalid={isInvalid}
      />
    );
  }

  if (inputProps?.type === "cellphone") {
    return (
      <InputMask
        {...field}
        mask="(99) 99999-9999"
        placeholder="(XX) XXXXX-XXXX"
        isInvalid={isInvalid}
      />
    );
  }

  return <Input {...field} {...inputProps} isInvalid={isInvalid} />;
}

function RenderReadonly({
  field,
  inputProps,
}: {
  field: ControllerRenderProps;
  inputProps: any;
}) {
  if (inputProps?.type === "date") {
    return (
      <Text {...inputProps}>{moment(field.value).format("DD/MM/YYYY")}</Text>
    );
  }

  if (inputProps?.type === "money") {
    return <Text {...inputProps}>{formatMoney(field.value ?? 0)}</Text>;
  }

  return <Text {...inputProps}>{field.value}</Text>;
}

export default Field;
