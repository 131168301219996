import { useTokenStore } from "core/features/auth/hooks/useTokenStore";
import { api } from "infra/axios";
import { useQuery } from "react-query";

export interface CardData {
  id: number;
  dataCadastro: string;
  tokenId: string;
  numerosFinais: string;
  nomePortador: string;
  cpf: string;
  principal: boolean;
  bandeira: string;
}

export function useGetCartoes(onSuccess?: (cartoes: CardData[]) => void) {
  const url = `/v1/cartoes`;
  const token = useTokenStore((state) => state?.token?.access_token);

  return useQuery<any[]>(
    url,
    async () => {
      const { data } = await api.get<CardData[]>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
    }
  );
}
