import { formatMoney } from "core/helpers/number";
import { useIngressosStore } from "ui/pages/Admin/Ingresso/hooks/useIngressosStore";

function useGetParcelamentos() {
  const total = useIngressosStore((state) =>
    state
      .ingressos!.filter((ingresso) => ingresso.quantity > 0)
      .reduce((acc, ingresso) => {
        return (
          acc +
          ingresso.quantity *
            (ingresso.item.valorServico +
              ingresso.item.valorTaxaWeb +
              ingresso.item.valorBase)
        );
      }, 0)
  );

  return {
    "1x": formatMoney(total),
    "2x": formatMoney(total / 2),
    "3x": formatMoney(total / 3),
  };
}

export default useGetParcelamentos;
