import { MapContainer, Marker, TileLayer } from "react-leaflet";

interface Place {
  id: string;
  name: string;
  slug: string;
  location: {
    latitude: number;
    longitude: number;
  };
}
interface MapProps {
  places?: Place[];
}

export const Map = ({ places }: MapProps) => (
  <>
    {places?.map(({ id, name, location }) => {
      const { latitude, longitude } = location;
      return (
        <MapContainer
          center={[latitude, longitude]}
          zoom={20}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={[latitude, longitude]}
            key={`place-${id}`}
            title={name}
          />
        </MapContainer>
      );
    })}
  </>
);
