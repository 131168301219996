import { Flex, Spinner, Text } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";

import CardDetalheCompra from "./components/CardDetalheCompra";
import MeusIngressos from "./components/MeusIngressos";
import useIngressoDetalhado from "core/features/ingresso/hooks/useIngressoDetalhado";
import OutlineButton from "ui/components/Button/OutlineButton";
import { BiChevronLeft } from "react-icons/bi";

export default function DetalheCompra() {
  const { data, isLoading } = useIngressoDetalhado();
  return (
    <Flex
      py={["150px", "150px", "150px", "350px"]}
      px={["50px", "50px", "50px", "200px"]}
      gap="80px"
    >
      {data && !isLoading ? (
        <Flex w="100%" flexDir="column" gap={10}>
          <Text
            fontWeight="medium"
            fontSize="24px"
            color={colors.purple500}
            flexWrap="wrap"
          >
            DETALHES DA COMPRA
          </Text>

          <CardDetalheCompra data={data} />

          <MeusIngressos
            idVenda={data.id}
            eventoId={data.eventoId}
            data={data.itens}
          />

          <OutlineButton
            title="VOLTAR"
            w={"150px"}
            onClick={() => window.history.back()}
            color={colors.blue}
            prevIcon={<BiChevronLeft size={20} />}
          />
        </Flex>
      ) : (
        <Spinner
          m={"auto 0"}
          alignSelf={"center"}
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      )}
    </Flex>
  );
}
