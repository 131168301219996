import { createContext } from "react";
import { User } from "./types/Users";

export interface AuthContextProps {
  user: User | null;
  signin: (email: string, password: string) => Promise<boolean>;
  signout: () => void;
  signup: (body: any) => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>(null!);
