import { Box, Checkbox, Flex, Stack, Text } from "@chakra-ui/react";
import useDeletarCartao from "core/features/eventos/hooks/useDeletarCartao";
import { CardData } from "core/features/eventos/hooks/useGetCartoes";
import colors from "core/resources/theme/colors";
import { showConfirm2 } from "infra/packages/helpers/alerta";
import { BiTrash } from "react-icons/bi";
import Button from "ui/components/Button/Button";

export interface CartaoCheckboxProps {
  cartao: CardData;
  selected?: boolean;
  onSelect?: (cartao: CardData | null) => void;
  canDelete: boolean;
}

export default function CartaoCheckbox({
  cartao,
  selected,
  onSelect,
  canDelete,
}: CartaoCheckboxProps) {
  const { mutate: deletar } = useDeletarCartao(cartao?.id);

  return (
    <Flex
      as="label"
      htmlFor={cartao.id + ""}
      h="fit-content"
      boxShadow="md"
      p={3}
      gap={4}
      rounded={4}
      align="center"
      flexDir={["column", "row"]}
      justifyContent={canDelete ? "space-between" : ""}
    >
      {!canDelete && (
        <Checkbox
          size="lg"
          id={cartao.id + ""}
          mr={[0, 0, 2, 2]}
          isChecked={selected}
          onChange={() => {
            if (!selected) onSelect?.(cartao);
            else onSelect?.(null);
          }}
        />
      )}

      <Stack>
        <Text fontSize="14px" fontWeight={colors.gray600}>
          Nome do portador
        </Text>
        <Text fontSize="18px" fontWeight="black">
          {cartao.nomePortador}
        </Text>
      </Stack>

      <Stack>
        <Text fontSize="14px" fontWeight={colors.gray600}>
          Número do cartão
        </Text>
        <Text
          fontSize="18px"
          fontWeight="black"
          color={colors.green}
          align="end"
        >
          **** **** **** {cartao.numerosFinais}
        </Text>
      </Stack>
      <Box mt={2}>
        {canDelete && (
          <Button
            onClick={() =>
              showConfirm2({
                message: "Deseja realmente excluir essa forma de pagamento?",
                onOk: deletar,
              })
            }
            bg={colors.red}
            title={""}
            icon={<BiTrash />}
          />
        )}
      </Box>
    </Flex>
  );
}
