import { Setor } from "core/features/ingresso/typings";
import { api } from "infra/axios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

function useGetSetores(onSuceess?: (data: Setor[]) => void) {
  const { eventoId } = useParams<{ eventoId: string }>();
  const endpoit = `/v1/ingressos/evento/${eventoId}/setores`;

  return useQuery(
    endpoit,
    async () => {
      const { data } = await api.get(endpoit);

      return data as Setor[];
    },
    {
      onSuccess: onSuceess,
    }
  );
}

export default useGetSetores;
