import { Flex } from "@chakra-ui/react";
import MenuDetalhesButton from "./MenuButton";

interface MenuDetalhesProps<T = any> {
  light?: boolean;
  options: {
    label: string;
    icon?: any;
    route: T;
    count?: number;
    permissions?: string[];
  }[];
  isLargerThan1024?: boolean;
  selectedOption: T;
  onChangeOption: (option: T) => any;
  disabledOptions?: string[];
  rounded?: boolean;
  shadow?: boolean;
}

function MenuDetalhes<T = any>({
  light,
  options,
  selectedOption,
  onChangeOption,
  disabledOptions = [],
  rounded = true,
  shadow = true,
}: MenuDetalhesProps<T>) {
  return (
    <Flex
      w={["100%", "100%", "100%", "100%", "300px"]}
      flexDir="column"
      p={0}
      py={light ? 4 : 0}
      overflow="hidden"
      boxShadow={shadow ? "0 0 24px rgba(0,0,0,.05)" : "none"}
      rounded={rounded ? "16px" : 0}
    >
      {options.map((option, key) => {
        const isSelected = selectedOption === option.route ? true : false;
        const isDisabled = disabledOptions.includes(option.route as any);

        return (
          <MenuDetalhesButton
            key={key}
            light={light}
            option={option as any}
            isSelected={isSelected}
            isDisabled={isDisabled}
            onChangeOption={() => onChangeOption(option.route)}
          />
        );
      })}
    </Flex>
  );
}

export default MenuDetalhes;
