import { useState } from "react";
import { FormularioCadastroUsuario } from "../register/typings";
import { AuthContext } from "./AuthContext";
import { logout, signup } from "./hooks/useApi";
import { User } from "./types/Users";

const LOCAL_STORAGE_AUTH_TOKEN_KEY = "authToken";

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<User | null>(null);

  // useEffect(() => {
  //   const validateToken = async () => {
  //     const storageData = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
  //     if (storageData) {
  //       const data = await validateToken(storageData);
  //       if (data.user) {
  //         setUser(data.user);
  //       }
  //     }
  //   };
  //   validateToken();
  // }, []);

  const login = async (email: string, password: string): Promise<boolean> => {
    // const data = await signin(email, password);
    // if (data.user && data.token) {
    //   setUser(data.user);
    //   localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, data.token);
    //   return true;
    // }
    return false;
  };

  const signout = async (): Promise<void> => {
    await logout();
    setUser(null);
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
  };

  const join = async (body: FormularioCadastroUsuario) => {
    await signup(body);
  };

  return (
    <AuthContext.Provider
      value={{ user, signin: login, signout, signup: join }} // request
    >
      {children}
    </AuthContext.Provider>
  );
};
