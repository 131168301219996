import { Box, Flex, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { useGetFotos } from "core/features/hooks/useGetFotos";
import DefaultButton from "../Button/DefaultButton";
import colors from "core/resources/theme/colors";
import { IoMdPhotos } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import './styles.css';
interface EstruturaImagesContentProps {
  isLarger: boolean;
  qtdeFotos: number
  isHome?: boolean
}

export default function EstruturaImagesContent({
  isLarger,
  qtdeFotos,
  isHome = false,
}: EstruturaImagesContentProps) {

  const [selectImg, setSelectImg] = useState(0);
  const { data: imgData, isLoading } = useGetFotos(qtdeFotos);
  const navigate = useNavigate()

  useEffect(() => {
    if (isHome) {
      const interval = setInterval(() => {
        if (imgData) {
          setSelectImg((prevIndex) => (prevIndex + 1) % imgData.length);

        }
      }, 2000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [imgData, isHome]);


  if (imgData && !isLoading) {
    return (
      <Flex gap={4} flexDir={isLarger ? "row" : "column"}>

        <Box className="image-container"
          w={["300px", "100%", "740px", "1200px"]}
          h='400px'
          rounded={10}>
          <img src={imgData?.[selectImg]?.imagemUrlCompleta} alt='foto-park' />
        </Box>

        <Flex
          flexDir={isLarger ? "column" : "row"}
          justifyContent="space-between"
          gap={2}
        >
          {Array.from(Array(isHome ? 5 : imgData?.length).keys()).map((_, index: number) => (
            <Flex
              key={index}
              bgImage={imgData[index]?.imagemUrlCompleta}
              bgRepeat="no-repeat"
              bgSize={"cover"}
              h={["50px", "60px", "80px", "120px"]}
              w={["50px", "60px", "80px", "120px"]}
              rounded={10}
              sx={{ aspectRatio: '1' }}
              onClick={() => setSelectImg(index)}
              cursor="pointer"
              _hover={{
                opacity: 0.6,
              }}
            />
          ))}

        </Flex>
        {isHome && (
          <DefaultButton
            onClick={() => navigate('/parque-aquatico')}
            title="+ FOTOS"
            bg={colors.red}
            icon={<IoMdPhotos />}
          />
        )}
      </Flex>
    );
  }
  return <Spinner
    alignSelf={"center"}
    thickness="4px"
    speed="0.65s"
    emptyColor="gray.200"
    color="blue.500"
    size="xl"
  />
}
