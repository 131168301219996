import axios from "axios";
import { FormularioCadastroUsuario } from "core/features/register/typings";
import { accountApi } from "infra/axios";
import { Token, UserInfo } from "../types/auth";
import { useTokenStore } from "./useTokenStore";

const http = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const genCadastroToken = async () => {
  const endpoint = "/connect/token";

  const body = {
    client_id: "webgula",
    client_secret: "secret",
    scope: "IdentityServerApi",
    grant_type: "client_credentials",
  };

  const encodedBody = Object.entries(body)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value!)}`
    )
    .join("&");

  const { data } = await accountApi.post<Token>(endpoint, encodedBody, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  return data;
};

export const signup = async (
  body: FormularioCadastroUsuario,
  token?: string
) => {
  const endpointUser = "/api/usuarios";

  const { data } = await accountApi.post(endpointUser, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const getUserInfo = async (token?: string) => {
  const endpoint = "/connect/userinfo";
  const { data } = await accountApi.post<UserInfo>(endpoint, undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const signin = async (email: string, password: string) => {
  const endpoint = "/connect/token";

  const body = {
    client_id: "nuvem_site_api",
    client_secret: "DSafr@*asdegPo",
    audience: "tacto-webgula-api profile openid",
    grant_type: "password",
    username: email,
    password,
  };

  const encodedBody = Object.entries(body)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value!)}`
    )
    .join("&");

  const { data } = await accountApi.post<Token>(endpoint, encodedBody, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  return data;
};

export const validateToken = async () => {
  const expires_in = useTokenStore.getState().token?.expires_in;
  const now = new Date().getTime();

  return expires_in && expires_in > now;
};

export const logout = async () => {
  return { status: true };
  // const response = await http.post("/logout");
  // return response.data;
};

export default http;
