import AdicionarCartao from "./AdicionarCartao";
import FormCartaoCredito from "./FormCartaoCredito";

export interface CadastrarCartaoProps {
  setCadastrarCard: (value: boolean) => void;
  cadastrarCard: boolean;
}

function CadastrarCartao({
  cadastrarCard,
  setCadastrarCard,
}: CadastrarCartaoProps) {
  if (cadastrarCard) {
    return <FormCartaoCredito setCadastrarCard={setCadastrarCard} />;
  }

  return <AdicionarCartao setCadastrarCard={setCadastrarCard} />;
}

export default CadastrarCartao;
