import { Flex } from "@chakra-ui/react";
import Card from "./Card";

export default function ListagemComprasMobile({ data }: any) {
  return (
    <Flex flexDir={"column"} gap={4}>
      {data.map((data: any) => (
        <Card data={data} />
      ))}
    </Flex>
  );
}
