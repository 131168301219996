import { Button, Center, Flex, Select, Stack, Text } from "@chakra-ui/react";
import useGetUserInfo from "core/features/auth/hooks/useGetUserInfo";
import useEventoDetalhado from "core/features/eventos/hooks/useEventoDetalhado";
import useGetSetores from "core/features/eventos/hooks/useGetSetores";
import colors from "core/resources/theme/colors";
import { Moment } from "moment";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { BiChevronLeft } from "react-icons/bi";
import { MdArrowForward } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import OutlineButton from "ui/components/Button/OutlineButton";
import Field from "ui/components/Input/Field";
import Container from "ui/components/Layout/Container";
import BarStep from "./components/BarStep";
import DataVisita from "./components/DataVisita";
import useCurrentStep from "./hooks/useCurrentStep";
import { useIngressosStore } from "./hooks/useIngressosStore";
import useStepTitle from "./hooks/useStepTitle";

function DataPasseio() {
  const params = useParams<{ eventoId: string }>();
  const { control, setValue, getValues, trigger } = useForm<{
    setorId: number;
    dataVisita: string;
  }>();
  const { data: user } = useGetUserInfo();
  console.log('user', user)

  const activeStep = useCurrentStep();
  const [titleStep, titleNextStep] = useStepTitle(activeStep, activeStep + 1);
  const { data } = useGetSetores((setores) => {
    if (setores?.length === 1) {
      setValue("setorId", setores[0].id);
    }
  });

  const { data: eventoAtual } = useEventoDetalhado();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!user && !user.cpf) {
      const instance = Swal.fire({
        title: "CPF não informado",
        confirmButtonText: 'Ir para meus dados',
        icon: "warning",
        text: "É necessario ter o CPF informado nas informações do usuário para adquirir ingressos.",
      });

      instance.then(({ isConfirmed }) => {
        if (isConfirmed) {
          setTimeout(() => {
            navigate("/perfil");
          }, 250);
        }
      });
    }
  }, [user, navigate]);

  return (
    <Container>
      <Flex flexDir="column">
        <Flex
          pt={["130px", "130px", "130px", "330px"]}
          mb="80px"
          flexDir={"column"}
          gap={8}
        >
          <BarStep
            step={activeStep}
            titleNextStep={titleNextStep!}
            nextStep={activeStep + 1}
            titleStep={titleStep!}
          />

          <Stack spacing={0.25}>
            <Text
              fontWeight="medium"
              fontSize="24px"
              color={colors.purple500}
              flexWrap="wrap"
            >
              {eventoAtual?.nome}
            </Text>
            <Text fontSize="16px">{eventoAtual?.texto}</Text>
          </Stack>

          <Flex gap={8} flexDir={["column", "column", "row"]}>
            <Field
              control={control}
              name="setorId"
              textBorder="Setor"
              bgTextBorder={colors.gray10}
              render={({ field }) => (
                <Select size="lg" color={colors.gray500} mt={2} {...field}>
                  <option value="">Selecione um setor</option>
                  {data?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.setorNome}
                    </option>
                  ))}
                </Select>
              )}
            />

            <DataVisita
              control={control}
              onChangeDiaUtil={(diaUtil) => {
                useIngressosStore.getState().setDiaUtilSelecionado(diaUtil);
              }}
            />
          </Flex>

          <Flex justify="space-between" align="center" mt={12}>
            <OutlineButton
              title="VOLTAR"
              color={colors.blue}
              prevIcon={<BiChevronLeft size={20} />}
              onClick={() => {
                navigate(-1);
              }}
            />

            <OutlineButton
              title="PRÓXIMO"
              color={colors.white}
              bg={colors.red}
              bgBorder="#ED3237"
              nextIcon={<MdArrowForward size={20} />}
              onClick={async () => {
                const isValid = await trigger();
                if (!isValid) return;

                useIngressosStore
                  .getState()
                  .setDataSelecionada(
                    (getValues("dataVisita") as unknown as Moment).toISOString()
                  );

                navigate(
                  `/ingresso/${params.eventoId}/setor/${getValues("setorId")}`
                );
              }}
            />
          </Flex>

          <Center>
            <Button
              variant="link"
              color={colors.blue500}
              mt={8}
              onClick={() => {
                navigate(`/ingresso`);
              }}
            >
              Confira outros eventos
            </Button>
          </Center>
        </Flex>
      </Flex>
    </Container>
  );
}

export const fmtReal = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
}).format;

export default DataPasseio;
