import { Flex, Image } from "@chakra-ui/react";
import ContentItem from "./ContentItem";
import colors from "core/resources/theme/colors";
import moment from "moment";
import { formatMoney } from "core/helpers/number";
import {
  getColorPagamento,
  getIconPagamento,
  getLabelPagamento,
} from "../../util";
import { IngressoDetalhado } from "core/features/ingresso/typings";
import TagPagamento from "../../TableMinhasCompras/TagPagamento";

interface CardDetalheCompraProps {
  data: IngressoDetalhado;
}

export default function CardDetalheCompra({ data }: CardDetalheCompraProps) {
  return (
    <Flex
      bg={colors.gray100}
      w="100%"
      flexDir="column"
      px={4}
      py={2}
      rounded={10}
      gap={4}
    >
      <Flex justify="space-between" flexDir={["column", "column", "row"]}>
        <Flex>
          <ContentItem
            titulo="#"
            descricao={data.id.toString() ? data.id.toString() : "--"}
          />
        </Flex>{" "}
        <Flex>
          <ContentItem
            titulo="DATA E HORA DA COMPRA"
            descricao={
              data.dataHora
                ? moment(data.dataHora).format("DD/MM/YYYY HH:mm")
                : "--"
            }
          />
        </Flex>
        <Flex>
          {" "}
          <ContentItem
            titulo="EVENTO"
            descricao={data.eventoNome ? data.eventoNome : "--"}
          />
        </Flex>
        <Flex>
          <ContentItem
            titulo="STATUS"
            descricao={data.statusNome ? data.statusNome : "--"}
          />
        </Flex>
      </Flex>
      <Flex flexDir={["column", "column", "row"]}>
        <Flex flex={1}>
          <ContentItem
            titulo="DATA E HORA DO EVENTO"
            descricao={
              data.eventoData
                ? moment(data.eventoData).format("DD/MM/YYYY HH:mm")
                : "--"
            }
          />
        </Flex>
        <Flex flex={1}>
          <ContentItem
            titulo="FORMA DE PAGAMENTO"
            descricao={
              <TagPagamento
                title={getLabelPagamento(data.pagamentos[0].modalidadeApiId)}
                icon={
                  <Image
                    w="20px"
                    h="auto"
                    src={getIconPagamento(data.pagamentos[0].modalidadeApiId)}
                  />
                }
                colorScheme={getColorPagamento(
                  data.pagamentos[0].modalidadeApiId
                )}
              />
            }
          />
        </Flex>
        <Flex>
          <ContentItem
            titulo="VALOR DA COMPRA"
            descricao={data.valorTotal ? formatMoney(data.valorTotal) : "--"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
