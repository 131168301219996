import { Box, Flex, Text } from "@chakra-ui/react";
import useSignin from "core/features/auth/hooks/useSignin";
import colors from "core/resources/theme/colors";
import { useForm } from "react-hook-form";
import { MdArrowForward } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultButton from "ui/components/Button/DefaultButton";
import Field from "ui/components/Input/Field";
import { Tela } from "ui/pages/Admin/Ingresso/components/Identificacao";

interface EntrarContaProps {
  setIsReset?: (value: React.SetStateAction<Tela>) => void;

  goToIfSucceed?: string;
}

export default function EntrarConta({
  setIsReset,
  goToIfSucceed = "/",
}: EntrarContaProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const { control, handleSubmit } = useForm<any>({});

  const { signin, isLoading, isError, isSuccess, isIdle, isPaused, error } = useSignin(() => {
    navigate(goToIfSucceed);
  });

  const onSubmit = (data: any) => {
    signin({
      email: data.email,
      password: data.senha,
    });
  };
  console.log(isError, isSuccess, isLoading, isIdle, isPaused, error)
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir="column" gap={4}>
        <Text
          fontWeight="medium"
          fontSize="24px"
          color={colors.purple500}
          flexWrap="wrap"
        >
          JÁ SOU CADASTRADO
        </Text>
        <Flex flexDir="column" gap={6} w="100%">
          <Box>
            <Field
              control={control}
              name="email"
              inputProps={{ placeholder: "Informe seu e-mail" }}
              rules={{
                required: {
                  message: "Informe seu e-mail",
                  value: true,
                },
              }}
            />
          </Box>
          <Box>
            <Field
              control={control}
              name="senha"
              inputProps={{ placeholder: "Senha", type: "password" }}
              rules={{
                required: {
                  message: "Informe sua senha",
                  value: true,
                },
              }}
            />
          </Box>
          <Flex justify="space-between" align="center" gap={3}>
            <Flex
              textDecor="underline"
              color={colors.blue}
              onClick={() =>
                location.pathname !== "/auth/login"
                  ? setIsReset!(Tela.EsqueciSenha)
                  : navigate("/auth/recuperar-senha")
              }
              cursor="pointer"
            >
              Esqueceu sua senha?
            </Flex>
            <DefaultButton
              type="submit"
              bg={colors.red}
              title="ENTRAR"
              icon={<MdArrowForward size={20} />}

            />
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
}
