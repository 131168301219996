import { Box, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useInformativoDetails } from "core/features/informativos/hooks/useInformativoDetails";
export default function InformativoDetalhado() {
  const { infoId } = useParams<{ infoId: string }>();

  const { data, isLoading } = useInformativoDetails(+infoId!);

  return (
    <Flex
      py={["150px", "150px", "150px", "350px"]}
      px={["50px", "50px", "30px", "200px"]}
      gap="80px"
      flexDir={"column"}
    >
      {isLoading ? (
        <Spinner
          alignSelf={"center"}
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      ) : (
        <>
          <Box w="100%">
            <Text
              fontWeight="medium"
              fontSize="24px"
              color={colors.purple500}
              flexWrap="wrap"
            >
              {data?.titulo}
            </Text>{" "}
            <Text
              mt={4}
              fontWeight="medium"
              fontSize="18px"
              color={colors.purple500}
              flexWrap="wrap"
            >
              {moment(data?.dataInformativo).format("DD/MM/YYYY")}
            </Text>
          </Box>
          <Image
            alignSelf="center"
            src={data?.imagemUrlCompleta}
            w={"600px"}
            h={["300px", "600px"]}
          />

          <Text
            textAlign={"start"}
            fontWeight="medium"
            fontSize="24px"
            whiteSpace={"break-spaces"}
          >
            {data?.texto}
          </Text>
        </>
      )}
    </Flex>
  );
}
