import { Flex, Box } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import colors from "core/resources/theme/colors";
import Field from "ui/components/Input/Field";
import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import InputMask from "ui/components/Input/InputMask/index";
import DefaultButton from "ui/components/Button/DefaultButton";
import { FiCheck } from "react-icons/fi";
import useGetUserInfo from "core/features/auth/hooks/useGetUserInfo";
import { useEffect } from "react";
import { useAlterarDados } from "core/features/perfil/hooks/useAlterarDados";
import SelectSexo from "ui/components/Input/SelectSexo";
import DatePicker from "ui/components/Input/DatePicker";



export default function InformaçõesPessoais() {
  const { control, handleSubmit, reset } = useForm<any>({});
  const { isSuccess: isLoadedUser, data: user } = useGetUserInfo();

  const { mutate, isLoading } = useAlterarDados();

  const onSubmit = async (form: any) => {
    const payload = {
      nome: form.name,
      cpf: form.cpf.replace(/\D/g, ''),
      dataAniversario: form.dateofbirth,
      sexo: form.sex,
    };
    mutate(payload);
  };
  useEffect(() => {
    if (user) {
      reset({ ...user, dateofbirth: new Date(user.dateofbirth) });

    }
  }, [user, reset]);

  if (!isLoadedUser) return null;


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir={["column"]} gap={4} w="100%">
        <Flex gap={6} flexDir="column">
          <Box w="100%">
            <Field
              textBorder="Nome"
              control={control}
              name="name"
              inputProps={{ placeholder: "Nome*" }}
              rules={{
                required: {
                  message: "Informe seu nome",
                  value: true,
                },
              }}
            />
          </Box>

          <Flex gap={6} flexDir={["column", "column", "row"]}>
            <Box w="100%">
              <Field
                textBorder="Email"
                control={control}
                name="email"
                inputProps={{ disabled: true }}
              />
            </Box>
            <Box w="100%">
              <Field
                textBorder="CPF"
                control={control}
                name="cpf"
                inputProps={{ placeholder: "CPF*" }}
                rules={{
                  required: "Campo obrigatório",
                  validate: (v) => isValidCPF(v) || "CPF inválido",
                }}
                render={({ field, isInvalid }) => {
                  return (
                    <InputMask
                      {...field}
                      isInvalid={isInvalid}
                      mask="999.999.999-99"
                      placeholder="CPF*"
                    />
                  );
                }}
              />
            </Box>
            <Box w="100%">
              <Field
                textBorder="Data Nascimento"
                control={control}
                name="dateofbirth"
                inputProps={{ placeholder: "Data de nascimento*" }}
                rules={{
                  required: {
                    message: "Informe a sua data de nascimento",

                    value: true,
                  },
                }}
                render={({ field }) => <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selectedDate={field?.value}
                  onChange={(date: Date) => {
                    field.onChange(date);
                  }}
                  placeholderText="Selecione o dia do seu aniversário"
                />}
              />
            </Box>
            <Box w="100%">
              <Field
                textBorder="Sexo"
                control={control}
                name="sex"
                inputProps={{ placeholder: "sexo*" }}
                rules={{
                  required: {
                    message: "Informe seu sexo",
                    value: true,
                  },
                }}
                render={({ field }) => <SelectSexo {...field} />}
              />
            </Box>
          </Flex>

        </Flex>
      </Flex>
      <Flex flex={1} justify="end" gap={6} align="center" mt="100px">
        <DefaultButton
          maxH={10}
          type="submit"
          isLoading={isLoading}
          bg={colors.red}
          title={"SALVAR"}
          icon={<FiCheck size={20} />}
        />
      </Flex>
    </form>
  );
}
