import { Flex } from "@chakra-ui/react";
import HeaderTableRow from "./HeaderTableRow";
import TableItemRow from "./TableItemRow";

export default function TableMinhasCompras({ data }: any) {
  return (
    <Flex flexDir="column">
      <HeaderTableRow />
      {data.map((item: any) => (
        <TableItemRow key={item.id} data={item} />
      ))}
      {data.length ? (
        <Flex bg={"#F3F7FC"} w="100%" px={6} py={6} roundedBottom={20} />
      ) : null}
    </Flex>
  );
}
