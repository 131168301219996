import { Divider, Flex, Text } from "@chakra-ui/react";
import useLogout from "core/features/auth/hooks/useLogout";
import { UserInfo } from "core/features/auth/types/auth";
import colors from "core/resources/theme/colors";
import { MdPerson } from "react-icons/md";
import { RxExit } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

interface MenuNavegationProps {
  handleLogout: () => void;
  user: UserInfo;
}

export default function MenuNavegation({ user }: MenuNavegationProps) {
  const navigate = useNavigate();
  const logout = useLogout();

  return (
    <Flex
      flexDir="column"
      bg="white"
      py={2}
      rounded={10}
      gap={2}
      boxShadow="lg"
      maxW="280px"
    >
      <Flex flexDir="column" align="center" gap={2} px={20}>
        <Flex
          w="40px"
          h="40px"
          border={`solid 1px ${colors.blue500}`}
          rounded="full"
          color={colors.blue500}
          align="center"
          justify="center"
        >
          {user.name.charAt(0)}
        </Flex>
        <Text color="black" fontWeight="medium">
          Olá , {user.name} :)
        </Text>
      </Flex>

      <Flex flexDir="column" gap={4} px={2}>
        <Divider />
        <Flex
          color={colors.blue}
          align="center"
          gap={2}
          fontSize="16px"
          cursor="pointer"
          onClick={() => navigate("/perfil")}
        >
          <MdPerson size={20} />
          <Text>Meus dados</Text>
        </Flex>
        <Flex
          color={colors.red}
          align="center"
          gap={2}
          fontSize="16px"
          cursor="pointer"
          onClick={() => logout()}
        >
          <RxExit size={16} />
          <Text>Sair</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
