import { Box, Center, Text } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { useEffect, useState } from "react";

function CountDownTimer() {
  const [timeLeft, setTimeLeft] = useState(15 * 60);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <Box
      w={["90%", "90%", "90%", "50%"]}
      bg="#FFF5F5"
      py={3}
      shadow="sm"
      fontSize="16px"
    >
      <Center>
        Você tem
        <Text color={colors.red} mx={1} fontWeight="bold">
          00:{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </Text>
        para efetuar o pagamento
      </Center>
    </Box>
  );
}

export default CountDownTimer;
