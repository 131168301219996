import { Select } from "@chakra-ui/react";
import useGetBairros from "core/features/hooks/useGetBairros";
import colors from "core/resources/theme/colors";
import { useEffect } from "react";
interface SelectBairroProps {
  value: any;
  onChange: (v: any) => void;
  cidadeId: string
}

export default function SelectBairro({
  onChange,
  value,
  cidadeId,
}: SelectBairroProps) {
  const { data } = useGetBairros(cidadeId);
  console.log('data', data)
  // const options = data?.map(
  //   (bairro) => bairro.nome[0] + bairro.nome.slice(1).toLocaleLowerCase()
  // );

  useEffect(() => {
    if (value) {
      onChange(value);
    }
  }, [value, onChange]);

  return (
    <Select
      onChange={onChange}
      value={value}
      fontSize="14px"
      color={colors.gray500}
      disabled={!cidadeId ?? true}
    >
      <option value="">Selecione</option>
      {data?.map((item: any) => (
        <option key={item.id} value={item.id}>
          {item?.nome}
        </option>
      ))}
    </Select>
  );
}
