import { api } from "infra/axios";
import { useQuery } from "react-query";

export function useDiasDisponiveis(vendaId: number, ingressoId: number) {
  const url = `v1/ingressos/meus-ingressos/${vendaId}/datasuteis/${ingressoId}`;

  return useQuery(
    url,
    async () => {
      const { data } = await api.get(url);

      return data;
    },
    {
      enabled: !!vendaId && !!ingressoId,
    }
  );
}
