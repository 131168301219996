import { Flex } from "@chakra-ui/react";

interface ButtonProps {
  bg: string;
  title: string;
  onClick?: () => void;
  icon?: any;
  maxH?: number;
  prevIcon?: any;
}

export default function Button({
  bg,
  title,
  icon,
  onClick,
  maxH = 10,
  prevIcon,
}: ButtonProps) {
  return (
    <Flex
      rounded={8}
      bg={bg}
      mb={2}
      h={[6, 8, 8, maxH]}
      py={4}
      px={[2, 4]}
      align="center"
      gap={4}
      color="white"
      onClick={onClick}
      cursor="pointer"
    >
      {prevIcon} {title} {icon}
    </Flex>
  );
}
