import { Flex, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { Map } from "./components/Map/index";
import OutlineButton from "ui/components/Button/OutlineButton";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export default function ComoChegar() {
  const navigate = useNavigate();
  return (
    <Flex
      py={["150px", "150px", "150px", "350px"]}
      px={["50px", "50px", "50px", "200px"]}
      gap="80px"
      flexDir={"column"}
    >
      <Flex flexDir="column" gap={30}>
        <Text
          fontWeight="medium"
          fontSize="24px"
          color={colors.purple500}
          flexWrap="wrap"
        >
          COMO CHEGAR
        </Text>
        <Text fontWeight="medium" fontSize="24px" flexWrap="wrap">
          Rodovia Vicente Bezerra Neto, km 05 (Estrada do Manso)Cuiabá, Mato
          Grosso - Brasil.78005-000
        </Text>

        <a
          href="https://goo.gl/maps/2MWKGkyCoyxPXTnv5"
          target={"_blank"}
          rel="noreferrer"
        >
          <Button
            bg="none"
            border="solid 1px black"
            _hover={{ background: "none" }}
          >
            Ver mapa ampliado
          </Button>
        </a>
      </Flex>

      <Flex maxW="1400px" h="600px">
        <Map
          places={[
            {
              location: { latitude: -15.3880529, longitude: -56.0285527 },
              id: "1",
              name: "Ácqua Park Pantanal",
              slug: "",
            },
          ]}
        />
      </Flex>

      <OutlineButton
        w={`200px`}
        onClick={() => navigate("/")}
        title="VOLTAR"
        color={colors.blue}
        prevIcon={<BiChevronLeft size={20} />}
      />
    </Flex>
  );
}
