import iconPix from "assets/images/svgs/iconPix.svg";
import creditCard from "assets/images/svgs/creditCard.svg";
import checkCircle from "assets/images/svgs/checkCircle.svg";
import moneyCircle from "assets/images/svgs/moneyCircle.svg";
import closeCircle from "assets/images/svgs/closeCircle.svg";
import clockCircle from "assets/images/svgs/clockCircle.svg";
import colors from "core/resources/theme/colors";
import { EnumFormaPagamento, EnumStatusPagamento } from "core/features/enums";

export function getLabelPagamento(pagamentoTag: EnumFormaPagamento) {
  switch (pagamentoTag) {
    case EnumFormaPagamento.Pix:
      return "Pix";
    case EnumFormaPagamento.CartaoCredito:
      return "Crédito";
    case EnumFormaPagamento.CartaoDebito:
      return "Débito";
    default:
      return "";
  }
}

export function getColorPagamento(pagamentoTag: EnumFormaPagamento) {
  switch (pagamentoTag) {
    case EnumFormaPagamento.Pix:
      return colors.green100;
    case EnumFormaPagamento.CartaoCredito:
      return colors.purple500;
    case EnumFormaPagamento.CartaoDebito:
      return colors.purple500;

    default:
      return "";
  }
}

export function getIconPagamento(pagamentoTag: EnumFormaPagamento) {
  switch (pagamentoTag) {
    case EnumFormaPagamento.Pix:
      return iconPix;
    case EnumFormaPagamento.CartaoCredito:
      return creditCard;
    case EnumFormaPagamento.CartaoDebito:
      return creditCard;

    default:
      return "";
  }
}

export function getIconStatus(statusTag: EnumStatusPagamento) {
  switch (statusTag) {
    case EnumStatusPagamento.Disponivel:
      return moneyCircle;
    case EnumStatusPagamento.AguardandoPagamento:
      return clockCircle;
    case EnumStatusPagamento.CompraCancelada:
      return closeCircle;
    case EnumStatusPagamento.Finalizado:
      return checkCircle;
    default:
      return "";
  }
}

export function getLabelStatus(statusTag: EnumStatusPagamento) {
  switch (statusTag) {
    case EnumStatusPagamento.Disponivel:
      return "Disponivel";
    case EnumStatusPagamento.AguardandoPagamento:
      return "Aguardando pagamento";
    case EnumStatusPagamento.CompraCancelada:
      return "Compra cancelada";
    case EnumStatusPagamento.Finalizado:
      return "Finalizado";
    default:
      return "";
  }
}

export function getColorStatus(statusTag: EnumStatusPagamento) {
  switch (statusTag) {
    case EnumStatusPagamento.Disponivel:
      return colors.green;
    case EnumStatusPagamento.AguardandoPagamento:
      return colors.yellow;
    case EnumStatusPagamento.CompraCancelada:
      return colors.red;
    case EnumStatusPagamento.Finalizado:
      return colors.blue;
    default:
      return "";
  }
}
