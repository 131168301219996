import { Flex, Box, Text } from "@chakra-ui/react";
import footer from "assets/images/svgs/backgroundFooter.svg";
import colors from "core/resources/theme/colors";
import TicketButton from "ui/components/Button/Button";
import { IoTicketSharp } from "react-icons/io5";
import Informations from "./components/InformationsFooter";
import MediaFoooter from "./components/MediaFooter";
import { BsFacebook, BsInstagram, BsWhatsapp } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@chakra-ui/react";

export default function Footer() {
  const navigate = useNavigate();
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  return (
    <Box w="100vw" bottom="0px" mt="200px">
      <Flex
        bgImage={footer}
        h="150px"
        backgroundRepeat="no-repeat"
        bgRepeat="no-repeat"
        bgSize={"cover"}
      />
      <Flex
        bg={colors.blue300}
        h="60px"
        gap={4}
        justifyContent="center"
        color="white"
        fontWeight="medium"
        align="center"
      >
        Compre seu ingresso aqui
        <TicketButton
          title="INGRESSO"
          bg={colors.red}
          icon={<IoTicketSharp />}
          onClick={() => navigate("/ingresso")}
        />
      </Flex>

      <Flex bg={colors.blue100} h="max-content" px="100px" py="40px">
        <Informations isLargerThan800={isLargerThan800} />
      </Flex>
      <Flex
        bg={colors.blue200}
        h="max-content"
        py="20px"
        flexDir={isLargerThan800 ? "row" : "column"}
        justifyContent="center"
        color="white"
        fontWeight="medium"
        align="center"
        gap="4"
      >
        Acompanhe o Ácqua Park nas redes sociais
        <Flex gap={4}>
          <MediaFoooter
            link={"https://www.instagram.com/acquaparkpantanal"}
            icon={<BsInstagram size={20} color="white" />}
          />
          <MediaFoooter
            link={"https://www.facebook.com/acquaparkpantanal"}
            icon={<BsFacebook size={20} color="white" />}
          />
          <MediaFoooter
            link={`https://wa.me/5565999999102`}
            icon={<BsWhatsapp size={20} color="white" />}
          />
        </Flex>
      </Flex>
      <Flex
        bg={colors.blue50}
        h="60px"
        justifyContent="center"
        color={colors.blue500}
        fontWeight="medium"
        align="center"
      >
        <Text>© Ácqua Park Pantanal - Todos os direitos reservados</Text>
      </Flex>
    </Box>
  );
}
