import { api } from "infra/axios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { IngressoDetalhado } from "../typings";
import { useTokenStore } from "core/features/auth/hooks/useTokenStore";

function useIngressoDetalhado() {
  const params = useParams<{ id: string }>();
  const url = `/v1/ingressos/meus-ingressos/${params.id}/detalhado`;
  const token = useTokenStore((state) => state.token?.access_token);

  return useQuery(["eventos", params.id], async () => {
    const { data } = await api.get<IngressoDetalhado>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  });
}

export default useIngressoDetalhado;
