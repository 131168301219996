import { Flex, Text, Box } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import Field from "ui/components/Input/Field";
import { useForm } from "react-hook-form";
import Button from "ui/components/Button/Button";
import { BiPlus } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { Tela } from "ui/pages/Admin/Ingresso/components/Identificacao";
import { useRegisterStore } from "core/features/register/register.store";
import { FormularioCriarUsuario } from "core/features/register/typings";

interface CadastroContaProps {
  setTela?: (value: React.SetStateAction<Tela>) => void;
}

export default function CadastroConta({ setTela }: CadastroContaProps) {
  const { setSelectedForm } = useRegisterStore();
  const locale = useLocation();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<FormularioCriarUsuario>({});

  return (
    <Flex flexDir="column" gap={4}>
      <Text
        fontWeight="medium"
        fontSize="24px"
        color={colors.purple500}
        flexWrap="wrap"
      >
        AINDA NÃO TENHO CADASTRO
      </Text>
      <Flex flexDir="column" gap={6} w="100%">
        <Box>
          <Field
            textBorder="Informe seu e-mail"
            colorTextBorder={colors.blue}
            control={control}
            name="email"
            inputProps={{
              placeholder: "email@",
              borderColor: colors.blue,
            }}
            rules={{
              required: {
                message: "Informe seu e-mail",
                value: true,
              },
            }}
          />
        </Box>

        <Flex justify="end" flex="1">
          <Button
            bg={colors.red}
            prevIcon={<BiPlus size={20} />}
            title="CADASTRAR"
            onClick={() =>
              locale.pathname === "/auth/login"
                ? handleSubmit(({ email }) => {
                    navigate("/auth/cadastro");
                    setSelectedForm({ email: email });
                  })()
                : handleSubmit(({ email }) => {
                    setSelectedForm({ email: email });
                    setTela!(Tela.Cadastro);
                  })()
            }
          />{" "}
        </Flex>
      </Flex>
    </Flex>
  );
}
