import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import { Box, Flex, Text } from "@chakra-ui/react";
import useCadastrarCartao, {
  Cartao,
} from "core/features/eventos/hooks/useCadastrarCartao";
import colors from "core/resources/theme/colors";
import { useForm } from "react-hook-form";
import DefaultButton from "ui/components/Button/DefaultButton";
import OutlineButton from "ui/components/Button/OutlineButton";
import Field from "ui/components/Input/Field/index";
import InputMask from "ui/components/Input/InputMask/index";

export interface FormCartaoCreditoProps {
  setCadastrarCard: (value: boolean) => void;
}

export default function FormCartaoCredito({
  setCadastrarCard,
}: FormCartaoCreditoProps) {
  const { control, handleSubmit } = useForm<any>({});
  const { mutate, isLoading } = useCadastrarCartao(() => {
    setCadastrarCard(false);
  });

  return (
    <Flex
      as="form"
      flexDir="column"
      gap={6}
      w="100%"
      onSubmit={handleSubmit((data) => {
        const cartao: Cartao = {
          numeroCartao: data.numeroCartao.replace(/\D/g, ""),
          mesExpiracao: data.validade.split("/")[0],
          anoExpiracao: data.validade.split("/")[1],
          nomePortador: data.nomeTitular,
          cpf: data.cpf.replace(/\D/g, ""),
          codigoSeguranca: data.ccv,
          principal: true,
        };

        mutate(cartao);
      })}
    >
      <Text fontWeight="medium" fontSize="18px">
        Adicione um cartão
      </Text>
      <Flex gap={6} flexDir={["column", "column", "row"]}>
        <Box w="100%">
          <Field
            control={control}
            name="numeroCartao"
            inputProps={{ placeholder: "Número do cartão" }}
            rules={{
              required: {
                message: "Informe o número do cartão",
                value: true,
              },
            }}
            render={({ field, isInvalid }) => {
              return (
                <InputMask
                  {...field}
                  isInvalid={isInvalid}
                  mask="9999 9999 9999 9999"
                  placeholder="N.º do Cartão"
                />
              );
            }}
          />
        </Box>
        <Flex w="100%" gap={6}>
          <Box w="100%">
            <Field
              control={control}
              name="validade"
              inputProps={{ placeholder: "Validade" }}
              rules={{
                required: {
                  message: "Informe a validade do cartão",
                  value: true,
                },
              }}
              render={({ field, isInvalid }) => {
                return (
                  <InputMask
                    {...field}
                    isInvalid={isInvalid}
                    mask="99/9999"
                    placeholder="Validade"
                  />
                );
              }}
            />
          </Box>
          <Box w="100%">
            <Field
              control={control}
              name="ccv"
              inputProps={{ placeholder: "CCV" }}
              rules={{
                required: {
                  message: "Informe o CCV do cartão",
                  value: true,
                },
              }}
              render={({ field, isInvalid }) => {
                return (
                  <InputMask
                    {...field}
                    isInvalid={isInvalid}
                    mask="999"
                    placeholder="CCV"
                  />
                );
              }}
            />
          </Box>
        </Flex>
      </Flex>
      <Flex gap={6} flexDir={["column", "column", "row"]}>
        <Box w="100%">
          <Field
            control={control}
            name="nomeTitular"
            inputProps={{ placeholder: "Nome do titular do cartão" }}
            rules={{
              required: {
                message: "Informe o nome que aparece no Cartão",
                value: true,
              },
            }}
          />
        </Box>
        <Box w="100%">
          <Field
            control={control}
            name="cpf"
            inputProps={{ placeholder: "CPF / CNPJ do titular" }}
            rules={{
              required: "Campo obrigatório",
              validate: (v) => isValidCPF(v) || "CPF inválido",
            }}
            render={({ field, isInvalid }) => {
              return (
                <InputMask
                  {...field}
                  isInvalid={isInvalid}
                  mask="999.999.999-99"
                  placeholder="CPF*"
                />
              );
            }}
          />
        </Box>
      </Flex>

      <Flex align="center" justify="end" gap={4}>
        <OutlineButton
          title="CANCELAR"
          h="40px"
          onClick={() => {
            setCadastrarCard(false);
          }}
        />

        <DefaultButton
          type="submit"
          isLoading={isLoading}
          bg={colors.red}
          title="ADICIONAR CARTÃO"
        />
      </Flex>
    </Flex>
  );
}
