import { api } from "infra/axios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { EventoSetorOpcao } from "../types";

function useGetSetorOpcoes(onSuccess?: (data: EventoSetorOpcao[]) => void) {
  const { eventoId, setorId } = useParams<{
    eventoId: string;
    setorId: string;
  }>();

  const endpoit = `/v1/ingressos/evento/${eventoId}/setor/${setorId}/opcoes`;

  return useQuery(
    [endpoit],
    async () => {
      const res = await api.get(endpoit);
      return res.data as EventoSetorOpcao[];
    },
    {
      onSuccess,
      refetchOnWindowFocus: false,
    }
  );
}

export default useGetSetorOpcoes;
