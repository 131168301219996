import { Flex, Text, Box } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { Control } from "react-hook-form";
import Field from "ui/components/Input/Field";

interface DadosAcessoProps {
  control: Control<any, any>;
  getValues?: any;
}

export default function DadosAcesso({ control, getValues }: DadosAcessoProps) {
  return (
    <Flex flexDir="column" gap={4} zIndex={99}>
      <Text
        fontWeight="medium"
        fontSize="24px"
        color={colors.purple500}
        flexWrap="wrap"
      >
        Dados de acesso
      </Text>
      <Flex gap={6} flexDir="column">
        <Flex gap={6} flexDir={["column", "column", "column", "row", "row"]}>
          <Box w="100%">
            <Field
              textBorder="Informe seu e-mail"
              colorTextBorder={colors.blue}
              control={control}
              name="email"
              inputProps={{
                placeholder: "email@",
                borderColor: colors.blue,
              }}
              rules={{
                required: {
                  message: "Informe seu e-mail",
                  value: true,
                },
              }}
            />
          </Box>
          <Box w="100%">
            <Field
              control={control}
              name="senha"
              inputProps={{ placeholder: "Senha*", type: "password" }}
              rules={{
                required: {
                  message: "Informe sua senha",
                  value: true,
                },
                validate: value => {
                  if (value.length < 6) {
                    return "A senha deve ter pelo menos 6 caracteres.";
                  }
                  return true;
                },
              }}
            />
          </Box>
        </Flex>
        <Flex gap={6} flexDir={["column", "column", "column", "row", "row"]}>
          <Box w="100%">
            <Field
              control={control}
              name="confirmarSenha"
              inputProps={{ placeholder: "Confirmar senha*", type: "password" }}
              rules={{
                required: {
                  message: "Condirme a senha",
                  value: true,
                },
                validate: value => {
                  const senha = getValues("senha");
                  if (senha !== value) {
                    return "As senhas não coincidem";
                  }
                  return true;
                },
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
