import { Box, Flex } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "ui/components/Button/DefaultButton";
import Field from "ui/components/Input/Field";
import InputCep, { CepResponse } from "ui/components/Input/InputCep";
import SelectBairro from "ui/components/Input/SelectBairro";

function DadosForm({
  onSubmit,
  data,
}: {
  onSubmit?: (data: any) => void;
  data: any;
}) {
  const { control, setValue, handleSubmit, reset, getValues } = useForm<any>({});
  const [habilitaCampos, setHabilitarCampos] = useState<'possuiBairro' | 'naoPossuiBairro' | 'cadastrarBairro'>('possuiBairro')
  const afterLoadCep = (data: CepResponse) => {
    setValue("logradouro", data.logradouro);
    setValue("rua", data.logradouro);
    setValue("estado", data.ufSigla);
    setValue("cepInfo", data.numero);
    setValue("bairroId", data.bairroId);
    setValue("estadoId", data.ufSigla);
    setValue("cidadeId", data.cidadeId);
    setValue("bairroNome", data.nomeBairro);
    setValue("cidade", data.nomeCidade);
    setValue("uf", data.nomeEstado);
    setValue("nome", "Padrão");
    setValue("flgPrincipal", true);
    setValue("numero", "");
    if (data.habilitaCadastro) {
      setHabilitarCampos('naoPossuiBairro')
    }
  };

  useEffect(() => {
    if (data) {
      reset(data);
      setValue("estado", data.nomeEstado);
      setValue("bairroNome", data.nomeBairro);
      setValue("cidade", data.nomeCidade);
      setValue("flgPrincipal", true);
      setValue("nome", "Padrão");
      setValue("cepInfo", data.cepInfo);
    }
  }, [data, reset, setValue]);

  useEffect(() => {
    if (habilitaCampos === 'cadastrarBairro') {
      setValue("bairroId", 0);
    }
  }, [setValue, habilitaCampos]);

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit!)}>
      <Flex flexDir="column" gap={4}>
        <Flex gap={6} flexDir="column">
          <Flex gap={6} flexDir={["column", "column", "row"]}>
            <Box w="100%">
              <Field
                control={control}
                name="cepInfo"
                inputProps={{ placeholder: "CEP*" }}
                rules={{
                  required: {
                    message: "Informe seu CEP",
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <InputCep {...field} onLoadCep={afterLoadCep} />
                )}
              />
            </Box>
            <Box w="100%">
              <Field

                control={control}
                name="logradouro"
                inputProps={{ placeholder: "Rua*", disabled: habilitaCampos !== 'naoPossuiBairro' && habilitaCampos !== 'cadastrarBairro' }}
                rules={{
                  required: {
                    message: "Informe sua rua",
                    value: true,
                  },
                }}
              />
            </Box>
          </Flex>
          <Flex gap={6} flexDir={["column", "column", "row"]}>
            <Box w="100%">
              <Field
                control={control}
                name="numero"
                inputProps={{ placeholder: "Número*" }}
                rules={{
                  required: {
                    message: "Informe o numedo da sua residência",
                    value: true,
                  },
                }}
              />
            </Box>
            <Box w="100%">
              <Field
                control={control}
                name="complemento"
                inputProps={{ placeholder: "Complemento" }}
              />
            </Box>
            {habilitaCampos === 'possuiBairro' && (
              <Box w="100%">
                <Field
                  control={control}
                  name="bairroNome"
                  inputProps={{ placeholder: "Bairro*", disabled: true }}
                  rules={{
                    required: {
                      message: "Informe seu bairro",
                      value: true,
                    },
                  }}
                />
              </Box>
            )}
            {
              habilitaCampos === 'naoPossuiBairro' && (
                <Box w="100%">
                  <Field
                    textBorder="Bairro"
                    control={control}
                    name="bairroId"
                    inputProps={{
                      placeholder: "Selecione*",
                    }}
                    rules={{
                      required: {
                        message: "Informe seu bairro",
                        value: true,
                      },
                    }}
                    render={({ field }) => <SelectBairro {...field} cidadeId={getValues('cidadeId')} />}
                  />
                  {/* <Button size={"xs"} bg={colors.red} color="white" onClick={() => setHabilitarCampos('cadastrarBairro')}>
                    Cadastrar bairro
                  </Button> */}
                </Box>
              )
            }


            {habilitaCampos === 'cadastrarBairro' && (
              <Box w="100%">
                <Field
                  control={control}
                  name="bairroNome"
                  inputProps={{ placeholder: "Bairro*" }}
                  rules={{
                    required: {
                      message: "Informe seu bairro",
                      value: true,
                    },
                  }}
                />
              </Box>
            )}

          </Flex>
          <Flex gap={6} flexDir={["column", "column", "row"]}>
            <Box w="100%">
              <Field
                textBorder="Estado"
                control={control}
                name="estado"
                inputProps={{
                  placeholder: "Selecione*",
                  disabled: true,
                }}
                rules={{
                  required: {
                    message: "Informe seu estado",
                    value: true,
                  },
                }}
              />
            </Box>
            <Box w="100%">
              <Field
                textBorder="Cidade"
                control={control}
                name="cidade"
                inputProps={{
                  placeholder: "Selecione*",
                  disabled: true,
                }}
                rules={{
                  required: {
                    message: "Informe sua cidade",
                    value: true,
                  },
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <Flex flexDir="column" gap={4} mt={8} align="end">
        <DefaultButton type="submit" bg={colors.red} title="SALVAR ENDEREÇO" />
      </Flex>
    </Box>
  );
}

export default DadosForm;
