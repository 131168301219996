const red = "#EA151A";

const yellow = "#FFC331";
const yellow500 = "#E5A300";

const green = "#00A859";
const green100 = "#C6F6D5";
const green500 = "#00753E";

const blue = "#397DC5";
const blue50 = "#8ED9F0";
const blue100 = "#4BC1E7";
const blue200 = "#61C9EA";
const blue300 = "#1EB2E1";
const blue500 = "#2E649E";
const lightBlue = "#D8E5F3";

const purple500 = "#3E4095";

const gray10 = "#FAFAFA";
const gray100 = "#EDF2F7";
const gray200 = "#E2E8F0";
const gray300 = "#CBD5E0";
const gray500 = "#718096";
const gray600 = "#4A5568";

const orange100 = "#FEEBC8";
const orange400 = "#ED8936";
const white = "#fff";

const colors = {
  white,
  red,

  yellow,
  yellow500,

  green,
  green100,
  green500,

  blue,
  blue50,
  blue100,
  blue200,
  blue300,
  blue500,
  lightBlue,

  purple500,

  gray10,
  gray100,
  gray200,
  gray300,
  gray500,
  gray600,

  orange100,
  orange400,
};

export default colors;
