import { DiaUtil } from "core/features/ingresso/typings";
import { api } from "infra/axios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

function useGetDiasUteis() {
  const { eventoId } = useParams<{ eventoId: string }>();
  const endpoint = `/v1/ingressos/evento/${eventoId}/datasuteis`;

  return useQuery([endpoint], async () => {
    const { data } = await api.get(endpoint);

    return data as DiaUtil[];
  });
}

export default useGetDiasUteis;
