import { Flex, Text } from "@chakra-ui/layout";
import { formatMoney } from "core/helpers/number";
import colors from "core/resources/theme/colors";
import ContentItem from "../ContentItem";
import moment from "moment";
import { IngressoItemDetalhado } from "core/features/ingresso/typings";
import Button from "ui/components/Button/Button";
import { IoTicketSharp } from "react-icons/io5";
import ModalAlterarData from "./ModalAlterarData";
import { useRef, useState } from "react";
import { BiPrinter } from "react-icons/bi";
import { useReactToPrint } from "react-to-print";
import ImpressaoIngresso from "../ImpressaoIngresso";
import { useIngressoItem } from "core/features/ingresso/hooks/useIngressoItem";

interface CardIngressoProps {
  data: IngressoItemDetalhado;
  idVenda: number;
  eventoId: number;
}

export default function CardIngresso({
  data,
  idVenda,
  eventoId,
}: CardIngressoProps) {
  const [openModal, setOpenModal] = useState(false);
  const { data: ingresso } = useIngressoItem(data.id, idVenda);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <ImpressaoIngresso data={ingresso ?? {}} ref={componentRef} />
      <Flex
        flexDir="column"
        gap={4}
        bg={colors.gray200}
        p={4}
        w="100%"
        rounded={10}
      >
        <Flex flexWrap={"wrap"} justify={"space-between"}>
          <Text color={colors.blue} fontSize="20px">
            {data.opcaoNome}
          </Text>

          {data.flgPodeMarcar && (
            <Button
              onClick={() => setOpenModal(true)}
              bg={colors.red}
              title={"Preencher"}
              icon={<IoTicketSharp />}
            />
          )}
          {data.flgPodeImprimir && (
            <Button
              onClick={handlePrint}
              bg={colors.blue300}
              title={""}
              icon={<BiPrinter />}
            />
          )}
        </Flex>
        <ContentItem
          titulo="VALOR TOTAL"
          descricao={formatMoney(data.valorTotal)}
        />{" "}
        <ContentItem titulo="STATUS" descricao={data.statusNome} />
        {data.utilizadorNome && (
          <ContentItem
            titulo="NOME DO UTILIZADOR:"
            descricao={data.utilizadorNome}
          />
        )}
        {data.utilizadorDocumento && (
          <ContentItem
            titulo="DOCUMENTO UTILIZADOR:"
            descricao={data.utilizadorDocumento}
          />
        )}
        {data.utilizadorEmail && (
          <ContentItem
            titulo="EMAIL DO UTILIZADOR:"
            descricao={data.utilizadorEmail}
          />
        )}
        {data.dataPrevistaUtilizacao && (
          <ContentItem
            titulo="DATA PREVISTA UTILIZAÇÃO:"
            descricao={moment(data.dataPrevistaUtilizacao).format("DD/MM/YYYY")}
          />
        )}
        {!data.dataUtilizacao && (
          <ContentItem
            titulo="DATA UTILIZAÇÃO:"
            descricao={moment(data.dataUtilizacao ?? "--").format("DD/MM/YYYY")}
          />
        )}
        <ModalAlterarData
          idVenda={idVenda}
          eventoId={eventoId}
          idIngresso={data.id}
          visible={openModal}
          onClose={() => setOpenModal(false)}
        />
      </Flex>
    </>
  );
}
