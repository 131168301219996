import ChakraUiProvider from "infra/packages/@chakra-ui";
import { QueryClientProvider } from "react-query";
import GlobalStyle from "./styles/global";
import Pages from "./ui/pages/index";
import ScrollToTop from "ui/components/ScroolToTop";
import 'react-datepicker/dist/react-datepicker.css';
import { queryClient } from "infra/packages/react-query";



export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraUiProvider>
        <ScrollToTop />
        <Pages />
        <GlobalStyle />
      </ChakraUiProvider>
    </QueryClientProvider>
  );
}
