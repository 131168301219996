import { Flex, Text } from "@chakra-ui/layout";
import colors from "core/resources/theme/colors";

interface BarStepProps {
  step: number;
  titleStep: string;
  nextStep: number;
  titleNextStep: string;
}

export default function BarStep({
  step,
  titleStep,
  nextStep,
  titleNextStep,
}: BarStepProps) {
  return (
    <Flex align="center" justify="center" w="100%">
      <Flex w="100%" align="center" gap={4} maxW="max-content">
        <Flex
          w="30px"
          h="30px"
          rounded="full"
          color="white"
          justify="center"
          align="center"
          bg={colors.purple500}
          fontSize="16px"
        >
          {step}
        </Flex>
        <Text fontWeight="medium" fontSize="16px" color={colors.purple500}>
          {titleStep}
        </Text>
      </Flex>

      <Flex bg={colors.purple500} w="100%" h="1px" mx={10} />

      <Flex w="100%" align="center" gap={4} maxW="max-content">
        <Flex
          w="30px"
          h="30px"
          rounded="full"
          color="white"
          justify="center"
          align="center"
          bg={colors.gray500}
          fontSize="16px"
        >
          {nextStep}
        </Flex>
        <Text
          fontWeight="medium"
          fontSize="16px"
          color={colors.gray500}
          flexWrap="wrap"
        >
          {titleNextStep}
        </Text>
      </Flex>
    </Flex>
  );
}
