import { api } from "infra/axios";

export async function useSolicitarAniversario(payload: any) {
  const { selectedMode } = payload;

  const url = selectedMode === '1' ? `v1/crm/reserva-aniversario-datanasc` : `v1/crm/reserva-aniversario/grupo-datanasc`;

  return await api.post(url, payload, {
    headers: {
      Authorization: `Bearer ${payload?.token}`,
    },
  });
}
